:global .social .control.language {
  padding-left: 5px;
}


:global .social .control.language button {
  display: block;
  font-size: 0.8em;
  margin: 0;
  padding: 0 !important;
}

:global .social.template-0 .drop-avatar,
:global .social.template-0 .mask
{
  display: none;
}


