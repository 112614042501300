#chevrons .chevron {
  width: 650px;
  height: 650px;
  background-color: #6b57ff;
  margin: 2em auto 2em;
}

#chevrons .chevron .header {
  width: 580px;
  height: 80px;
  font-family: "JetBrains Sans", sans-serif;
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto 72px auto;
}

#chevrons .chevron .chevron_container {
  width: 650px;
}

.cont {
  display: flex;
  flex-flow: row wrap;
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
}

.item {
  display: block;
  margin: 0 24px 24px 0 !important;
  padding: 0 !important;
}

.badges_qty_6 .cont,
.badges_qty_7 .cont,
.badges_qty_8 .cont {
  width: 360px;
}

.badges_qty_5 .cont,
.badges_qty_9 .cont,
.badges_qty_10 .cont,
.badges_qty_11 .cont,
.badges_qty_12 .cont,
.badges_qty_13 .cont,
.badges_qty_14 .cont,
.badges_qty_15 .cont {
  width: 456px;
}

.badges_qty_4 .cont {
  width: 386px;
  transform: scale(1.3);
}

.badges_qty_3 .cont {
  width: 288px;
  transform: scale(1.5);
}

.badges_qty_2 .cont {
  width: 193px;
  transform: scale(2);
}

.badges_qty_1 .cont {
  width: 83px;
  transform: scale(3);
  margin-top: 40px;
}

.badges_qty_6 .item:nth-child(4n),
.badges_qty_7 .item:nth-child(4n),
.badges_qty_8 .item:nth-child(4n),
.badges_qty_5 .item:nth-child(5n),
.badges_qty_9 .item:nth-child(5n),
.badges_qty_10 .item:nth-child(5n),
.badges_qty_11 .item:nth-child(5n),
.badges_qty_12 .item:nth-child(5n),
.badges_qty_13 .item:nth-child(5n),
.badges_qty_14 .item:nth-child(5n),
.badges_qty_15 .item:nth-child(5n) {
  margin-right: 0 !important;
}

.badges_qty_7 .item:nth-child(5) {
  margin-left: 48px !important;
}

.badges_qty_8 .item:nth-child(5),
.badges_qty_10 .item:nth-child(6) {
  margin-left: 0 !important;
}

.badges_qty_9 .item:nth-child(6) {
  margin-left: 48px !important;
}

.badges_qty_12 .item:nth-child(11) {
  margin-left: 144px !important;
}

.badges_qty_13 .item:nth-child(11),
.badges_qty_6 .item:nth-child(5) {
  margin-left: 100px !important;
}

.badges_qty_14 .item:nth-child(11) {
  margin-left: 50px !important;
}
