
/*Template-1*/

.template-1 .template-twitter .generalInput,
.template-1 .template-facebook .generalInput,
.template-1 .template-linkedin .generalInput {
  bottom: 10px;
}
 .template-twitter .generalInput,
 .template-facebook .generalInput,
 .template-linkedin .generalInput
{
  width: 100%;
}

.template-1.huge .generalInput
{
  font-size: 96px;
  line-height: 96px;

}

/*Youtube community*/

 .cover-wrapper-template-youtubeCommunity  {
  --cover-zoom-level: 3;
}


.template-1.huge  .template-youtubeCommunity  .generalInput,
.template-1.normal .template-youtubeCommunity  .generalInput,
.template-1.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

.template-1 .template-youtubeCommunity {
  padding: 72px;
}
.template-1 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}

/*Instagram post*/

/*Social*/

.template-1.huge  .template-share  .generalInput,
.template-1.normal .template-share  .generalInput,
.template-1.small .template-share  .generalInput
{
  font-size: 144px;
  line-height: 160px;
}

/*Featured*/

.template-1.huge  .template-blog  .generalInput,
.template-1.normal .template-blog  .generalInput,
.template-1.small .template-blog .generalInput
{
  font-size: 160px;
  line-height: 192px;
}



/*template-2*/

.template-2 .mainContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
}

.template-2 .logo {
  top: 0;
}

.template-2 .mainContent .generalInput
{
  order: 1;
  bottom: unset;
  font-size: 96px;
  line-height: 96px;
  padding-bottom: 24px;
  margin-bottom: 0;
  position: static;
}

.template-2 .mainContent  .editable.firstLine {
  order: 2;
  display: block;
  width: 100%;
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  position: static;
}



/*Linkedin*/

.template-2 .template-linkedin .logo {
  top:-3px;
}
.template-2 .cover-wrapper-template-linkedin .firstLine {
  margin-bottom: -3px;
}

/*Youtube community*/

.template-2 .template-youtubeCommunity .mainContent {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  height: 100%;
}
.template-2 .template-youtubeCommunity .mainContent  .editable.firstLine {
  order: 2;
  font-size: 60px;
  line-height: 72px;
}


.template-2 .template-youtubeCommunity .mainContent .editable.generalInput
{
  position: static;
  font-size: 120px;
  line-height: 120px;
  order: 1;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.template-2  .template-youtubeCommunity .logo {
  top: unset;
}

.template-2.huge  .template-youtubeCommunity  .generalInput,
.template-2.normal .template-youtubeCommunity  .generalInput,
.template-2.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

.template-2 .template-youtubeCommunity {
  padding: 72px;
}
.template-2 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}

/*Instagram post*/

.template-2 .template-instagram.story-2 .mainContent {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  height: 100%;
}
.template-2 .template-instagram.story-2 .mainContent  .editable.firstLine {
  order: 2;
  font-size: 72px;
  line-height: 96px;
  padding-bottom: 354px;
}


.template-2 .template-instagram.story-2 .mainContent .editable.generalInput
{
  position: static;
  font-size: 120px;
  line-height: 120px;
  order: 1;
  padding-bottom: 0;
  margin-bottom: 35px;

}

.template-2  .template-instagram.story-2 .logo {
  top: unset;
}

.template-2.huge  .template-instagram.story-2  .generalInput,
.template-2.normal .template-instagram.story-2  .generalInput,
.template-2.small .template-instagram.story-2  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

.template-2 .template-instagram.story-2 .generalInput {
  bottom: unset;
  left:0
}


.template-2.huge  .template-share  .generalInput,
.template-2.normal .template-share  .generalInput,
.template-2.small .template-share  .generalInput
{
  font-size: 192px;
  line-height: 192px;
  padding-bottom: 0;
}


.template-2.huge  .template-share  .firstLine,
.template-2.normal .template-share  .firstLine,
.template-2.small .template-share  .firstLine
{
  font-size: 80px;
  line-height: 92px;
  padding-top: 50px;
}

/*Featured*/

.template-2.huge  .template-blog  .generalInput,
.template-2.normal .template-blog  .generalInput,
.template-2.small .template-blog .generalInput
{
  font-size: 160px;
  line-height: 192px;
}


.template-2  .template-blog  .editable.firstLine {
  display: none;
}



/*Template-3*/

.template-3 .mainContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
}

.template-3 .logo {
  top: 0;
}

.template-3 .mainContent .generalInput
{
  order: 1;
  bottom: unset;
  font-size: 72px;
  line-height: 80px;
  padding-bottom: 24px;
  margin-bottom: 0;
  position: static;
}

.template-3 .mainContent  .editable.firstLine {
  order: 2;
  display: block;
  width: 100%;
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  position: static;
}



/*Linkedin*/

.template-3 .template-linkedin .logo {
  top:-3px;
}
.template-3 .cover-wrapper-template-linkedin .firstLine {
  margin-bottom: -3px;
}

/*Youtube community*/

.template-3 .template-youtubeCommunity .mainContent {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  height: 100%;
}
.template-3 .template-youtubeCommunity .mainContent  .editable.firstLine {
  order: 2;
  font-size: 60px;
  line-height: 72px;
}


.template-3 .template-youtubeCommunity .mainContent .editable.generalInput
{
  position: static;
  font-size: 96px;
  line-height: 96px;
  order: 1;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.template-3  .template-youtubeCommunity .logo {
  top: unset;
}

.template-3.huge  .template-youtubeCommunity  .generalInput,
.template-3.normal .template-youtubeCommunity  .generalInput,
.template-3.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

.template-3 .template-youtubeCommunity {
  padding: 72px;
}
.template-3 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}

/*Instagram post*/

.template-3 .template-instagram.story-2 .mainContent {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  height: 100%;
}
.template-3 .template-instagram.story-2 .mainContent  .editable.firstLine {
  order: 2;
  font-size: 72px;
  line-height: 96px;
  padding-bottom: 354px;
}


.template-3 .template-instagram.story-2 .mainContent .editable.generalInput
{
  position: static;
  font-size: 120px;
  line-height: 120px;
  order: 1;
  padding-bottom: 0;
  margin-bottom: 35px;

}

.template-3  .template-instagram.story-2 .logo {
  top: unset;
}

.template-3.huge  .template-instagram.story-2  .generalInput,
.template-3.normal .template-instagram.story-2  .generalInput,
.template-3.small .template-instagram.story-2  .generalInput
{
  font-size: 120px;
  line-height: 120px;
}

.template-3 .template-instagram.story-2 .generalInput {
  bottom: unset;
  left:0
}

/*Social*/

.template-3.huge  .template-share  .generalInput,
.template-3.normal .template-share  .generalInput,
.template-3.small .template-share  .generalInput
{
  font-size: 144px;
  line-height: 160px;
  padding-bottom: 0;
}


.template-3.huge  .template-share  .firstLine,
.template-3.normal .template-share  .firstLine,
.template-3.small .template-share  .firstLine
{
  font-size: 80px;
  line-height: 92px;
  padding-top: 50px;
}

/*Featured*/

.template-3.huge  .template-blog  .generalInput,
.template-3.normal .template-blog  .generalInput,
.template-3.small .template-blog .generalInput
{
  font-size: 160px;
  line-height: 192px;
}


.template-3  .template-blog  .editable.firstLine {
  display: none;
}





/*template-4*/

/*.EDU.template-4 .logo--image {*/
/*  width: 340px;*/
/*  background-position: left top;*/
/*}*/


/*.template-4 .section-youtubeCommunity,*/
/*.template-4 .section-instagram*/
/*{*/
/*  display: none;*/
/*}*/

.EDU.template-4  .cover-wrapper-template-share .logo--product-name {
  display: none;
}


.template-4 .cover-webinar  {
  padding-right: 0;

}


.template-4 .template-twitter .generalInput,
.template-4 .template-facebook .generalInput,
.template-4 .template-linkedin .generalInput {
  bottom: 0;
}

.template-4 .generalInput
{
  font-size: 72px;
  line-height: 80px;

}

/*Youtube community*/

.template-4.huge  .template-youtubeCommunity  .generalInput,
.template-4.normal .template-youtubeCommunity  .generalInput,
.template-4.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

.template-4 .template-youtubeCommunity {
  padding: 72px;
}
.template-4 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}


/*Social*/

.template-4  .template-share  .generalInput
{
  font-size: 144px;
  line-height: 160px;
}

/*Featured*/

.template-4 .template-blog  .generalInput
{
  font-size: 160px;
  line-height: 192px;
}


.template-4  .logo--product-name {
  display: block;
  position: absolute;
  top: 72px;
  left: 80px;
  font-size: 48px;
}

.EDU.template-4 .logo--product-name {
  display: none;
}

.JB.template-4  .logo--product-name {
  display: none;
}

.template-4  .logo--product-name::before {
  display: none;
}


.template-4 .mainContent {
  width: 500px;
}




.template-4 .template-share .logo-1 {
  display: none;
}




.template-4 .drop-avatar
{
  background-image: none;
  display: block;
  height: 610px;
  width: 600px;
  position: absolute;
  padding-left: 0 ;
  border-radius: 20px 0 0;
  top: auto;
  bottom: 0;
  /*align-self: flex-end;*/
}
.template-4 .template-twitter .drop-avatar .dropArea
{
  margin-bottom: 50px;
}


.template-4 .template-facebook .drop-avatar .dropArea
{
  margin-bottom: 75px;
}

.template-4 .template-youtubeCommunity .drop-avatar .dropArea {
  margin-bottom: 10px;
  margin-left: 50px;
}

.template-4 .template-instagram .drop-avatar .dropArea {
  margin-bottom: 10px;
  margin-left: -40px;
  height: 500px;
}

.template-4 .template-linkedin .drop-avatar .dropArea {
  margin-bottom: 80px;
}

.template-4 .cover-webinar {
  display: flex;
  justify-content: space-between;
  background-color: red;
}

.template-4 .react-transform-wrapper {
  left:0;
}

.template-4 .cover-wrapper-template-facebook .drop-avatar {
  height: 570px;
}

.template-4 .cover-wrapper-template-linkedin .drop-avatar {
  height: 570px;
}

.template-4 .cover-wrapper-template-share .drop-avatar,
.template-4 .cover-wrapper-template-blog .drop-avatar
{
  height: 1340px;
  width: 1280px;
  border-radius: 40px 0 0;
}

.template-4 .cover-wrapper-template-share  .logo--product-name {
  display: block;
  position: absolute;
  top: 144px;
  left: 160px;
  font-size: 92px;
}


.template-4 .cover-wrapper-template-share .mainContent,
.template-4 .cover-wrapper-template-blog .mainContent
{
  width: 1100px;
}


.template-4 .cover-wrapper-template-share .template-share,
.template-4 .cover-wrapper-template-share .template-blog
{
  padding: 120px 0 120px 144px;
}



/*template 5*/

/*.EDU.template-5 .logo--image {*/
/*  width: 340px;*/
/*  background-position: left top;*/
/*}*/

/*.template-5 .section-youtubeCommunity,*/
/*.template-5 .section-instagram*/
/*{*/
/*  display: none;*/
/*}*/

.EDU.template-5 .logo--product-name {
  display: none;
}

.JB.template-5 .logo--product-name {
  display: none;
}

.template-5 .cover-webinar  {
  padding-right: 0;

}


.template-5 .template-twitter .generalInput,
.template-5 .template-facebook .generalInput,
.template-5 .template-linkedin .generalInput {
  bottom: 0;
}

.template-5 .generalInput
{
  font-size: 72px;
  line-height: 80px;

}

/*Youtube community*/

.template-5.huge  .template-youtubeCommunity  .generalInput,
.template-5.normal .template-youtubeCommunity  .generalInput,
.template-5.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

.template-5 .template-youtubeCommunity {
  padding: 72px;
}
.template-5 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}


/*Social*/

.template-5.huge  .template-share  .generalInput,
.template-5.normal .template-share  .generalInput,
.template-5.small .template-share  .generalInput
{
  font-size: 144px;
  line-height: 160px;
}

/*Featured*/

.template-5.huge  .template-blog  .generalInput,
.template-5.normal .template-blog  .generalInput,
.template-5.small .template-blog .generalInput
{
  font-size: 160px;
  line-height: 192px;
}


.template-5  .logo--product-name {
  display: block;
  position: absolute;
  top: 72px;
  left: 80px;
  font-size: 48px;
}


.EDU.template-5 .product-edu .logo--product-name {
  display: none;
}

.template-5  .logo--product-name::before {
  display: none;
}


.template-5 .mainContent {
  width: 750px;
}




.template-5 .template-share .logo-1 {
  display: none;
}


.template-5 .drop-avatar
{
  background-image: none;
  display: block;
  height: 610px;
  width: 336px;
  position: absolute;
  padding-left: 0 ;
  border-radius: 20px 0 0;
  top: auto;
  bottom: 0;
  /*align-self: flex-end;*/
}
.template-5 .template-twitter .drop-avatar .dropArea
{
  margin-bottom: 50px;
}


.template-5 .template-facebook .drop-avatar .dropArea
{
  margin-bottom: 75px;
}

.template-5 .template-youtubeCommunity .drop-avatar .dropArea {
  margin-bottom: 10px;
  margin-left: 50px;
}

.template-5 .template-instagram .drop-avatar .dropArea {
  margin-bottom: 10px;
  margin-left: -40px;
  height: 500px;
}

.template-5 .template-linkedin .drop-avatar .dropArea {
  margin-bottom: 80px;
}

.template-5 .cover-webinar {
  display: flex;
  justify-content: space-between;
  background-color: red;
}

.template-5 .react-transform-wrapper {
  left:0;
}

.template-5 .cover-wrapper-template-facebook .drop-avatar {
  height: 570px;
}

.template-5 .cover-wrapper-template-linkedin .drop-avatar {
  height: 570px;
}
.template-5 .cover-wrapper-template-share .drop-avatar,
.template-5 .cover-wrapper-template-blog .drop-avatar
{
  height: 1306px;
  width: 772px;
  border-radius: 40px 0 0;

}

.template-5 .cover-wrapper-template-share  .logo--product-name {
  display: block;
  position: absolute;
  top: 144px;
  left: 160px;
  font-size: 92px;
}


.template-5 .cover-wrapper-template-share .mainContent,
.template-5 .cover-wrapper-template-blog .mainContent
{
  width: 1500px;
}


.template-5 .cover-wrapper-template-share .template-share {
  padding: 120px 0 120px 144px;

}
