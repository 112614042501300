

.wrapper {
  --cover-zoom-level: 1;
  --cover-width: auto;
  --cover-height: auto;
  zoom: calc(1 / var(--cover-zoom-level));
  margin-right: 32px;
}
.container {
  display: flex;
  box-sizing: border-box;
  margin: 0;
  padding: 112px 144px;
  justify-content: space-between;
  overflow: hidden;
  width: calc(var(--cover-width) * 2);
  height: calc(var(--cover-height) * 2);
  /*font-family: "JetBrains Sans", sans-serif;*/
  background-repeat: no-repeat;
  position: relative;
}

.withUpload {
  padding-right: 0;
  padding-bottom: 0;
}

:global .drop-container {
  position: relative;
  border-radius: 32px 0 0;
  align-self: flex-end;
  width: 1280px;
  height: 1080px;
}

:global .template-share .drop-container
{
  width: 1274px;
  height: 1296px;
}

:global .drop-avatar {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

:global .mask {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
}

.input,
.generalInput,
.firstLine,
.secondLine,
.thirdLine
{
  /*font-family: "JetBrains Sans", sans-serif;*/
  font-style: normal;
  font-weight: 530;
  font-size: 160px;
  line-height: 192px;
  color: #ffffff;
  overflow: hidden;
  z-index: 11;
}


.secondLine,
.thirdLine
{
  display: none;
}

.dateInput {
  display: none;
}
:global(.social .dateInput) {
  display: block;
}
:global(.social .dateInput) {
  display: block;
}
 .mainContent {
  width: 1196px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

/*TODO: remove this*/
:global(.cover-wrapper-product-jbai .template-featured.type-release) .mainContent {
  width: 94%;
  margin-top: 617px;
  display: flex;
  flex-direction: row;
  align-content:   center;
  justify-content: center;
  align-items: center
}


.input {
  width: 2050px;
  height: 960px;
}

:global .logo {
  color: white;
  font-size: 60px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  z-index: 13;
}

:global .logo .logo--image {
  width: 96px;
  height: 96px;
  color: white;
  margin-right: 20px;
  background-size: 96px 96px;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

:global .logo .logo--product-name {
  flex-shrink: 0;
}



.positionInput {
  display: none;
  z-index: 13;
}

.episodeInput {
  display: none;
}

/*youtubeCover*/
:global .cover-wrapper-template-youtubeCover {
  --cover-zoom-level: 2;
}

:global(.type-youtube-video) {
  flex-direction: column;
}

:global .template-youtubeCover {
  width: 1920px;
  height: 1080px;
  padding: 72px;
  padding-bottom: 105px;
  background-position: 1000px center;
  background-size: 840px 880px;
}

:global .template-youtubeCover .drop-avatar,
:global .template-youtubeCover .mask {
  width: calc(1080px / 1.0925);
}

:global .template-youtubeCover .logo {
  font-size: 60px;
}

:global(.template-youtubeCover) .mainContent {
  width: 51%;
}

:global(.template-youtubeCover .editable).generalInput {
  font-weight: 600;
  font-size: 96px;
  line-height: 110px;
  margin-top: 72px;
  margin-bottom: auto;
}

:global(.template-youtubeCover .editable).firstLine {
  font-size: 60px;
  line-height: 72px;
  order: 2;
}

/*youtubeThumbnail*/

:global .cover-wrapper-template-youtubeThumbnail {
  --cover-zoom-level: 2;
}

:global .template-youtubeThumbnail {
  width: 1280px;
  height: 720px;
  padding: 50px;
}

:global(.template-youtubeThumbnail) .mainContent {
  width: 50%;
}

:global .template-youtubeThumbnail .drop-avatar,
:global .template-youtubeThumbnail .mask {
  width: calc(720px / 1.0925);
}

:global .template-youtubeThumbnail .logo img {
  padding-right: 15px;
}

:global .template-youtubeThumbnail .logo {
  font-size: 50px;
}

:global(.template-youtubeThumbnail .editable).generalInput {
  font-weight: 530;
  font-size: 75px;
  line-height: 88px;
  margin-top: 30px;
  margin-bottom: auto;
}

:global(.template-youtubeThumbnail .editable).firstLine {
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  order: 2;
}

/*YouTube First Slide*/


/*:global .cover-wrapper-template-youtubeFirstSlide {*/
/*  --cover-zoom-level: 2;*/
/*}*/

:global .template-youtubeFirstSlide {
  width: 1920px;
  height: 1080px;
  padding: 72px;
}

/*StreramYardBackground*/

:global .template-streamYardBackground{
  width: 1920px;
  height: 1080px;
  padding: 0;
}
/*StreramYardOverlay*/

:global .template-streamYardOverlay{
  width: 1920px;
  height: 1080px;
  padding: 0;
}

/*EmailBanner*/

:global .template-emailBanner{
  width: calc(2 * 650px);
  height: calc(2 * 325px);
  padding: calc(2 * 40px) !important;
}

/*EmailBanner*/

:global .template-emailPhoto{
  width: calc(2 * 650px);
  height: calc(2 * 144px);
  padding: 0 !important;
}
/*youtubeCommunity*/

:global .cover-wrapper-template-youtubeCommunity {
  --cover-zoom-level: 2;
}


:global .template-youtubeCommunity {
  width: 1080px;
  height: 1080px;
  padding: 64px 96px;
  position: relative;
}

:global .template-youtubeCommunity .logo img {
  padding-right: 15px;
}

:global .template-youtubeCommunity .logo {
  font-size: 36px;
}

:global .template-youtubeCommunity .drop-avatar,
:global .template-youtubeCommunity .mask {
  width: 592px;
  height: 720px;
  top: inherit;
  bottom: 0;
  background-size: 776px 856px;
  background-repeat: repeat;
}

:global .template-youtubeCommunity.type-webinar .drop-avatar,
:global .template-youtubeCommunity.type-webinar .mask {
  background-size: 140% 140%;
  background-position: top 18% left 15%;
}

:global(.template-youtubeCommunity .editable).positionInput
{
  display: inline;
  order: 3;
  font-weight: 459;
  font-size: 36px;
  line-height: 48px;
  color: white;
  z-index: 16;
}

:global(.template-youtubeCover .editable).positionInput
{
  display: inline;
  order: 4;
  font-weight: 459;
  font-size: 60px;
  line-height: 72px;
  color: white;
  z-index: 16;
}

:global(.template-youtubeCommunity .editable).generalInput {
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  margin-top: 30px;
  margin-bottom: 56px;
}

:global(.template-youtubeCommunity .editable).firstLine {
  font-weight: 459;
  font-size: 36px;
  line-height: 48px;
  order: 3;
  width: 360px;
}

:global(.template-youtubeCommunity .editable).dateInput {
  display: block;
  font-weight: 459;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: auto;
  width: 440px;
  order: 2;
}

:global(.type-youtube-video.template-youtubeCommunity .editable).generalInput {
  margin-bottom: auto;
}

/*twitter*/

:global .cover-wrapper-template-twitter {
  --cover-zoom-level: 2;
}

:global .template-twitter {
  width: 1200px;
  height: 675px;
  padding: 64px 72px;
}

:global(.template-twitter) .mainContent {
  width: 50%;
}

:global .template-twitter .drop-avatar,
:global .template-twitter .mask {
  width: calc(675px / 1.0925);
  background-size: cover;
}

:global .template-twitter .logo img {
  padding-right: 15px;
}

:global .template-twitter .logo {
  font-size: 36px;
}

:global(.template-twitter .editable).generalInput {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  margin-top: 30px;
  margin-bottom: auto;
}

:global(.template-twitter .editable).firstLine {
  font-weight: 459;
  font-size: 36px;
  line-height: 48px;
  order: 3;
  width: 360px;
}

/*facebook*/

:global .cover-wrapper-template-facebook {
  --cover-zoom-level: 2;
}

:global .template-facebook {
  width: 1200px;
  height: 630px;
  padding: 64px 72px;
}

:global(.template-facebook) .mainContent {
  width: 55%;
}

:global .template-facebook .drop-avatar,
:global .template-facebook .mask {
  width: calc(630px / 1.0925);
}

:global .template-facebook .logo img {
  padding-right: 15px;
}

:global .template-facebook .logo {
  font-size: 36px;
}

:global(.template-facebook .editable).generalInput {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  margin-top: 30px;
  margin-bottom: auto;
}

:global(.template-facebook .editable).firstLine {
  font-weight: 459;
  font-size: 36px;
  line-height: 48px;
  order: 3;
  width: 360px;
}

/*linkedin*/

:global .cover-wrapper-template-linkedin {
  --cover-zoom-level: 2;
}

:global .template-linkedin {
  width: 1200px;
  height: 627px;
  padding: 64px 72px;
}

:global .template-linkedin .logo img {
  padding-right: 15px;
}

:global .template-linkedin .logo {
  font-size: 36px;
}

:global .template-linkedin .drop-avatar,
:global .template-linkedin .mask {
  width: calc(627px / 1.0925);
}

:global(.template-linkedin) .mainContent {
  width: 55%;
}

:global(.template-linkedin .editable).generalInput {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  margin-top: 30px;
  margin-bottom: auto;
}

:global(.template-linkedin .editable).firstLine {
  font-weight: 459;
  font-size: 36px;
  line-height: 48px;
  order: 3;
  width: 360px;
}

/*blog*/
:global .cover-wrapper-template-blog {
  --cover-zoom-level: 3;
  --cover-width: 1280px;
  --cover-height: 720px;
}

:global .template-blog {
  position: relative;
  background-size: contain;
}

:global .template-blog.type-webinar .drop-avatar,
:global .template-blog.type-webinar .mask {
  width: calc(1200px / 1.0925);
  height: 100%;
  background-size: 132% 132%;
  background-position: top 10% left 5%;
}

:global .template-blog .logo,
:global .template-blog.type-webinar .logo-1 {
  display: none
}

:global(.template-blog) .mainContent {
  width: 55%;
}

:global(.type-webinar) .firstLine,
:global(.type-webinar) .generalInput,
:global(.template-share.type-webinar) .firstLine,
:global(.template-share.type-webinar) .generalInput
{
  font-weight: 530;
  font-size: 120px;
  line-height: 144px;
}

:global .template-share.type-webinar .editable
{
  padding-top: 0;
}


  /*Tips&Tricks*/

:global(.template-featured.type-tips-tricks) .mainContent,
:global(.template-share.type-tips-tricks) .mainContent
{
  /*align-content: stretch;*/
  width: auto;
  /*display: flex;*/
  /*flex-direction: column;*/
  max-width: 70%;
}


:global .template-featured.type-tips-tricks .drop-container,
:global .template-share.type-tips-tricks .drop-container
{
  /*width: auto;*/
  max-width: 1280px;
}

/*featured*/

:global .cover-wrapper-template-featured {
  --cover-zoom-level: 3;
  --cover-width: 1280px;
  --cover-height: 720px;
}
/*blog share*/

:global .cover-wrapper-template-share,
:global .cover-wrapper-template-preview,
:global .cover-wrapper-template-site
{
  --cover-zoom-level: 3;
  --cover-width: 1280px;
  --cover-height: 720px;
}

:global .template-featured .editable {
  padding-top: 80px;
  font-size: 160px;
  line-height: 192px;
  font-weight: 530;
  letter-spacing: 0;
}

:global .template-share {
  position: relative;
}



.template-share.type-webinar .logo,
.template-share.type-webinar .logo-2 {
  display: none
}

:global .template-share .logo-2 {
  width: 210px;
  height: 210px;
  left: 404px;
  bottom: 136px;
}

:global .template-share .logo-3 {
  width: 210px;
  height: 210px;
  left: 684px;
  bottom: 136px;
}

/*:global(.template-share) .mainContent {*/
/*  width: 55%;*/
/*}*/
:global .template-share .editable {
  padding-top: 80px;
  font-size: 160px;
  line-height: 192px;
  font-weight: 600;
  letter-spacing: 0;
}



/*email*/

:global .cover-wrapper-template-email {
  --cover-zoom-level: 2;
}

:global(.cover-wrapper-type-webinar .template-email) {
  width: 1300px;
  height: 288px;
  padding: 0;
  align-items: flex-end;
  background-color: transparent !important;
}

:global(.cover-wrapper-type-webinar .template-email .logo--image) {
  width: 96px;
  height: 96px;
  background-size: cover;
}

:global(.template-email .editable),
:global(.template-email .logo--product-name) {
  display: none;
}

:global(.cover-wrapper-type-webinar .template-email .drop-avatar){
  width: 488px;
  height: 288px;
  left: 406px;
  right: inherit;
  background-position-y: 16px;
}

:global(.template-email .mask) {
  display: none;
}

/*instagram*/

:global .cover-wrapper-template-instagram,
:global .cover-wrapper-template-countdown
{
  --cover-zoom-level: 3;
}

:global .template-instagram {
  width: 1080px;
  height: 1920px;
  padding: 240px 96px 0;
}
:global .template-countdown {
  width: 1080px;
  height: 1920px;
  padding: 240px 96px 0;
}

:global .template-instagram.story-1 .logo {
  font-weight: 530;
  font-size: 36px;
}

:global .template-instagram.story-1 .logo img {
  width: 160px;
  height: 160px;
}

:global .template-instagram.story-1 .drop-avatar,
:global .template-instagram.story-1 .mask,
:global .template-instagram.story-2 .mask {
  display: none;
}

:global(.template-instagram.story-1 .editable).firstLine {
  display: none;
}

:global(.template-instagram.story-1 .editable).dateInput {
  display: block;
  margin-top: 228px;
  color: white;
  font-weight: 459;
  font-size: 50px;
  line-height: 60px;
}

:global(.template-instagram.story-1 .editable).generalInput,
:global(.template-instagram.story-1 .editable).generalInput::before {
  margin-top: 100px;
  font-weight: 530;
  font-size: 96px;
  line-height: 100px;
}

:global(.template-instagram.story-1 .editable).generalInput::before {
  content: 'Webinar';
  display: block;
  margin-top: 0;
}

:global .template-instagram.story-2 {
  padding-top: 724px;
}

:global .template-instagram.story-2 .drop-avatar {
  width: 1080px;
  height: 1080px;
  top: inherit;
  bottom: 0;
}

:global .template-instagram.story-2 .logo {
  display: none;
}

:global(.template-instagram.story-2 .editable).generalInput {
  display: none;
}

:global(.template-instagram.story-2 .editable).firstLine,
:global(.template-instagram.story-2 .editable).positionInput {
  width: 420px;
  font-weight: 459;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
}

:global(.template-instagram.story-2 .editable).positionInput {
  display: block;
}

:global .logo-1,
:global .logo-2,
:global .logo-3 {
  position: absolute;
  width: 192px;
  height: 192px;
  bottom: 144px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 15;
}



:global .template-share .logo-1 {
  width: 200px;
  height: 200px;
  bottom: 192px
}

:global .template-blog .logo-2 {
  left: 384px;
}

:global .logo-3 {
  left: 624px;
}

:global .logo-type-JB .logo--product-name {
  display: none;
}



:global .template-instagram.story-1 .editable.headline::before {
  content: none;
  display: none;
}
:global .template-instagram.story-1 .editable.headline {
  margin-top: 0;
  font-weight: 530;
  font-size: 120px;
  line-height: 120px;
}

:global .template-instagram.story-1 .editable.dateline {
  display: block;
  margin-top: 40px;
  font-weight: 400;
  font-size: 60px;
  line-height: 60px;
}

:global  .template-instagram .logo-1 {
  display: block;
  width: 120px;
  height: 120px;
  left: 96px;
  bottom: 376px;
}

:global .template-instagram .logo-2 {
  display: block;
  width: 280px;
  height: 391px;
  left: 96px;
  bottom: 856px;
}

:global .blog .toolbar .stage {
  display: none;
}

/*Emails*/

:root {
  --multiplier: 2;
}

:global .template-leaderboard {
  width: calc(var(--multiplier) * 650px);
  height: calc(var(--multiplier) * 96px);
  padding: calc(var(--multiplier) * 24px) calc(var(--multiplier) * 48px) calc(var(--multiplier) * 23px);
}

:global .template-half {
  width: calc(var(--multiplier) * 650px) !important;
  height: calc(var(--multiplier) * 200px) !important;
  padding: calc(var(--multiplier) * 24px) calc(var(--multiplier) * 48px) calc(var(--multiplier) * 24px);
}

:global .template-content {
  width: calc(var(--multiplier) * 650px) !important;
  height: calc(var(--multiplier) * 325px) !important;
  padding: calc(var(--multiplier) * 60px);
}

:global .template-longcontent {
  width: calc(var(--multiplier) * 650px);
  height: calc(var(--multiplier) * 325px);
  padding: calc(var(--multiplier) * 60px);
}
