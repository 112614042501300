@import "../layouts.css";
@import "../covers.css";



#covers.internal.template-1.huge .template-share .generalInput {

  font-size: 144px;
  line-height: 160px;

}

#covers.internal.template-2 .template-share .generalInput {
position: static;
}


#covers.internal.template-3 .template-share .generalInput {
  position: static;
  font-size: 144px;
  line-height: 160px;
}
#covers.internal.template-4 .template-share .generalInput {
  font-size: 144px;
  line-height: 160px;
}
#covers.internal.template-4 .cover-wrapper-template-share .logo--product-name {
  display: block;
  font-size: 92px;
  left: 160px;
  position: absolute;
  top: 144px;
}
#covers.internal.template-5 .template-share .generalInput {
  font-size: 144px;
  line-height: 160px;
}
#covers.internal.template-5 .cover-wrapper-template-share .logo--product-name {
  display: block;
  font-size: 92px;
  left: 160px;
  position: absolute;
  top: 144px;
}

/*!*Reset*!*/

/*#covers.internal .withupload {*/
/*  padding: 0;*/
/*}*/

/*#covers.internal .tags {*/
/*  display: none;*/
/*}*/
/*!*Exportable control*!*/

/*.exportable-toggle {*/
/*  margin-right: 15px;*/
/*}*/

/*.no-exportable .cover-wrapper-type-internal {*/
/*  filter: grayscale(100%);*/
/*  opacity: 0.1;*/
/*  transition: filter 0.3s ease, opacity 0.3s ease;*/
/*}*/

/*.no-exportable .exportable-toggle {*/
/*  opacity: 1.0;*/
/*}*/

/*.no-exportable h2 {*/
/*  color: #444;*/
/*}*/


/*.internal .cover-wrapper-template-twitter,*/
/*.internal .cover-wrapper-template-facebook,*/
/*.internal .cover-wrapper-template-linkedin {*/
/*  --cover-zoom-level: 2;*/
/*}*/

/*#covers .toolbar .control button {*/
/*  padding: 1px 5px !important;*/
/*  margin: 1px 2px !important;*/
/*}*/

/*.internal .control.language {*/
/*  padding-left: 5px;*/
/*}*/

/*.internal .logo-1,*/
/*.internal .logo-2,*/
/*.internal .logo-3 {*/
/*  display: none;*/
/*}*/

/*.internal .dateInput {*/
/*  display: none;*/
/*}*/

/*.internal .control.language button {*/
/*  display: block;*/
/*  font-size: 0.8em;*/
/*  margin: 0;*/
/*  padding: 0 !important;*/
/*}*/

/*@keyframes softBlink {*/
/*  0% {*/
/*    background-color: #3d3d3d;*/
/*  }*/
/*  50% {*/
/*    background-color: transparent;*/
/*  }*/
/*  100% {*/
/*    background-color: #3d3d3d;*/
/*  }*/
/*}*/


/*!*@keyframes prompt {*!*/
/*!*  0% { opacity: 1; }*!*/
/*!*  50% { opacity: 0; }*!*/
/*!*  100% { opacity: 1; }*!*/
/*!*}*!*/

/*!*#covers.internal .template-twitter .generalInput::after {*!*/
/*!*  content: "_";*!*/
/*!*  animation: prompt 1s infinite;*!*/

/*!*}*!*/

/*#covers.internal .template-twitter .generalInput:focus::after {*/
/*  animation: none;*/
/*  display: none;*/
/*}*/
/*!*main styles*!*/

/*#covers.internal .logo {*/
/*  !*top:3px;*!*/
/*  !*left: -3px;*!*/
/*  bottom: unset;*/
/*  display: flex;*/
/*  justify-content: flex-start;*/
/*  max-width: 100%;*/
/*  position: unset;*/
/*  !*z-index: 100;*!*/
/*}*/

/*#covers.internal .mainContent {*/
/*  height: 100%;*/
/*  max-height: unset;*/
/*  position: relative;*/
/*  padding: 0;*/
/*}*/
/*#covers.internal .logo .logo--image {*/
/*  display: block;*/
/*  width: 335px;*/
/*  height: 72px;*/
/*  margin: 0;*/
/*  background-size: 335px 72px;*/
/*  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");*/
/*}*/

/*#covers.internal.EDU .logo--image {*/
/*  width: 556px;*/
/*  height: 75px;*/
/*  padding-left: 10px;*/
/*  background-size: 570px 75px;*/
/*  margin-top: -3px;*/
/*  margin-left: 3px;*/
/*  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS Academy/JetBrains_Academy_logo_white.svg");*/

/*}*/

/*#covers.internal.EDU .logo--product-name {*/
/*  display: none;*/
/*}*/

/*!*Twitter*!*/


/*#covers.internal .withupload {*/
/*  padding: 48px;*/
/*}*/


/*#covers.internal.IDE .logo--image {*/
/*  order: 1;*/
/*  width: 374px;*/
/*  height: 60px;*/
/*  background-size: 374px 60px;*/
/*  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDEs/JETBRAINS_IDEs_logo_white.svg");*/
/*}*/
/*#covers.internal.IDE .logo--product-name {*/
/*  font-weight: 600;*/
/*  font-size: 40px;*/
/*  line-height: 0;*/
/*  padding: 0;*/
/*  padding-top: 8px;*/
/*  order: 2;*/
/*}*/


/*#covers.internal .logo--product-name::before {*/
/*  content: "|";*/
/*  padding-left: 16px;*/
/*  padding-right: 16px;*/
/*  position: relative;*/
/*  font-weight: 600;*/
/*  top: -2px;*/
/*}*/

/*#covers.internal .logo--product-name::after {*/
/*  content: 'Internal';*/
/*  font-weight: 600;*/
/*  font-size: 40px;*/
/*  margin-top: -26px;*/
/*  line-height: 45px;*/
/*  order: 3;*/
/*  background-color: black;*/
/*  border-radius: 30px;*/
/*  padding-left: 36px;*/
/*  padding-right: 36px;*/
/*  padding-top: 3px;*/
/*  padding-bottom: 3px;*/
/*  position: absolute;*/
/*  right: 0;*/
/*  height: 45px;*/
/*}*/


/*.internal.isProductInText .logo--product-name,*/
/*.internal.isProductInText .logo--product-name::before  {*/
/*  display: none;*/
/*}*/

/*#covers.internal.EDU.isProductInText .product-edu .logo--product-name,*/
/*#covers.internal.EDU.isProductInText .product-edu .logo--product-name::before  {*/
/*  display: none;*/
/*}*/

/*#covers.internal.EDU.isProductInText .product-edu   .logo--image{*/
/*  width: 335px;*/
/*  height: 72px;*/
/*  margin: 0 11px 0 0;*/
/*  background-size: 335px 72px;*/
/*  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");*/

/*}*/


/*#covers.internal .generalInput {*/
/*  display: inline;*/
/*  font-size: 80px;*/
/*  line-height: 80px;*/
/*  max-height: 100%;*/
/*}*/


/*!*#covers.internal.normal .generalInput{*!*/
/*!*  font-size: 96px;*!*/
/*!*  line-height: 96px;*!*/
/*!*}*!*/

/*!*#covers.internal.small .generalInput{*!*/
/*!*  font-size: 80px;*!*/
/*!*  line-height: 80px;*!*/
/*!*}*!*/

/*.internal .logo--product-name {*/
/*  padding: 10px 0 0 10px;*/
/*  font-size: 48px;*/
/*  line-height: 48px;*/
/*  font-weight: 600;*/
/*  white-space: nowrap;*/
/*}*/

/*.internal .mainContent .firstLine {*/
/*  display: block;*/
/*  width: 680px;*/
/*  overflow: visible;*/
/*  color: white;*/
/*  font-size: 40px;*/
/*  line-height: 40px;*/
/*  font-weight: 600;*/
/*  !*padding-bottom: 10px;*!*/
/*  padding-top: 10px;*/
/*  order: 3;*/
/*  !*margin-bottom: 5px;*!*/
/*}*/

/*.internal .mainContent  .positionInput {*/
/*  display: block;*/
/*  overflow: visible;*/
/*  color: white;*/
/*  font-size: 40px;*/
/*  line-height: 40px;*/
/*  font-weight: 600;*/
/*  padding-bottom: 0;*/
/*  order: 4;*/
/*  margin-bottom: 0;*/
/*}*/


/*.internal.isTranslated .control.language button {*/
/*  animation: softBlink 2s 5;*/

/*}*/

/*.internal.isTranslated .control.language button[data-test-state="active"] {*/
/*  background-color: transparent;*/
/*  animation: none;*/
/*}*/

/*!*.internal .drop-avatar,*!*/
/*!*.internal .mask {*!*/
/*!*  display: none;*!*/
/*!*}*!*/

/*.internal .toolbar h5 {*/
/*  color: grey;*/
/*  margin-bottom: 10px;*/
/*}*/

/*.internal .logo {*/
/*  position: absolute;*/
/*  bottom: 70px;*/
/*}*/

/*!*.internal .cover-wrapper-template-twitter .template-twitter,*!*/
/*!*.internal .cover-wrapper-template-facebook .template-facebook,*!*/
/*!*.internal .cover-wrapper-template-facebook .template-linkedin*!*/
/*!*{*!*/
/*!*  padding: 60px 72px;*!*/
/*!*}*!*/



/*!*.internal .firstLine {*!*/
/*!*  display: none;*!*/
/*!*}*!*/

/*.internal .cover-wrapper-template-twitter .mainContent,*/
/*.internal .cover-wrapper-template-facebook .mainContent,*/
/*.internal .cover-wrapper-template-linkedin .mainContent {*/
/*  width: 100%;*/
/*}*/

/*!*.internal .cover-wrapper-template-twitter .mainContent {*!*/
/*!*  max-height: 400px;*!*/
/*!*}*!*/

/*!*.internal .cover-wrapper-template-facebook .mainContent {*!*/
/*!*  max-height: 370px;*!*/
/*!*}*!*/

/*#covers.internal .generalInput {*/
/*  display: grid;*/
/*  align-content: center;*/
/*  height: 100%;*/
/*  margin-top: 8px;*/
/*}*/


/*!*Linkedin*!*/

/*!*#covers.internal .template-linkedin .logo {*!*/
/*!*  top:-3px;*!*/
/*!*}*!*/
/*!*.internal .cover-wrapper-template-linkedin .generalInput {*!*/
/*!*  margin-bottom: -3px;*!*/
/*!*}*!*/




/*!*Twitter*!*/

/*.internal .cover-wrapper-template-twitter  .positionInput {*/
/*  display: none;*/
/*}*/


/*#covers.internal .cover-wrapper-template-twitter .generalInput {*/
/*  width: 630px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-twitter .generalInput div {*/
/*  display: inline-flex;*/
/*  flex-wrap: nowrap;*/

/*}*/
/*  !*Facebook*!*/

/*.internal .cover-wrapper-template-facebook .positionInput {*/
/*  display: none;*/
/*}*/

/*#covers.internal .cover-wrapper-template-facebook .generalInput {*/
/*  width: 630px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-facebook .generalInput {*/
/*  display: grid;*/
/*  align-content: center;*/
/*  height: 100%;*/
/*  margin-top: 3px;*/
/*}*/


/*!*.internal .cover-wrapper-template-facebook  .firstLine {*!*/
/*!*  margin-bottom: -8px;*!*/
/*!*}*!*/

/*!*LinkedIn*!*/


/*#covers.internal .cover-wrapper-template-linkedin .generalInput {*/
/*  width: 630px;*/
/*}*/

/*.internal .cover-wrapper-template-linkedin .positionInput {*/
/*  display: none;*/
/*}*/


/*#covers.internal .cover-wrapper-template-linkedin .generalInput {*/
/*  margin-top: 1px;*/
/*}*/


/*!*YouTube Community*!*/

/*!*#covers.internal .template-youtubeCommunity {*!*/
/*!*  padding: 90px 96px;*!*/
/*!*}*!*/

/*#covers.internal .template-youtubeCommunity {*/
/*  padding: 72px;*/
/*}*/

/*#covers.internal .template-youtubeCommunity .generalInput {*/
/* margin-top: 120px;*/
/*  width: 692px;*/
/*  font-size: 88px;*/
/*  line-height: 88px;*/
/*  align-content: unset;*/
/*  display: unset;*/
/*}*/


/*!*#covers.internal .template-youtubeCommunity .logo {*!*/
/*!*  left: -3px;*!*/
/*!*  bottom: 0;*!*/
/*!*}*!*/


/*.internal .cover-wrapper-template-youtubeCommunity .dateInput,*/
/*.internal .cover-wrapper-template-youtubeCommunity .positionInput {*/
/*  display: block;*/
/*}*/
/*.internal .cover-wrapper-template-youtubeCommunity .firstLine {*/
/*  font-size: 40px;*/
/*}*/

/*.internal .cover-wrapper-template-youtubeCommunity .positionInput {*/
/*  font-size: 40px;*/
/*  line-height: 40px;*/
/*  font-weight: 400;*/
/*  opacity: 70%;*/
/*}*/

/*.internal .cover-wrapper-template-youtubeCommunity .dateInput {*/
/*  color: white;*/
/*  margin-bottom: 54px;*/
/*  font-size: 40px;*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  line-height: 40px;*/
/*}*/



/*!*Instagram Stories*!*/

/*.internal .cover-wrapper-template-instagram {*/
/*  --cover-zoom-level: 3;*/
/*}*/

/*#covers.internal.IDE .cover-wrapper-template-instagram .logo {*/
/*  margin-top: 171px;*/
/*  margin-left: -3px;*/
/*}*/

/*#covers.internal.IDE .cover-wrapper-template-instagram .logo--image {*/
/*  height: 72px;*/
/*  width: 454px;*/
/*  background-size: 454px 72px;*/
/*}*/

/*#covers.internal.IDE .cover-wrapper-template-instagram .logo--product-name {*/
/*  font-size: 48px;*/
/*}*/

/*#covers.internal.IDE .cover-wrapper-template-instagram .logo--product-name::after {*/
/*  position: relative;*/
/*  left: -759px;*/
/*  top: 186px;*/
/*  padding-left: 39px;*/
/*  padding-right: 39px;*/
/*  font-size: 48px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-instagram .story-2 {*/
/*  padding: 96px;*/
/*}*/

/*#covers.internal.IDE .cover-wrapper-template-instagram .generalInput{*/
/*  font-size: 120px;*/
/*  line-height: 120px;*/
/*  height: 600px;*/
/*  margin-top: 260px;*/
/*  display: block;*/
/*  align-content: unset;*/

/*}*/

/*#covers.internal .cover-wrapper-template-instagram .dateInput {*/
/*  display: block;*/
/*  font-size: 48px;*/
/*  line-height: 48px;*/
/*  font-weight: 600;*/
/*  color: white;*/
/*  margin-top: 71px;*/
/*  z-index: 100;*/
/*  width: 465px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-instagram .firstLine,*/
/*.internal .cover-wrapper-template-instagram .story-2 .positionInput {*/
/*  display: none;*/
/*}*/

/*#covers.internal .cover-wrapper-template-instagram .generalInput {*/
/*  bottom: 390px;*/
/*  overflow: visible;*/
/*  max-height: 100%;*/
/*  font-size: 96px;*/
/*  line-height: 96px;*/
/*}*/
/*#covers.internal .cover-wrapper-template-instagram .dropArea {*/
/*  !*position: absolute;*!*/
/*  !*right: 0;*!*/
/*  height: 150px !important;*/
/*  width: 150px !important;*/
/*}*/


/*!*Instagram Stories Countdown*!*/
/*#covers.internal .template-countdown {*/
/*  padding: 96px;*/
/*}*/
/*#covers.internal .template-countdown .generalInput {*/
/*  display: none;*/
/*}*/

/*#covers.internal .template-countdown .logo {*/
/*  display: none;*/
/*}*/

/*#covers.internal .cover-wrapper-template-countdown .dropArea {*/
/*  height: 150px !important;*/
/*  width: 150px !important;*/
/*}*/
/*#covers.internal .cover-wrapper-template-countdown .firstLine {*/
/*  font-size: 48px;*/
/*  line-height: 48px;*/
/*  font-weight: 600;*/
/*  color: white;*/
/*  margin-top: 925px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-countdown .positionInput {*/
/*  font-size: 48px;*/
/*  line-height: 48px;*/
/*  font-weight: 400;*/
/*  color: white;*/
/*}*/

/*#covers.internal .cover-wrapper-template-countdown .drop-avatar {*/
/*  overflow: unset !important;*/
/*  width: 100%;*/
/*}*/



/*#covers.internal .section-countdown  .exportable-toggle {*/
/*  margin-left:-45px;*/

/*  :nth-child(1) {*/
/*   display: none !important;*/
/*  }*/

/*  !*span::after {*!*/
/*  !*  background-color: #444 !important;*!*/
/*  !*}*!*/
/*}*/



/*!*Blog share*!*/

/*:root {*/
/*  --multiplier: 2;*/
/*}*/

/*#covers.internal .cover-wrapper-template-share {*/
/*  --cover-zoom-level: 3;*/
/*}*/

/*#covers.internal .cover-wrapper-template-share .template-share {*/
/*  padding: calc(var(--multiplier) * 60px) 0 calc(var(--multiplier) * 140px) calc(var(--multiplier) * 72px);*/
/*  background-color: black;*/
/*}*/

/*#covers.internal .cover-wrapper-template-share .logo {*/
/*  transform: scale(2);*/
/*  transform-origin: top left;*/

/*}*/

/*#covers.internal .cover-wrapper-template-share .positionInput {*/
/*  display: none;*/
/*}*/

/*#covers.internal .cover-wrapper-template-share .generalInput {*/
/*  width: calc(var(--multiplier) * 762px);*/
/*  font-size: calc(var(--multiplier) * 80px);*/
/*  line-height: calc(var(--multiplier) * 96px);*/
/*  margin-top: calc(var(--multiplier) * 35px);*/
/*  padding-top: 0;*/
/*}*/

/*#covers.internal .cover-wrapper-template-share .firstLine {*/
/*  position: absolute;*/
/*  font-size: calc(var(--multiplier) * 40px);*/
/*  line-height: calc(var(--multiplier) * 40px);*/
/*  width: 1480px;*/
/*  top: calc(var(--multiplier) * 475px);*/
/*}*/




/*#covers.internal .cover-wrapper-template-share .logo--product-name::after {*/
/*  display: none;*/
/*}*/

/*#covers.internal .cover-wrapper-template-share .mainContent {*/
/*  max-height: 950px;*/
/*  width: 1000px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-share .drop-avatar {*/
/*  overflow: unset !important;*/
/*  width: 100%;*/

/*}*/






/*!*Featured*!*/


/*#covers.internal .cover-wrapper-template-blog {*/
/*  --cover-zoom-level: 4;*/
/*}*/

/*#covers.internal .cover-wrapper-template-blog .template-blog {*/
/*  padding: calc(var(--multiplier) * 60px) 0 calc(var(--multiplier) * 140px) calc(var(--multiplier) * 72px);*/
/*  background-color: black;*/
/*}*/

/*#covers.internal .cover-wrapper-template-blog .logo {*/
/*  display: none;*/
/*  !*transform: scale(2);*!*/
/*  !*transform-origin: top left;*!*/

/*}*/

/*#covers.internal .cover-wrapper-template-blog .positionInput {*/
/*  display: none;*/
/*}*/

/*#covers.internal .cover-wrapper-template-blog .generalInput {*/
/*  width: calc(var(--multiplier) * 762px);*/
/*  font-size: calc(var(--multiplier) * 80px);*/
/*  line-height: calc(var(--multiplier) * 96px);*/
/*  margin-top: calc(var(--multiplier) * -35px);*/
/*  padding-top: 0;*/
/*}*/

/*#covers.internal .cover-wrapper-template-blog .firstLine {*/
/*  position: absolute;*/
/*  font-size: calc(var(--multiplier) * 40px);*/
/*  line-height: calc(var(--multiplier) * 40px);*/
/*  width: 1480px;*/
/*  top: calc(var(--multiplier) * 455px);*/
/*  height: 200px;*/
/*  align-content: end;*/
/*}*/




/*#covers.internal .cover-wrapper-template-blog .logo--product-name::after {*/
/*  display: none;*/
/*}*/

/*#covers.internal .cover-wrapper-template-blog .mainContent {*/
/*  max-height: 1000px;*/
/*  width: 1000px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-blog .drop-avatar {*/
/*  overflow: unset !important;*/
/*  width: 100%;*/
/*}*/

/*#covers.internal .cover-wrapper-template-blog .drop-avatar .dropArea {*/
/*  right: 30px;*/
/*}*/


/*!*YouTube Thumbnail*!*/




/*#covers.internal .cover-wrapper-template-youtubeThumbnail .logo--product-name::after {*/
/*  right: -520px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-youtubeThumbnail .positionInput {*/
/*  display: none;*/
/*}*/



/*!*YouTube First Slide*!*/


/*#covers.internal .template-youtubeFirstSlide {*/
/*  padding: 72px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-youtubeFirstSlide  .logo {*/
/*  width: 100%;*/

/*}*/

/*#covers.internal.IDE .cover-wrapper-template-youtubeFirstSlide .logo--image {*/
/*  width: 450px;*/
/*  height: 73px;*/
/*  background-size: 450px 73px;*/
/*  }*/


/*#covers.internal .cover-wrapper-template-youtubeFirstSlide .logo--product-name {*/
/*  font-size: 48px;*/
/*  line-height: 48px;*/
/*  padding: 10px 0 0 5px;*/
/*}*/
/*#covers.internal .cover-wrapper-template-youtubeFirstSlide .logo--product-name::before {*/
/*  position: unset;*/
/*  top: unset;*/
/*}*/

/*#covers.internal .cover-wrapper-template-youtubeFirstSlide  .logo--product-name::after {*/
/*  right: -580px;*/
/*  top: 34px;*/
/*  font-size: 48px;*/
/*  line-height: 48px;*/
/*  padding: 8px 40px 13px 35px;*/
/*  border-radius: 60px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-youtubeFirstSlide .generalInput {*/
/*  font-size: 140px;*/
/*  line-height: 140px;*/
/*  margin-top: 34px;*/
/*  padding-top: 0;*/
/*  height: 700px;*/
/*  max-width: 1173px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-youtubeFirstSlide .positionInput {*/
/*  display: block;*/
/*  font-size: 48px;*/
/*  line-height: 48px;*/
/*  font-weight: 400;*/
/*  color: white;*/
/*  margin-top: 82px;*/
/*  position: absolute;*/
/*  top: 805px;*/

/*}*/

/*#covers.internal .cover-wrapper-template-youtubeFirstSlide .firstLine {*/
/*  position: absolute;*/
/*  top: 830px;*/
/*  font-size: 48px;*/
/*  line-height: 48px;*/
/*}*/

/*#covers.internal .cover-wrapper-template-youtubeFirstSlide {*/
/*  --cover-zoom-level: 2;*/
/*}*/

/*!*StreamYardBackground*!*/

/*#covers.internal .template-streamYardBackground {*/
/*  background-color: black;*/
/*}*/

/*#covers.internal .template-streamYardBackground .mainContent {*/
/*  display: none;*/
/*}*/

/*#covers.internal .template-streamYardBackground .drop-avatar {*/
/*  display: none;*/
/*}*/


/*#covers.internal .cover-wrapper-template-streamYardBackground {*/
/*  --cover-zoom-level: 2;*/
/*}*/

/*!*StreamYardOverlay*!*/
/*#covers.internal.IJ .template-streamYardOverlay {*/
/*  padding: 0;*/
/*}*/

/*#covers.internal.IJ .template-streamYardOverlay .mainContent {*/
/*  width: 100%;*/
/*}*/

/*#covers.internal .template-streamYardOverlay .logo,*/
/*#covers.internal .template-streamYardOverlay .positionInput,*/
/*#covers.internal .template-streamYardOverlay .dateInput,*/
/*#covers.internal .template-streamYardOverlay .firstLine,*/
/*#covers.internal .template-streamYardOverlay .drop-avatar*/
/*{*/
/*  display: none;*/
/*}*/

/*#covers.internal .template-streamYardOverlay .drop-avatar {*/
/*  display: none;*/
/*}*/

/*#covers.internal .template-streamYardOverlay .generalInput {*/
/*  display: block;*/
/*  font-size: 25px;*/
/*  line-height: 28px;*/
/*  border-bottom-left-radius: 20px;*/
/*  padding: 8px 24px;*/
/*  font-weight: 600;*/
/*  min-width: 200px;*/
/*  max-width: 1500px;*/
/*  text-align: center;*/
/*  height: 38px;*/
/*  position: absolute;*/
/*  right: 0;*/
/*  margin: 0;*/
/*}*/

/*#covers.internal .cover-wrapper-template-streamYardOverlay {*/
/*  --cover-zoom-level: 2;*/
/*}*/

/*#covers.internal .cover-wrapper-template-streamYardOverlay {*/
/*  background-repeat: repeat;*/
/*  background-size: 10px 10px;*/
/*  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Common/transparent.png");*/
/*}*/



/*!*Email banner *!*/
/*#covers.internal .cover-wrapper-template-emailBanner {*/
/*  --cover-zoom-level: 2;*/
/*}*/

/*#covers.internal .template-emailBanner .mainContent{*/
/* !*transform: scale(2);*!*/
/* !*transform-origin: top left;*!*/
/*  display: grid;*/
/*}*/

/*#covers.internal .cover-wrapper-template-emailBanner {*/

/*  .logo {*/
/*    display: none;*/
/*  }*/

/*  .drop-avatar {*/
/*    display: none;*/
/*  }*/
/*  .positionInput {*/
/*    display: none;*/
/*  }*/
/*  .dateInput {*/
/*    display: none;*/
/*  }*/
/*}*/


/*#covers.internal .cover-wrapper-template-emailBanner .logo {*/
/*  display: none;*/
/*}*/

/*#covers.internal .cover-wrapper-template-emailBanner {*/
/*  .generalInput {*/
/*    font-size: calc(2 * 43px);*/
/*    line-height: calc(2 * 49px);*/
/*    text-align: center;*/
/*    margin: 0;*/
/*    margin-top: 42px;*/
/*    height: unset;*/
/*    align-content: center;*/
/*    !*min-height: calc(2 * 107px);*!*/

/*  }*/

/*  .firstLine {*/
/*    font-size: calc(2 * 40px);*/
/*    line-height: calc(2 * 44px);*/
/*    text-align: center;*/
/*    margin-bottom: 35px;*/
/*    font-weight: 400;*/
/*    width: 100%;*/
/*    !*align-content: ;*!*/
/*  }*/

/*}*/

/*!*EmailPhoto*!*/

/*!*Email banner *!*/
/*#covers.internal .cover-wrapper-template-emailPhoto {*/
/*  --cover-zoom-level: 2;*/
/*  background-repeat: repeat;*/
/*  background-size: 10px 10px;*/
/*  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Common/transparent.png");*/
/*  .mainContent {display: none}*/

/*  .drop-avatar {*/
/*    position: relative;*/
/*    width: 100%;*/
/*  }*/
/*  .dropArea {*/
/*    width: 90px !important;*/
/*    height: 90px !important;*/
/*    font-size: 10px;*/
/*    margin: 0 auto 10px auto !important;*/
/*  }*/

/*  .react-transform-component {*/
/*    width: 200px !important;*/
/*  }*/

/*  :hover .controls {*/
/*    display: block !important;*/
/*  }*/

/*  :hover .controls button {*/
/*    margin-left: 3px;*/
/*  }*/


/*}*/



/*  !*#covers.internal.EDU.template-4 .logo--image {*!*/
/*!*  width: 340px;*!*/
/*!*  background-position: left top;*!*/
/*!*}*!*/

/*!*#covers.internal.template-4 .section-blog,*!*/
/*!*#covers.internal.template-4 .section-youtubeCommunity,*!*/
/*!*#covers.internal.template-4 .section-instagram*!*/
/*!*{*!*/
/*!*  display: none;*!*/
/*!*}*!*/



/*!*#covers.internal.template-4 .cover-webinar  {*!*/
/*!*  padding-right: 0;*!*/

/*!*}*!*/


/*!*#covers.internal.template-4 .template-twitter .generalInput,*!*/
/*!*#covers.internal.template-4 .template-facebook .generalInput,*!*/
/*!*#covers.internal.template-4 .template-linkedin .generalInput {*!*/
/*!*  bottom: 0;*!*/
/*!*}*!*/


/*!*Youtube community*!*/

/*!*#covers.internal.template-4.huge  .template-youtubeCommunity  .generalInput,*!*/
/*!*#covers.internal.template-4.normal .template-youtubeCommunity  .generalInput,*!*/
/*!*#covers.internal.template-4.small .template-youtubeCommunity  .generalInput*!*/
/*!*{*!*/
/*!*  font-size: 96px;*!*/
/*!*  line-height: 96px;*!*/
/*!*}*!*/

/*!*#covers.internal.template-4 .template-youtubeCommunity {*!*/
/*!*  padding: 72px;*!*/
/*!*}*!*/
/*!*#covers.internal.template-4 .template-youtubeCommunity .generalInput {*!*/
/*!*  bottom: unset;*!*/
/*!*  left:0*!*/
/*!*}*!*/


/*!*internal*!*/

/*!*#covers.internal.template-4.huge  .template-share  .generalInput,*!*/
/*!*#covers.internal.template-4.normal .template-share  .generalInput,*!*/
/*!*#covers.internal.template-4.small .template-share  .generalInput*!*/
/*!*{*!*/
/*!*  font-size: 144px;*!*/
/*!*  line-height: 160px;*!*/
/*!*}*!*/

/*!*Featured*!*/

/*!*#covers.internal.template-4.huge  .template-blog  .generalInput,*!*/
/*!*#covers.internal.template-4.normal .template-blog  .generalInput,*!*/
/*!*#covers.internal.template-4.small .template-blog .generalInput*!*/
/*!*{*!*/
/*!*  font-size: 160px;*!*/
/*!*  line-height: 192px;*!*/
/*!*}*!*/



/*#covers.internal  .logo--product-name {*/
/*  order: 2;*/
/*!*display: block;*!*/
/*!*  position: absolute;*!*/
/*!*  top: 72px;*!*/
/*!*  left: 80px;*!*/
/*  font-size: 48px;*/
/*}*/

/*#covers.internal.EDU.template-4 .logo--product-name {*/
/*  display: none;*/
/*}*/

/*#covers.internal.JB.template-4  .logo--product-name {*/
/*  display: none;*/

/*}*/





/*#covers.internal.template-4 .template-share .logo-1 {*/
/*  display: none;*/
/*}*/



/*!*#covers.internal.template-4 .section-blog {*!*/
/*!*  display: none;*!*/
/*!*}*!*/

/*!*#covers.internal.template-4 .drop-avatar*!*/
/*!*{*!*/
/*!*  background-image: none;*!*/
/*!*  display: block;*!*/
/*!*  height: 610px;*!*/
/*!*  width: 600px;*!*/
/*!*  position: absolute;*!*/
/*!*  padding-left: 0 ;*!*/
/*!*  border-radius: 20px 0 0;*!*/
/*!*  top: auto;*!*/
/*!*  bottom: 0;*!*/
/*!*  !*align-self: flex-end;*!*!*/
/*!*}*!*/

/*#covers.internal .drop-avatar .dropArea {*/
/*  width: 180px;*/
/*  height: 180px;*/
/*  border-radius: 90px;*/
/*  margin-right: 0;*/
/*  top: unset;*/
/*}*/




/*!*#covers.internal .template-facebook .drop-avatar .dropArea*!*/
/*!*{*!*/
/*!* margin-bottom: 75px;*!*/
/*!*}*!*/

/*!*#covers.internal.template-4 .template-youtubeCommunity .drop-avatar .dropArea {*!*/
/*!*  margin-bottom: 10px;*!*/
/*!*  margin-left: 50px;*!*/
/*!*}*!*/

/*!*#covers.internal.template-4 .template-instagram .drop-avatar .dropArea {*!*/
/*!*  margin-bottom: 10px;*!*/
/*!*  margin-left: -40px;*!*/
/*!*  height: 500px;*!*/
/*!*}*!*/

/*!*#covers.internal.template-4 .template-linkedin .drop-avatar .dropArea {*!*/
/*!*  margin-bottom: 80px;*!*/
/*!*}*!*/

/*!*.internal.template-4 .cover-webinar {*!*/
/*!*  display: flex;*!*/
/*!*  justify-content: space-between;*!*/
/*!*  background-color: red;*!*/
/*!*!*}*!*!*/

/*!*.internal.template-4 .react-transform-wrapper {*!*/
/*!*  left:0;*!*/
/*!*}*!*/

/*!*#covers.internal.template-4 .cover-wrapper-template-facebook .drop-avatar {*!*/
/*!*  height: 570px;*!*/
/*!*}*!*/

/*!*#covers.internal.template-4 .cover-wrapper-template-linkedin .drop-avatar {*!*/
/*!*  height: 570px;*!*/
/*!*}*!*/








