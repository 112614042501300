
$products: (
  "amper": "AMP",
  "annotated": "ANT",
  "aqua": "AQ",
  "clion": "CL",
  "datagrip": "DG",
  "dataspell": "DS",
  "datalore": "DL",
  "dottrace": "DT",
  "dotcover": "DC",
  "dotpeek": "DP",
  "dotmemory": "DM",
  "hub": "HB",
  "fleet": "FL",
  "goland": "GO",
  "intellij-idea": "IJ",
  "pycharm": "PC",
  "phpstorm": "PS",
  "qodana": "QD",
  "resharper": "RS",
  "resharper-cpp": "RScpp",
  "code-with-me": "CWM",
  "teamcity": "TC",
  "toolbox": "TB",
  "jetbrains": "JB",
  "jetbrains-academy": "EDU",
  "rider": "RD",
  "rubymine": "RM",
  "webstorm": "WS",
  "youtrack": "YT"
);
$internals: "twitter", "facebook", "linkedin", "youtubeCommunity", "instagram", "countdown" "share", "blog";

@each $product, $abbr in $products {
  @each $internal in $internals {
    //$iconType: if($product == "fleet" or $product == "code-with-me", "icon-full", "square");

  //  .internal #{$abbr} .cover-wrapper-template-#{$internal} .logo-1 {
  //
  //  background-image: url("https://d3r50642m20jlp.cloudfront.net/tiles/get_tilesets?prefix=Coverbaker/Internal/${product?.value}/${stage.value}/#{$product}/0.svg");
  //}
  //  .internal.template-8.#{$abbr} .cover-wrapper-template-#{$internal} .logo-1 {
  //    display: block;
  //    background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos_Icons_v2/#{$product}/Type=icon-full.svg");
  //    height: 200px;
  //    margin-bottom: 0;
  //  }

    .internal.template-11.#{$abbr} .cover-wrapper-template-#{$internal} .logo-1 {
      display: block;
      background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos_Icons_v2/#{$product}/Type=icon-full.svg");
      height: 200px;
      margin-bottom: 0;
    }
  }
}

$primaryColors: (
  "AQ": #6B57FF,
  "CL": #21D789,
  "DG": #BA4AF7,
  "DS": #0068FD,
  "EDU": #6B57FF,
  "GO": #7D40FF,
  "IJ": #0047FD,
  "PC": #08B1E6,
  "PS": #6B57FF,
  "RD": #671FFF,
  "RM" : #CC00FF,
  "RR": #21D789,
  "RS": #D31AC2,
  "WS": #129CFF,
  "WRS": #AF1DF5,
  "AMP": #6B57FF,
  "CMP": #000000,
  "FL": #6B57FF,
  "GW": #000000,
  "GZ": #000000,
  "IS": #000000,
  "JBAI": #000000,
  "JBIDEs": #000000,
  "KT": #000000,
  "KTR": #000000,
  "MP": #000000,
  "SPC": #000000,
  "ST": #000000,
  "TB": #000000,
  "YT": #07c3f2

);
@function to-lower-case($string) {
  $lowercase: "abcdefghijklmnopqrstuvwxyz";
  $uppercase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  $result: "";

  @for $i from 1 through str-length($string) {
    $char: str-slice($string, $i, $i);
    $index: str-index($uppercase, $char);

    @if $index {
      $result: $result + str-slice($lowercase, $index, $index);
    } @else {
      $result: $result + $char;
    }
  }

  @return $result;
}

@each $product, $color in $primaryColors {

#covers.emails.#{$product} .product-#{to-lower-case($product)}{
  background-color: $color
}

}
