@import "../layouts.css";
@import "../covers.css";



@font-face {
  font-family: "sourcehan jp";
  src: url("../../fonts/SourceHanSansJP-VF.ttf.woff2") format("woff2");
}
@font-face {
  font-family: "sourcehan cn";
  src: url("../../fonts/SourceHanSansCN-VF.ttf.woff2") format("woff2");
}

@font-face {
  font-family: "sourcehan kr";
  src: url("../../fonts/SourceHanSansKR-VF.ttf.woff2") format("woff2");
}





.emails.language-jp .generalInput
{
  font-family: "sourcehan jp", sans-serif !important;

}

.emails.language-ch .generalInput {
  font-family: "sourcehan cn", sans-serif !important;
}


.emails.language-kr .generalInput
{
  font-family: "sourcehan kr", sans-serif !important;
}


#covers.emails .cover-wrapper div {
  background-size: cover ;
  background-position: center center;
}

#covers.emails .variations {
  display: block;
}

#covers.emails.template-1.huge .template-share .generalInput {

  font-size: 144px;
  line-height: 160px;

}

#covers.emails.template-2 .template-share .generalInput {
position: static;
}


#covers.emails.template-3 .template-share .generalInput {
  position: static;
  font-size: 144px;
  line-height: 160px;
}
#covers.emails.template-4 .template-share .generalInput {
  font-size: 144px;
  line-height: 160px;
}
#covers.emails.template-4 .cover-wrapper-template-share .logo--product-name {
  display: block;
  font-size: 92px;
  left: 160px;
  position: absolute;
  top: 144px;
}
#covers.emails.template-5 .template-share .generalInput {
  font-size: 144px;
  line-height: 160px;
}
#covers.emails.template-5 .cover-wrapper-template-share .logo--product-name {
  display: block;
  font-size: 92px;
  left: 160px;
  position: absolute;
  top: 144px;
}

/*#covers.emails .localization {*/
/*  display: none;*/
/*}*/

/*Email*/

/*Leaderboard*/

#covers.emails .logo {
  display: none;
}

#covers.emails .cover-wrapper-template-leaderboard {
  --cover-zoom-level: 2;
}
#covers.emails .template-leaderboard .generalInput {
  font-size: calc(var(--multiplier) * 43px);
  width: 100%;
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--multiplier) * 49px);
  text-align: center;
}

/*Half*/
#covers.emails .cover-wrapper-template-half {
  --cover-zoom-level: 2;
}
#covers.emails .template-half .mainContent {
  display: flex;
}
#covers.emails .template-half .generalInput {
  font-size: calc(var(--multiplier) * 43px);
  width: 100%;
  height: 100%;
  align-content: center;
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--multiplier) * 49px);
  text-align: center;
}

/*Content*/
#covers.emails .cover-wrapper-template-content {
  --cover-zoom-level: 2;
}
#covers.emails .template-content .mainContent {
  display: flex;
}
#covers.emails .template-content .generalInput {
  font-size: calc(var(--multiplier) * 72px);
  width: 100%;
  height: 100%;
  align-content: center;
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--multiplier) * 72px);
  text-align: center;
}

/*LongContent*/
#covers.emails .cover-wrapper-template-longcontent {
  --cover-zoom-level: 2;
}
#covers.emails .template-longcontent .mainContent {
  display: flex;
}
#covers .template-longcontent .generalInput {
  font-size: calc(var(--multiplier) * 43px);
  width: 100%;
  height: 100%;
  align-content: center;
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--multiplier) * 49px);
  text-align: center;
}

/*Layout-1*/
#covers.emails.template-1 .template-leaderboard .logo {
  display: block;
  position: static;
  margin: 0;
  padding: 0;
}
#covers.emails.template-1 .template-leaderboard .logo--image {
  display: none;
}
#covers.emails.template-1 .template-leaderboard .generalInput {
  display: none !important;
}

#covers.emails.template-1 .template-leaderboard {
  text-align: center
}

#covers.emails.template-1 .template-leaderboard .mainContent {
  width: max-content;
  position: static;
  display: inline-flex;
  align-items: baseline;
  flex-direction: row;
  margin: 0 auto;
}
#covers.emails.template-1 .template-leaderboard .logo--product-name::before {
display: none;
}

#covers.emails.template-1 .template-leaderboard .logo--product-name {
  display: block !important;
  width: max-content;
  position: static !important;
  margin: 0;
 padding: 0;
  font-size: calc(var(--multiplier) * 43px);
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--multiplier) * 49px);
  margin-right: calc(var(--multiplier) * 0.1555em);
}

#covers.emails.template-1 .template-leaderboard .thirdLine {
  display: block;
  font-size: calc(var(--multiplier) * 43px);
  font-style: normal;
  font-weight: 400;
  line-height: calc(var(--multiplier) * 49px);

}

/*Layout-2*/
#covers.emails.template-2 .template-half,
#covers.emails.template-2 .template-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

}

#covers.emails.template-2 .template-half .mainContent {
  display: flex;
  /*height: 100%;*/
  align-content: center;
}

#covers.emails.template-2 .firstLine {
  align-content: center;
  position: relative;
  top: -30px;
  margin: 0 auto;
  order: 2;
  display: block;
  width: max-content;
  overflow: visible;
  border-radius: calc(var(--multiplier) * 24px);
  font-size: calc(var(--multiplier) * 18px);
  font-style: normal;
  font-weight: 600;
  color: black;
  background-color: #fff;;
  padding: calc(var(--multiplier) * 12px) calc(var(--multiplier) * 32px);
}


#covers.emails.template-2 .template-half .generalInput {
  position: static;
  order: 1;
  font-size: calc(var(--multiplier) * 43px);
  width: 100%;
  /*height: unset;*/
  align-content: center;
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--multiplier) * 49px);
  text-align: center;
}



#covers.emails.template-2 .template-content .generalInput {
  position: static;
  order: 1;
  font-size: calc(var(--multiplier) * 43px);
  width: 100%;
  height: max-content;
  align-content: center;
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--multiplier) * 49px);
  text-align: center;
  padding-bottom: 35px;
}

#covers.emails.template-2 .template-content .firstLine {
  top: 10px;
}

#covers.emails.template-2 .template-content .mainContent {
  height: max-content;
  margin: auto 0;
}
#covers.emails.template-3 .template-longcontent .generalInput {
  display: none;
}

#covers.emails.template-3 .template-longcontent .firstLine {
  display: none;
}

#covers.emails.template-3 .template-longcontent .mainContent {
  height: max-content;
  margin: auto 0;

}
#covers.emails.template-3 .template-longcontent .secondLine {
  display: block;
  position: relative;
  top:-5px;
  order: 1;
  font-size: calc(var(--multiplier) * 43px);
  width: 100%;
  height: max-content;
  align-content: center;
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--multiplier) * 49px);
  text-align: center;

}

#covers.emails.template-3 .template-longcontent .dateInput {
  display: block;
  position: relative;
  top: 6px;
  order: 1;
  font-size: calc(var(--multiplier) * 40px);
  width: 100%;
  height: max-content;
  align-content: center;
  font-style: normal;
  font-weight: 400;
  line-height: calc(var(--multiplier) * 44px);
  text-align: center;
  color: white;
}


#covers.WRS .set-9 .generalInput,
#covers.WRS .set-10 .generalInput
{
 color: white;
}

#covers.blacktext .generalInput,
#covers.blacktext .dateInput
{
  color: black !important;
}

#covers.EDU.blacktext.template-2 .set-9 .firstLine{
  background-color: #6858f6;
  color: white;

}

#covers.EDU.blacktext.template-1 .logo--product-name,
#covers.EDU.blacktext.template-1 .thirdLine
{
  color: black;
  font-size: 70px  !important;
}

