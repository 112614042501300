body {
  margin: 0;
  padding: 0;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body.not-chrome::before {
  content: "Use Chrome for better experience";
  background-color: #FFA726;
  width: 100%;
  color: #424242;
  display: block;
  text-align: center;
  padding: 20px;
  font-size: 20px;
  /*font-family: "JetBrains Sans", sans-serif;*/
}

.toolbar .design.variations {
  display: none;
}
.social .toolbar .design.variations,
.internal .toolbar .design.variations
{
  display: block;
}

