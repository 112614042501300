@import "~reset-css/reset.css";
@import "fonts/jetbrainssans.css";

body {
  --wt-offset-12: 175px;
  --wt-offset: 42px;
  --wt-offset-2: 75px;
  --wt-offset-3: 115px;
  --wt-offset-4: 170px;
  --wt-offset-5: 230px;
  --wt-offset-6: 260px;
  --wt-offset-7: 300px;
  --wt-offset-8: 345px;
  --wt-offset-9: 445px;
  --wt-offset-10: 485px;
  background-color: #222;
  font-family: "JetBrains Sans New", sans-serif;
}

.App {
  text-align: left;
  height: 100vh;
  display: grid;
  grid-template-columns: calc(100vw - 250px) 250px;
}

.wt-tabs__tab-list {
  display: flex;
  flex-direction: column;
}

.offset-top-3-square {
  padding-top: 175px !important;
}

.offset-left-4-square {
  margin-left: 250px !important;
}

.offset-right-2-square {
  margin-right: 185px !important;
}

#container {
  padding: 24px;
  grid-column: 1;
  overflow: scroll;
}

/*#covers .wt-container {*/
/*    padding-left: 0;*/
/*    margin-left: 0;*/
/*    !*transform: scale(0.5) translateY(-1650px) translateX(-600px);*!*/
/*}*/

.artboard_wrapper {
  margin-bottom: 24px !important;
  margin-top: 0 !important;
}

#covers .header {
  padding-left: calc(var(--wt-offset-2) * 2 / 3);
  padding-top: calc(var(--wt-offset-4) * 4 / 3);
  height: 600px;
  overflow: hidden;
}

#covers .header h2 {
  font-size: 95px !important;
  line-height: 1em !important;
  letter-spacing: -0.02em !important;
}

.header h2:focus,
.place:focus,
.date:focus,
.name:focus,
.cta_text:focus,
.mission:focus,
.editable:focus {
  outline: 1px dashed gray;
}

#covers .name,
#covers .mission {
  /*position:absolute;*/
  line-height: 1.1em !important;
  color: #b3b3b3 !important;
  padding-left: calc(var(--wt-offset-2) * 2 / 3);
  /*bottom: var(--wt-offset-2);*/
  /*left: var(--wt-offset-2);;*/
}

#covers #n0 h4 {
  font-size: 52px !important;
  line-height: 1em !important;
}

#covers #n0 h4.mission {
  bottom: -50px;
  display: none;
}

#covers #n1 h2 {
  font-size: 128px !important;
  line-height: 1em !important;
  letter-spacing: -0.03em !important;
  margin-left: var(--wt-offset-3);
}

#covers #n1 h4.name {
  font-size: 36px !important;
  top: 705px;
  left: 110px;
}

#covers #n1 h4.mission {
  font-size: 36px !important;
  top: 745px;
  left: 110px;
}

#covers #n2 h2 {
  font-size: 170px !important;
  line-height: 1em !important;
  letter-spacing: -0.03em !important;
  margin-left: var(--wt-offset-4);
  padding-top: var(--wt-offset-7);
}

#covers #n2 h4.name {
  font-size: 46px !important;
  top: 950px;
  left: var(--wt-offset-4);
}

#covers #n2 h4.mission {
  font-size: 46px !important;
  top: 1000px;
  left: var(--wt-offset-4);
}

#covers h1 {
  padding-bottom: 30px;
  padding-left: 35px;
  color: #808080;
}

.wt-tabs__tab {
  margin: 0 10px;
  padding: 2px 0;
}

grammarly-extension {
  display: none !important;
}

.toolbar {
  position: fixed;
  right: 0;
  background-color: #1b1b1b;
  /*font-family: "system-ui", sans-serif !important;*/
  width: 250px;
  height: 100vh;
  padding: 24px;
  box-sizing: border-box;
  z-index: 2;
}

.control {
  margin-bottom: 24px;
}

.link_button{
  margin-bottom: 16px;
}

.translate_button{
  margin-bottom: 16px;
  margin-right: 10px;
}

.cai-link {
  color: rgba(255, 255, 255, 0.4);
  text-decoration: none;
  position: absolute;
  bottom: 24px;
  /*font-family: "JetBrains Sans";*/
  font-size: 18px;
  font-weight: 200;
  font-feature-settings: "aalt" on;
}

.cai-link:hover {
  color: white;
}
