
$products: (
  "amper": "AMP",
  "annotated": "ANT",
  "aqua": "AQ",
  "clion": "CL",
  "datagrip": "DG",
  "dataspell": "DS",
  "datalore": "DL",
  "dottrace": "DT",
  "dotcover": "DC",
  "dotpeek": "DP",
  "dotmemory": "DM",
  "hub": "HB",
  "fleet": "FL",
  "goland": "GO",
  "intellij-idea": "IJ",
  "pycharm": "PC",
  "phpstorm": "PS",
  "qodana": "QD",
  "resharper": "RS",
  "resharper-cpp": "RScpp",
  "code-with-me": "CWM",
  "teamcity": "TC",
  "toolbox": "TB",
  "jetbrains": "JB",
  "jetbrains-academy": "EDU",
  "rider": "RD",
  "rubymine": "RM",
  "webstorm": "WS",
  "youtrack": "YT"
);
$socials: "twitter", "facebook", "linkedin", "youtubeCommunity", "instagram", "countdown" "share", "blog";

@each $product, $abbr in $products {
  @each $social in $socials {
    //$iconType: if($product == "fleet" or $product == "code-with-me", "icon-full", "square");

  //  .social #{$abbr} .cover-wrapper-template-#{$social} .logo-1 {
  //
  //  background-image: url("https://d3r50642m20jlp.cloudfront.net/tiles/get_tilesets?prefix=Coverbaker/Social/${product?.value}/${stage.value}/#{$product}/0.svg");
  //}
  //  .social.template-8.#{$abbr} .cover-wrapper-template-#{$social} .logo-1 {
  //    display: block;
  //    background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos_Icons_v2/#{$product}/Type=icon-full.svg");
  //    height: 200px;
  //    margin-bottom: 0;
  //  }

    .livestream.template-11.#{$abbr} .cover-wrapper-template-#{$social} .logo-1 {
      display: block;
      background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos_Icons_v2/#{$product}/Type=icon-full.svg");
      height: 200px;
      margin-bottom: 0;
    }
  }
}


$primaryColors: (
  "AQ": #6B57FF,
  "CL": #21D789,
  "DG": #BA4AF7,
  "DL": #087cfa,
  "DS": #0068FD,
  "EDU": #6B57FF,
  "GO": #7D40FF,
  "IJ": #0047FD,
  "PC": #08B1E6,
  "PS": #6B57FF,
  "RD": #671FFF,
  "RM" : #CC00FF,
  "RR": #21D789,
  "RS": #D31AC2,
  "WS": #129CFF,
  "WRS": #AF1DF5
);

@each $product, $color in $primaryColors {

#covers.livestream.#{$product} .template-streamYardOverlay .generalInput {
  background: linear-gradient(to right, $color, black);
}

}
