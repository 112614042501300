
/*@import "../layouts.css";*/
/*@import "../covers.css";*/
/*Reset*/

#covers.livestream .withupload {
  padding: 0;
}




/*Exportable control*/

.exportable-toggle {
  margin-right: 15px;
}

.no-exportable .cover-wrapper-type-livestream {
  filter: grayscale(100%);
  opacity: 0.1;
  transition: filter 0.3s ease, opacity 0.3s ease;
}

.no-exportable .exportable-toggle {
  opacity: 1.0;
}

.no-exportable h2 {
  color: #444;
}


.livestream .cover-wrapper-template-twitter,
.livestream .cover-wrapper-template-facebook,
.livestream .cover-wrapper-template-linkedin {
  --cover-zoom-level: 2;
}

#covers .toolbar .control button {
  padding: 1px 5px !important;
  margin: 1px 2px !important;
}

.livestream .control.language {
  padding-left: 5px;
}

.livestream .logo-1,
.livestream .logo-2,
.livestream .logo-3 {
  display: none;
}

.livestream .dateInput {
  display: none;
}

.livestream .control.language button {
  display: block;
  font-size: 0.8em;
  margin: 0;
  padding: 0 !important;
}

@keyframes softBlink {
  0% {
    background-color: #3d3d3d;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #3d3d3d;
  }
}


/*@keyframes prompt {*/
/*  0% { opacity: 1; }*/
/*  50% { opacity: 0; }*/
/*  100% { opacity: 1; }*/
/*}*/

/*#covers.livestream .template-twitter .generalInput::after {*/
/*  content: "_";*/
/*  animation: prompt 1s infinite;*/

/*}*/

#covers.livestream .template-twitter .generalInput:focus::after {
  animation: none;
  display: none;
}
/*main styles*/

#covers.livestream .logo {
  /*top:3px;*/
  /*left: -3px;*/
  bottom: unset;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  position: unset;
  /*z-index: 100;*/
}

#covers.livestream .mainContent {
  height: 100%;
  width: 100%;
  max-height: unset;
  position: relative;
  padding: 0;
}
#covers.livestream .logo .logo--image {
  display: block;
  width: 335px;
  height: 72px;
  margin: 0;
  background-size: 335px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");
}

#covers.livestream.EDU .logo--image {
  width: 556px;
  height: 75px;
  padding-left: 10px;
  background-size: 570px 75px;
  margin-top: -3px;
  margin-left: 3px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS Academy/JetBrains_Academy_logo_white.svg");

}

#covers.livestream.whitelogo .logo--image {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_white_logo_white.svg");
}


#covers.livestream.EDU .logo--product-name {
  display: none;
}

/*Twitter*/


#covers.livestream .withupload {
  padding: 48px;
}


#covers.livestream.IDE .logo--image {
  order: 1;
  width: 378px;
  height: 62px;
  background-size: 378px 62px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDEs/JETBRAINS_IDEs_logo_white.svg");
}

#covers.livestream.IDE .logo--product-name {
  font-weight: 600;
  font-size: 40px;
  line-height: 0;
  padding: 0;
  padding-top: 8px;
  order: 2;
}


#covers.livestream .logo--product-name::before {
  content: "|";
  padding-left: 20px;
  padding-right: 2px;
  position: relative !important;
  font-size: 48px;
  top: -2px !important;
}

#covers.livestream .episodeInput {
  display: block;
  content: 'Livestream';
  color: white;
  font-weight: 600;
  font-size: 40px;
  margin-top: 0;
  line-height: 45px;
  order: 3;
  background-color: black;
  border-radius: 30px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  right: 0;
  height: 45px;
  white-space: nowrap;
}

/*#covers.livestream.EDU .template-youtubeThumbnail .episodeInput {*/
/*  margin-top: -50px;*/
/*}*/

/*#covers.livestream.EDU .template-twitter .episodeInput {*/
/*  margin-top: -50px;*/
/*}*/

/*#covers.livestream.EDU .template-facebook .episodeInput {*/
/*  margin-top: -50px;*/
/*}*/

/*#covers.livestream.EDU .template-linkedin .episodeInput {*/
/*  margin-top: -50px;*/
/*}*/

/*#covers.livestream.EDU .template-youtubeCommunity .episodeInput {*/
/*  margin-top: -50px;*/
/*}*/

/*#covers.livestream.EDU .template-instagram .episodeInput {*/
/*  margin-top: -50px;*/
/*}*/

#covers.livestream.EDU .template-share .logo--product-name {
  display: none;

}


.livestream.isProductInText .logo--product-name,
.livestream.isProductInText .logo--product-name::before  {
  display: none;
}

#covers.livestream.EDU.isProductInText .product-edu .logo--product-name,
#covers.livestream.EDU.isProductInText .product-edu .logo--product-name::before  {
  display: none;
}

#covers.livestream.EDU.isProductInText .product-edu   .logo--image{
  width: 335px;
  height: 72px;
  margin: 0 11px 0 0;
  background-size: 335px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");

}


#covers.livestream .generalInput {
  display: inline;
  font-size: 80px;
  line-height: 80px;
  max-height: 100%;
  font-weight: 600;
}


/*#covers.livestream.normal .generalInput{*/
/*  font-size: 96px;*/
/*  line-height: 96px;*/
/*}*/

/*#covers.livestream.small .generalInput{*/
/*  font-size: 80px;*/
/*  line-height: 80px;*/
/*}*/

.livestream.IDE .logo--product-name {
  padding: 10px 0 0 10px;
  font-size: 49px;
  line-height: 48px;
  font-weight: 600;
  white-space: nowrap;
}

.livestream .logo--product-name {
  padding-left: 0;
  padding-top: 9px;
  position: relative;
  font-size: 49px;
}

.livestream .mainContent .firstLine {
  display: block;
  width: 680px;
  overflow: visible;
  color: white;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  /*padding-bottom: 10px;*/
  padding-top: 10px;
  order: 3;
  /*margin-bottom: 5px;*/
}

.livestream .mainContent  .positionInput {
  display: block;
  overflow: visible;
  color: white;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  padding-bottom: 0;
  order: 4;
  margin-bottom: 0;
}


.livestream.isTranslated .control.language button {
  animation: softBlink 2s 5;

}

.livestream.isTranslated .control.language button[data-test-state="active"] {
  background-color: transparent;
  animation: none;
}

.livestream .drop-avatar,
.livestream .mask {
  display: block;
}



.livestream .toolbar h5 {
  color: grey;
  margin-bottom: 10px;
}

.livestream .logo {
  position: absolute;
  bottom: 70px;
}

/*.livestream .cover-wrapper-template-twitter .template-twitter,*/
/*.livestream .cover-wrapper-template-facebook .template-facebook,*/
/*.livestream .cover-wrapper-template-facebook .template-linkedin*/
/*{*/
/*  padding: 60px 72px;*/
/*}*/



/*.livestream .firstLine {*/
/*  display: none;*/
/*}*/

.livestream .cover-wrapper-template-twitter .mainContent,
.livestream .cover-wrapper-template-facebook .mainContent,
.livestream .cover-wrapper-template-linkedin .mainContent {
  width: 100%;
}

/*.livestream .cover-wrapper-template-twitter .mainContent {*/
/*  max-height: 400px;*/
/*}*/

/*.livestream .cover-wrapper-template-facebook .mainContent {*/
/*  max-height: 370px;*/
/*}*/

#covers.livestream .generalInput {
  display: grid;
  align-content: center;
  height: 100%;
  margin-top: 8px;
}


/*Linkedin*/

/*#covers.livestream .template-linkedin .logo {*/
/*  top:-3px;*/
/*}*/
/*.livestream .cover-wrapper-template-linkedin .generalInput {*/
/*  margin-bottom: -3px;*/
/*}*/




/*Twitter*/

.livestream .cover-wrapper-template-twitter  .positionInput {
  display: none;
}


#covers.livestream .cover-wrapper-template-twitter .generalInput {
  width: 630px;
  font-size: 80px;
  line-height: 80px;
  margin-bottom: -10px;
}


.livestream .cover-wrapper-template-twitter .firstLine {
  position: absolute;
  bottom: 0;
}
  /*Facebook*/

.livestream .cover-wrapper-template-facebook .positionInput {
  display: none;
}

#covers.livestream .cover-wrapper-template-facebook .generalInput {
  width: 630px;
  font-size: 80px;
  line-height: 80px;
  margin-bottom: -8px;
}

#covers.livestream .cover-wrapper-template-facebook .generalInput {
  display: grid;
  align-content: center;
  height: 100%;
  margin-top: 3px;
}


.livestream .cover-wrapper-template-facebook  .firstLine {
  position: absolute;
  bottom: 7px;
  z-index: 100;
}

/*LinkedIn*/


#covers.livestream .cover-wrapper-template-linkedin .generalInput {
  width: 630px;
  font-size: 80px;
  line-height: 80px;
  margin-bottom: 49px;
  height: 420px;
}

.livestream .cover-wrapper-template-linkedin .positionInput {
  display: none;
}

.livestream .cover-wrapper-template-linkedin  .firstLine {
  position: absolute;
  bottom: 8px;
  z-index: 100;
}


/*YouTube Community*/

/*#covers.livestream .template-youtubeCommunity {*/
/*  padding: 90px 96px;*/
/*}*/

#covers.livestream .template-youtubeCommunity {
  padding: 72px;
}

#covers.livestream .template-youtubeCommunity .logo {
  order: 1;
}

#covers.livestream .template-youtubeCommunity .mainContent {
  display: flex;
}

#covers.livestream .template-youtubeCommunity .generalInput {
  order: 2;
 margin-top: 181px;
  /*margin-top: 109px;*/
  width: 692px;
  height: 440px;
  font-size: 88px;
  line-height: 88px;
  align-content: unset;
  display: unset;
}


/*#covers.livestream .template-youtubeCommunity .logo {*/
/*  left: -3px;*/
/*  bottom: 0;*/
/*}*/


.livestream .cover-wrapper-template-youtubeCommunity .dateInput,
.livestream .cover-wrapper-template-youtubeCommunity .positionInput {
  display: block;
}
.livestream .cover-wrapper-template-youtubeCommunity .firstLine {
  font-size: 40px;
  margin-top: 54px;
  order: 4;
}

.livestream .cover-wrapper-template-youtubeCommunity .positionInput {
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  opacity: 70%;
}

.livestream .cover-wrapper-template-youtubeCommunity .dateInput {
  color: white;
  margin-top: 651px;
  margin-bottom: 0;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  order: 3;
}

.livestream.twospeakers .cover-wrapper-template-youtubeCommunity .dateInput {
  color: white;
  margin-top: 555px;
  margin-bottom: 0;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  order: 3;
}

.livestream.twospeakers .cover-wrapper-template-youtubeCommunity .secondLine {
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  display: block;
  order: 5;
  margin-top: 16px;
  /*position: absolute;*/
  /*bottom: 30px;*/
}

.livestream.twospeakers .cover-wrapper-template-youtubeCommunity .thirdLine {
  display: block;
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  opacity: 70%;
  order: 6;
}


/*Instagram Stories*/

.livestream .cover-wrapper-template-instagram {
  --cover-zoom-level: 3;
}

.livestream .cover-wrapper-template-instagram .mainContent {
  display: flex;
}

#covers.livestream .cover-wrapper-template-instagram .logo {
  margin-top: 171px;
  margin-left: -3px;
  order: 1;
}

#covers.livestream.IDE .cover-wrapper-template-instagram .logo--image {
  height: 72px;
  width: 454px;
  background-size: 454px 72px;
}

#covers.livestream.IDE .cover-wrapper-template-instagram .logo--product-name {
  font-size: 48px;
}

#covers.livestream .cover-wrapper-template-instagram .episodeInput {
  position: absolute;
  top: 384px;
  right: 570px;
  padding: 9px 40px;
  font-size: 48px;
}



#covers.livestream .cover-wrapper-template-instagram .story-2 {
  padding: 96px;
}

#covers.livestream .cover-wrapper-template-instagram .generalInput {
  overflow: visible;
  margin-top: 258px;
  max-height: 100%;
  font-size: 120px;
  line-height: 120px;
  order: 2;
  position: static;
  height: 600px;
  display: block;
  align-content: unset;
}

#covers.livestream .cover-wrapper-template-instagram .dateInput {
  display: block;
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
  color: white;
  margin-top: 74px;
  z-index: 100;
  width: 465px;
  order: 3;
}

#covers.livestream .cover-wrapper-template-instagram .firstLine,
.livestream .cover-wrapper-template-instagram .story-2 .positionInput {
  display: none;
}


#covers.livestream .cover-wrapper-template-instagram .dropArea {
  /*position: absolute;*/
  /*right: 0;*/
  height: 150px !important;
  width: 150px !important;
}


/*Instagram Stories Countdown*/
#covers.livestream .template-countdown {
  padding: 96px;
}
#covers.livestream .template-countdown .generalInput {
  display: none;
}

#covers.livestream .template-countdown .logo {
  display: none;
}

#covers.livestream .cover-wrapper-template-countdown .dropArea {
  height: 150px !important;
  width: 150px !important;
}
#covers.livestream .cover-wrapper-template-countdown .firstLine {
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
  color: white;
  margin-top: 925px;
}

#covers.livestream .cover-wrapper-template-countdown .positionInput {
  font-size: 48px;
  line-height: 48px;
  font-weight: 400;
  color: white;
}

#covers.livestream .cover-wrapper-template-countdown .drop-avatar {
  overflow: unset !important;
  width: 100%;
}



#covers.livestream .section-countdown  .exportable-toggle {
  margin-left:-45px;

  :nth-child(1) {
   display: none !important;
  }

  /*span::after {*/
  /*  background-color: #444 !important;*/
  /*}*/
}



/*Blog share*/

:root {
  --multiplier: 2;
}

#covers.livestream .cover-wrapper-template-share {
  --cover-zoom-level: 4;
}

#covers.livestream .cover-wrapper-template-share .template-share {
  padding: calc(var(--multiplier) * 60px) 0 calc(var(--multiplier) * 140px) calc(var(--multiplier) * 72px);
  background-color: black;
}

#covers.livestream .cover-wrapper-template-share .logo .logo--image {
  width: calc(var(--multiplier) * 280px);
  height: calc(var(--multiplier) * 60px);
  background-size: calc(var(--multiplier) * 280px) calc(var(--multiplier) * 60px);
}

#covers.livestream.IDE .cover-wrapper-template-share .logo .logo--image {
  width: calc(var(--multiplier) * 380px);
  height: calc(var(--multiplier) * 60px);
  background-size: calc(var(--multiplier) * 380px) calc(var(--multiplier) * 60px);
}



#covers.livestream .cover-wrapper-template-share .logo .logo--product-name {
  font-size: calc(var(--multiplier) * 40px) !important;
  line-height: calc(var(--multiplier) * 40px) ;
  padding: 0;
  padding-top: calc(var(--multiplier) * 6px);
}

#covers.livestream .cover-wrapper-template-share  .logo--product-name::before {
  padding-left: calc(var(--multiplier) * 17px);
  padding-right: calc(var(--multiplier) * 10px);
  font-size: calc(var(--multiplier) * 47px);

}

#covers.livestream .cover-wrapper-template-share .positionInput {
  display: none;
}

#covers.livestream .cover-wrapper-template-share .generalInput {
  position: static;
  width: calc(var(--multiplier) * 762px);
  font-size: calc(var(--multiplier) * 80px);
  line-height: calc(var(--multiplier) * 96px);
  margin-top: calc(var(--multiplier) * 5px);
  padding-top: 0;
}

#covers.livestream .cover-wrapper-template-share .firstLine {
  position: absolute;
  font-size: calc(var(--multiplier) * 40px);
  line-height: calc(var(--multiplier) * 40px);
  width: 1480px;
  top: calc(var(--multiplier) * 475px);
}



#covers.livestream .cover-wrapper-template-share .episodeInput {
  font-size: calc(var(--multiplier) * 38px);
  border-radius: calc(var(--multiplier) * 30px);
  padding-left: calc(var(--multiplier) * 36px);
  padding-right: calc(var(--multiplier) * 36px);
  padding-top: calc(var(--multiplier) * 3px);
  padding-bottom: calc(var(--multiplier) * 3px);
  line-height: calc(var(--multiplier) * 45px);
  height: calc(var(--multiplier) * 45px);
}

#covers.livestream .cover-wrapper-template-share .mainContent {
  max-height: 950px;
  width: 1000px;
}

#covers.livestream .cover-wrapper-template-share .mainContent {
  width: 100%;
  margin-right: 60px;

}

#covers.livestream .cover-wrapper-template-share .drop-avatar {
  overflow: unset !important;
  width: 66%;
}





/*Featured*/


#covers.livestream .cover-wrapper-template-blog {
  --cover-zoom-level: 4;
}

#covers.livestream .cover-wrapper-template-blog .template-blog {
  padding: calc(var(--multiplier) * 60px) 0 calc(var(--multiplier) * 140px) calc(var(--multiplier) * 72px);
  background-color: black;
}

#covers.livestream .cover-wrapper-template-blog .logo {
  display: none;
  /*transform: scale(2);*/
  /*transform-origin: top left;*/

}

#covers.livestream .cover-wrapper-template-blog .positionInput {
  display: none;
}

#covers.livestream .cover-wrapper-template-blog .generalInput {
  width: calc(var(--multiplier) * 762px);
  font-size: calc(var(--multiplier) * 80px);
  line-height: calc(var(--multiplier) * 96px);
  margin-top: calc(var(--multiplier) * -22px);
  padding-top: 0;
}

#covers.livestream .cover-wrapper-template-blog .firstLine {
  position: absolute;
  font-size: calc(var(--multiplier) * 40px);
  line-height: calc(var(--multiplier) * 40px);
  width: 1480px;
  top: calc(var(--multiplier) * 474px);
  height: 200px;
  align-content: end;
}




#covers.livestream .cover-wrapper-template-blog .episodeInput {
  display: none;
}

#covers.livestream .cover-wrapper-template-blog .mainContent {
  max-height: 1000px;
  width: 1000px;
}

#covers.livestream .cover-wrapper-template-blog .drop-avatar {
  overflow: unset !important;
  width: 66%;
}

#covers.livestream .cover-wrapper-template-blog .drop-avatar .dropArea {
  right: 30px;
}


/*YouTube Thumbnail*/




#covers.livestream .cover-wrapper-template-youtubeThumbnail .positionInput {
  display: none;
}

#covers.livestream .cover-wrapper-template-youtubeThumbnail .firstLine {
  position: absolute;
  bottom:42px;
  z-index: 100;
}

#covers.livestream .cover-wrapper-template-youtubeThumbnail .generalInput {
  font-size: 96px;
  line-height: 96px;
  order: 1;
  height: 480px;
  width: 710px;
  padding: 0;
  margin-bottom: 81px;
}



/*YouTube First Slide*/


#covers.livestream .template-youtubeFirstSlide {
  padding: 72px;
}

#covers.livestream .cover-wrapper-template-youtubeFirstSlide  .logo {
  width: 100%;

}

#covers.livestream.IDE .cover-wrapper-template-youtubeFirstSlide .logo--image {
  width: 450px;
  height: 73px;
  background-size: 450px 73px;
  }


#covers.livestream .cover-wrapper-template-youtubeFirstSlide .logo--product-name {
  font-size: 48px;
  line-height: 48px;
  /*padding: 10px 0 0 5px;*/
}
#covers.livestream .cover-wrapper-template-youtubeFirstSlide .logo--product-name::before {
  position: unset;
  top: unset;
}

#covers.livestream .cover-wrapper-template-youtubeFirstSlide  .episodeInput {
  right: 0;
  /*top: 34px;*/
  font-size: 48px;
  line-height: 48px;
  padding: 8px 40px 13px 35px;
  border-radius: 60px;
}

#covers.livestream .cover-wrapper-template-youtubeFirstSlide .generalInput {
  font-size: 140px;
  line-height: 140px;
  margin-top: 0;
  margin-bottom: 129px;
  padding-top: 0;
  height: 700px;
  max-width: 1173px;
}

#covers.livestream .cover-wrapper-template-youtubeFirstSlide .positionInput {
  display: block;
  font-size: 48px;
  line-height: 48px;
  font-weight: 400;
  color: white;
  margin-top: 82px;
  position: absolute;
  top: 805px;

}

#covers.livestream .cover-wrapper-template-youtubeFirstSlide .firstLine {
  position: absolute;
  top: 830px;
  font-size: 48px;
  line-height: 48px;
}

#covers.livestream .cover-wrapper-template-youtubeFirstSlide {
  --cover-zoom-level: 2;
}

/*StreamYardBackground*/

#covers.livestream .template-streamYardBackground {
  background-color: black;
}

#covers.livestream .template-streamYardBackground .mainContent {
  display: none;
}

#covers.livestream .template-streamYardBackground .drop-avatar {
  display: none;
}


#covers.livestream .cover-wrapper-template-streamYardBackground {
  --cover-zoom-level: 2;
}

/*StreamYardOverlay*/

#covers.livestream .template-streamYardOverlay {
  padding: 0;
}
#covers.livestream.IJ .template-streamYardOverlay .mainContent {
  width: 100%;
}

#covers.livestream .template-streamYardOverlay .logo,
#covers.livestream .template-streamYardOverlay .positionInput,
#covers.livestream .template-streamYardOverlay .dateInput,
#covers.livestream .template-streamYardOverlay .firstLine,
#covers.livestream .template-streamYardOverlay .drop-avatar
{
  display: none;
}

#covers.livestream .template-streamYardOverlay .drop-avatar {
  display: none;
}

#covers.livestream .template-streamYardOverlay .generalInput {
  position: absolute;
  right: 0;
  left: unset;
  top:0;
  width: max-content;
  display: block;
  font-size: 25px;
  line-height: 28px;
  border-bottom-left-radius: 20px;
  padding: 3px 24px;
  font-weight: 600;
  min-width: 200px;
  /*max-width: 1500px;*/
  text-align: center;
  height: 38px;
  margin: 0;
  white-space: nowrap;
}
#covers.livestream .template-streamYardOverlay .generalInput div {
  display: inline;
  padding-left: 5px;

}
/*#covers.livestream .template-streamYardOverlay .generalInput div::before {*/
/*  content: "";*/
/*  padding-left: 2px;*/
/*}*/

#covers.livestream .cover-wrapper-template-streamYardOverlay {
  --cover-zoom-level: 2;
}

#covers.livestream .cover-wrapper-template-streamYardOverlay {
  background-repeat: repeat;
  background-size: 10px 10px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Common/transparent.png");
}

#covers.livestream.blacktext .cover-wrapper-template-streamYardOverlay  .editable {
  color: white !important;
}



/*Email banner */
#covers.livestream .cover-wrapper-template-emailBanner {
  --cover-zoom-level: 2;
}

#covers.livestream .template-emailBanner .mainContent{
  height: 100%;
  /*align-content: center !important;*/
}

#covers.livestream .cover-wrapper-template-emailBanner {

  .logo {
    display: none;
  }

  .drop-avatar {
    display: none;
  }
  .positionInput {
    display: none;
  }
  .dateInput {
    display: none;
  }
}


#covers.livestream .cover-wrapper-template-emailBanner .logo {
  display: none;
}

#covers.livestream .cover-wrapper-template-emailBanner {
  .generalInput {
    font-size: calc(2 * 43px);
    line-height: calc(2 * 49px);
    text-align: center;
    margin: 0;
    margin-top: auto;
    height: unset;
    overflow: hidden ;
    align-content: normal;
    width: 100%;
    position: static;
    padding-bottom: 0;

  }

  .firstLine {
    font-size: calc(2 * 40px);
    line-height: calc(2 * 44px);
    text-align: center;
    margin-bottom: auto;
    font-weight: 400;
    width: 100%;
    position: static;
  }

}

/*EmailPhoto*/

/*Email banner */
#covers.livestream .cover-wrapper-template-emailPhoto {
  --cover-zoom-level: 2;
  background-repeat: repeat;
  background-size: 10px 10px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Common/transparent.png");
  .mainContent {display: none}

  .drop-avatar {
    position: relative;
    width: 100%;
  }
  .dropArea {
    width: 90px !important;
    height: 90px !important;
    font-size: 10px;
    margin: 0 auto 10px auto !important;
  }

  .react-transform-component {
    width: 200px !important;
  }

  :hover .controls {
    display: block !important;
  }

  :hover .controls button {
    margin-left: 3px;
  }


}



  /*#covers.livestream.EDU .logo--image {*/
/*  width: 340px;*/
/*  background-position: left top;*/
/*}*/

/*#covers.livestream .section-blog,*/
/*#covers.livestream .section-youtubeCommunity,*/
/*#covers.livestream .section-instagram*/
/*{*/
/*  display: none;*/
/*}*/



/*#covers.livestream .cover-webinar  {*/
/*  padding-right: 0;*/

/*}*/


/*#covers.livestream .template-twitter .generalInput,*/
/*#covers.livestream .template-facebook .generalInput,*/
/*#covers.livestream .template-linkedin .generalInput {*/
/*  bottom: 0;*/
/*}*/


/*Youtube community*/

/*#covers.livestream.huge  .template-youtubeCommunity  .generalInput,*/
/*#covers.livestream.normal .template-youtubeCommunity  .generalInput,*/
/*#covers.livestream.small .template-youtubeCommunity  .generalInput*/
/*{*/
/*  font-size: 96px;*/
/*  line-height: 96px;*/
/*}*/

/*#covers.livestream .template-youtubeCommunity {*/
/*  padding: 72px;*/
/*}*/
/*#covers.livestream .template-youtubeCommunity .generalInput {*/
/*  bottom: unset;*/
/*  left:0*/
/*}*/


/*livestream*/

/*#covers.livestream.huge  .template-share  .generalInput,*/
/*#covers.livestream.normal .template-share  .generalInput,*/
/*#covers.livestream.small .template-share  .generalInput*/
/*{*/
/*  font-size: 144px;*/
/*  line-height: 160px;*/
/*}*/

/*Featured*/

/*#covers.livestream.huge  .template-blog  .generalInput,*/
/*#covers.livestream.normal .template-blog  .generalInput,*/
/*#covers.livestream.small .template-blog .generalInput*/
/*{*/
/*  font-size: 160px;*/
/*  line-height: 192px;*/
/*}*/



#covers.livestream  .logo--product-name {
  order: 2;
/*display: block;*/
/*  position: absolute;*/
/*  top: 72px;*/
/*  left: 80px;*/
  font-size: 48px;
}

#covers.livestream.EDU .logo--product-name {
  display: none;
  font-size: 0;

}

#covers.livestream.EDU .episodeInput {
  display: block;
}

#covers.livestream.JB  .logo--product-name {
  display: none;

}





#covers.livestream .template-share .logo-1 {
  display: none;
}



/*#covers.livestream .section-blog {*/
/*  display: none;*/
/*}*/

/*#covers.livestream .drop-avatar*/
/*{*/
/*  background-image: none;*/
/*  display: block;*/
/*  height: 610px;*/
/*  width: 600px;*/
/*  position: absolute;*/
/*  padding-left: 0 ;*/
/*  border-radius: 20px 0 0;*/
/*  top: auto;*/
/*  bottom: 0;*/
/*  !*align-self: flex-end;*!*/
/*}*/

#covers.livestream .drop-avatar .dropArea {
  width: 180px;
  height: 180px;
  border-radius: 90px;
  margin-right: 0;
  top: unset;
}




/*#covers.livestream .template-facebook .drop-avatar .dropArea*/
/*{*/
/* margin-bottom: 75px;*/
/*}*/

/*#covers.livestream .template-youtubeCommunity .drop-avatar .dropArea {*/
/*  margin-bottom: 10px;*/
/*  margin-left: 50px;*/
/*}*/

/*#covers.livestream .template-instagram .drop-avatar .dropArea {*/
/*  margin-bottom: 10px;*/
/*  margin-left: -40px;*/
/*  height: 500px;*/
/*}*/

/*#covers.livestream .template-linkedin .drop-avatar .dropArea {*/
/*  margin-bottom: 80px;*/
/*}*/

/*.livestream .cover-webinar {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  background-color: red;*/
/*!*}*!*/

/*.livestream .react-transform-wrapper {*/
/*  left:0;*/
/*}*/

/*#covers.livestream .cover-wrapper-template-facebook .drop-avatar {*/
/*  height: 570px;*/
/*}*/

/*#covers.livestream .cover-wrapper-template-linkedin .drop-avatar {*/
/*  height: 570px;*/
/*}*/



#covers.livestream .drop-avatar {
  width: 65%;
  /*overflow: hidden;*/
}

#covers.livestream .cover-wrapper-template-youtubeCommunity .drop-avatar {
  width: 75%;
}

#covers.livestream .cover-wrapper-template-instagram .drop-avatar {
  width: 100%;
  height: 70%;
}

/*#covers.livestream .drop-avatar .react-transform-wrapper   {*/
/*  overflow: visible !important;*/
/*}*/




