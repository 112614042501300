/* Common Styles */
#covers.social.stage-php-annotated {
  --date-input-font-size: 32px;
  --date-input-padding: 6px 15px;
  --date-input-margin-bottom: 14px;
  --date-input-border-radius: 9px;
}

#covers.social.stage-php-annotated.IDE .cover-wrapper .logo--image {
  width: 336px;
  height: 72px;
  margin: 0 11px 0 0;
  background-size: 335px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");

}


#covers.social.stage-php-annotated .localization,
#covers.social.stage-php-annotated .translate_button,
#covers.social.stage-php-annotated .logo--product-name {
  display: none;
}

#covers.social.stage-php-annotated .generalInput {
  font-size: 96px;
  line-height: 96px;
  padding-bottom: 94px;
}

#covers.social.stage-php-annotated .dateInput {
  width: auto;
  display: inline-block;
  font-size: var(--date-input-font-size);
  color: #fff;
  padding: var(--date-input-padding);
  margin-bottom: var(--date-input-margin-bottom);
  border-radius: var(--date-input-border-radius);
  border: solid 1px #fff;
  z-index: 100;
}

#covers.social.stage-php-annotated.isProductInText .dateInput {
  display: block;
}

#covers.social.stage-php-annotated .logo-1 {
  display: block !important;
  bottom: unset;
  background-size: cover;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/PS/icons/php-annotated/0.svg");
}

/* Twitter Styles */
#covers.social.stage-php-annotated .template-twitter {
  .logo-1 {
    left: 681px;
    top: 181px;
    width: 451px;
    height: 327px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
  }
}

/* Facebook Styles */
#covers.social.stage-php-annotated .template-facebook {
  .logo-1 {
    left: 660px;
    top: 140px;
    width: 451px;
    height: 327px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
  }
}

/* LinkedIn Styles */
#covers.social.stage-php-annotated .template-linkedin {
  .logo-1 {
    left: 660px;
    top: 143px;
    width: 451px;
    height: 327px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
  }

  .generalInput {
    padding-bottom: 83px;
  }

  .dateInput {
    bottom: -18px;
    padding-right: 10px;
  }
}

/* YouTube Community Styles */
#covers.social.stage-php-annotated .template-youtubeCommunity {
  .exportable-toggle {
    margin-left: -45px;

    :nth-child(1) {
      display: none !important;
    }
  }

  .logo-1 {
    left: 525px;
    top: 631px;
    width: 488px;
    height: 351px;
  }

  .generalInput {
    font-size: 120px;
    line-height: 120px;
    position: static;
    margin-bottom: 35px;
    padding-bottom: 0;
  }

  .dateInput {
    width: max-content;
    line-height: 44px;
    margin-top: 14px;
    padding: 0 15px;
  }
}

/*#covers.social.stage-php-annotated.isProductInText .template-youtubeCommunity .logo-1 {*/
/*  top: 506px;*/
/*}*/

/* Instagram Styles */
#covers.social.stage-php-annotated .template-instagram {
  .logo-1 {
    top: 945px;
    left: 233px;
    width: 769px;
    height: 551px;
  }

  .generalInput {
    font-size: 120px;
    line-height: 120px;
    padding-top: 231px;
    position: static;
    margin-bottom: 62px;
    padding-bottom: 0;
  }

  .dateInput {
    display: block;
    position: static;
    width: max-content;
    margin-top: -19px;
  }

  .logo {
    display: none;
  }
}

/* Blog Styles */
#covers.social.stage-php-annotated .template-blog {
  .logo-1 {
    left: calc(2 * 718px);
    top: calc(2 * 269px);
    width: calc(2 * 459px);
    height: calc(2 * 337px);
  }

  .generalInput {
    font-size: 160px !important;
    line-height: 192px;
    position: static;
    margin-top: 0;
    height: auto;
  }

  .dateInput {
    font-size: 64px;
    line-height: 60px;
    font-weight: normal;
    padding: 15px 30px;
    width: max-content;
    border-radius: 18px;
    border: solid 2px #fff;
    white-space: nowrap;
    margin-top: 52px;
    margin-left: 15px;
  }
}

/* Share Styles */
#covers.social.stage-php-annotated .template-share {
  background-size: contain;
  .logo--image {
    background-size: cover !important;
    width: calc(2* 336px) !important;
    height: calc(2* 72px) !important;
    }

  .logo-1 {
    left: calc(2* 719px);
    top: calc(2* 153px);
    width: calc(2* 457px);
    height: calc(2* 337px);
  }

  .generalInput {
    font-size: 192px !important;
    line-height: 192px !important;;
    position: static;
    margin-top: 219px;
    margin-bottom: -16px;
    padding-top: 20px;
    height: 600px !important;
    align-content: end;
  }

  .dateInput {
    font-size: 64px;
    line-height: 60px;
    font-weight: normal;
    padding: 15px 30px;
    width: max-content;
    border-radius: 18px;
    border: solid 2px #fff;
    white-space: nowrap;
    margin-top: 82px;
    margin-bottom: -3px;
  }
}

/* Toolbar Styles */
#covers.social.stage-php-annotated .toolbar .template,
#covers.social.stage-php-annotated .toolbar .language {
  display: none;
}
/*Section Styles*/
#covers.social.stage-php-annotated .section-youtubeCommunity .exportable-toggle {
  span {
    display: none;
  }
  label {
    margin-left: 53px;
  }
  label span {
    display: block;
  }
}

#covers.social.stage-php-annotated .drop-avatar,
#covers.social.stage-php-annotated .logo--product-name
{
  display: none !important;
}
