.controls {
  position: absolute;
  top: 3%;
  bottom: 3%;
  right: 3%;
  display: none;
  grid-template-rows: repeat(3, max-content) auto;
  grid-row-gap: 3%;
  align-items: flex-end;
  z-index: 41;
}

.preview {
  cursor: grab;
  overflow: hidden;
}

.previewUploaded {
  cursor: hand;
}

.preview:hover .controls {
  display: grid;
}

.previewUploaded .wrapper {
  height: 100%;
  width: 100%;
}

.previewUploaded .dropButton {
  display: none;
}

.roundButton {
  zoom: var(--cover-zoom-level, 1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);
  cursor: pointer;
  z-index: 1;
  color: white;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  font-family: "arial", sans-serif;
}

.roundButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.dropButton {
  zoom: var(--cover-zoom-level, 1);
  position: absolute;
  border: 1px dashed rgba(0, 0, 0, 0.5);
  background-color: transparent;
  cursor: pointer;
  left: 10%;
  right: 10%;
  margin: auto;
  top: 10%;
  bottom: 10%;
  padding: 0;
  border-radius: 2.5%;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
  z-index: 12;
  filter: invert(1);
}


.dropButton:hover {
  color: #000000;
  border: 1px dashed rgba(0, 0, 0, 1.0);
}
