/* Common Styles */
#covers.social.stage-dotnet-annotated {
  --date-input-font-size: 32px;
  --date-input-padding: 6px 15px;
  --date-input-margin-bottom: 14px;
  --date-input-border-radius: 9px;
}

#covers.social.stage-dotnet-annotated.IDE .cover-wrapper .logo--image {
  width: 336px;
  height: 72px;
  margin: 0 11px 0 0;
  background-size: 335px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");
}

#covers.social.stage-dotnet-annotated .localization,
#covers.social.stage-dotnet-annotated .translate_button,
#covers.social.stage-dotnet-annotated .logo--product-name {
  display: none;
}

#covers.social.stage-dotnet-annotated .generalInput {
  font-size: 96px;
  line-height: 96px;
  padding-bottom: 94px;
}

#covers.social.stage-dotnet-annotated .dateInput {
  width: auto;
  display: inline-block;
  font-size: var(--date-input-font-size);
  color: #fff;
  padding: var(--date-input-padding);
  margin-bottom: var(--date-input-margin-bottom);
  border-radius: var(--date-input-border-radius);
  border: solid 1px #fff;
  z-index: 100;
}

#covers.social.stage-dotnet-annotated.isProductInText .dateInput {
  display: block;
}

#covers.social.stage-dotnet-annotated .logo-1 {
  display: block;
  bottom: unset;
  background-size: cover;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/DN/icons/dotnet-annotated/0.svg");
}

/* Twitter Styles */
#covers.social.stage-dotnet-annotated .template-twitter {
  .logo--image {
    width: 342px;
    height: 72px;
  }
  .logo-1 {
    left: 758px;
    top: 239px;
    width: 334px;
    height: 271px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
    margin-left: 0;
    padding: 6px 22px;
  }
}

/* Facebook Styles */
#covers.social.stage-dotnet-annotated .template-facebook {
  .logo--image {
    width: 342px;
    height: 72px;
  }
  .logo-1 {
    left: 758px;
    top: 188px;
    width: 334px;
    height: 271px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
    margin-left: 0;
    padding: 7px 22px;
  }
}

/* LinkedIn Styles */
#covers.social.stage-dotnet-annotated .template-linkedin {
  .logo--image {
    width: 342px;
    height: 72px;
  }
  .logo-1 {
    left: 758px;
    top: 188px;
    width: 334px;
    height: 271px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
  }



  .dateInput {
    bottom: -4px;
    padding: 6px 19px;
  }
}

/* YouTube Community Styles */
#covers.social.stage-dotnet-annotated .template-youtubeCommunity {
  .exportable-toggle {
    margin-left: -45px;

    :nth-child(1) {
      display: none !important;
    }
  }

  .logo-1 {
    left: 608px;
    top: 675px;
    width: 371px;
    height: 300px;
  }

  .generalInput {
    font-size: 120px;
    line-height: 120px;
    position: static;
    margin-bottom: 35px;
    padding-bottom: 0;
  }

  .dateInput {
    width: max-content;
    line-height: 44px;
    margin-top: 14px;
    padding: 0 22px;
  }
}


/* Instagram Styles */
#covers.social.stage-dotnet-annotated .template-instagram {
  .logo-1 {
    top: 924px;
    left: 405px;
    width: 588px;
    height: 476px;
  }

  .generalInput {
    font-size: 120px;
    line-height: 120px;
    padding-top: 119px;
    position: static;
    margin-bottom: 62px;
    padding-bottom: 0;
  }

  .dateInput {
    display: block;
    position: static;
    width: max-content;
    margin-top: -26px;
    margin-left: 0;
    padding: 6px 21px;
  }

  .logo {
    display: none;
  }
}

/* Blog Styles */
#covers.social.stage-dotnet-annotated .template-blog {
  .logo-1 {
    left: calc(2 * 830px);
    top: calc(2 * 310px);
    width: calc(2 * 356px);
    height: calc(2 * 288px);
  }

  .generalInput {
    font-size: 160px !important;
    line-height: 192px;
    position: static;
    margin-top: 0;
    height: auto;
  }

  .dateInput {
    font-size: 64px;
    line-height: 60px;
    font-weight: normal;
    padding: 16px 42px;
    width: max-content;
    border-radius: 18px;
    border: solid 2px #fff;
    white-space: nowrap;
    margin-top: 52px;
    margin-left: 15px;
  }
}

/* Share Styles */
#covers.social.stage-dotnet-annotated .template-share {
  background-size: contain;
  .logo--image {
    background-size: cover !important;
    width: calc(2* 336px) !important;
    height: calc(2* 72px) !important;
  }

  .logo-1 {
    left: calc( 2 * 830px);
    top: calc( 2 * 194px);
    width: calc( 2 * 356px);
    height: calc( 2 * 288px);
  }

  .generalInput {
    font-size: 192px;
    line-height: 192px;
    position: static;
    margin-top: 214px;
    margin-bottom: -16px;
    padding-top: 20px;
    height: 600px !important;
    align-content: end;
  }

  .dateInput {
    font-size: 64px;
    line-height: 60px;
    font-weight: normal;
    padding: 15px 42px;
    width: max-content;
    border-radius: 18px;
    border: solid 2px #fff;
    white-space: nowrap;
    margin-top: 89px;
    margin-bottom: -3px;
  }
}

/* Toolbar Styles */
#covers.social.stage-dotnet-annotated .toolbar .template,
#covers.social.stage-dotnet-annotated .toolbar .language {
  display: none;
}

/*Section Styles*/
#covers.social.stage-dotnet-annotated .section-youtubeCommunity .exportable-toggle {
  span {
    display: none;
  }
  label {
    margin-left: 53px;
  }
  label span {
    display: block;
  }
}
