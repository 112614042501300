/* Common Styles */
#covers.social.stage-java-annotated {
  --date-input-font-size: 32px;
  --date-input-padding: 6px 15px;
  --date-input-margin-bottom: 14px;
  --date-input-border-radius: 9px;
}

#covers.social.stage-java-annotated .localization,
#covers.social.stage-java-annotated .translate_button,
#covers.social.stage-java-annotated .logo--product-name {
  display: none !important;
}

#covers.social.stage-java-annotated .generalInput {
  font-size: 96px;
  line-height: 96px;
  padding-bottom: 96px;
}

#covers.social.stage-java-annotated .dateInput {
  width: auto;
  display: inline-block;
  font-size: var(--date-input-font-size);
  color: #fff;
  padding: var(--date-input-padding);
  margin-bottom: var(--date-input-margin-bottom);
  border-radius: var(--date-input-border-radius);
  border: solid 1px #fff;
  z-index: 100;
}

/*#covers.social.stage-java-annotated.isProductInText .dateInput {*/
/*  display: block;*/
/*}*/

#covers.social.stage-java-annotated .logo-1 {
  display: block !important;
  bottom: unset;
  background-size: cover;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/IJ/icons/java-annotated/0.svg");
}

#covers.social.template-5.stage-java-annotated .drop-avatar {
  display: none !important;
}
#covers.stage-java-annotated.template-5 .mainContent {
  width: 100%;
}

#covers.social.stage-java-annotated.template-3 .mainContent .editable.firstLine,
#covers.social.stage-java-annotated.template-2 .mainContent .editable.firstLine

{
  display: none;
}

/*#covers.stage-java-annotated.template-4 .drop-avatar {*/
/*  display: block;*/
/*}*/

  /* Twitter Styles */
#covers.social.stage-java-annotated .template-twitter {
  .logo-1 {
    left: 723px;
    top: 106px;
    width: 383px;
    height: 485px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
  }
}

/* Facebook Styles */
#covers.social.stage-java-annotated .template-facebook {
  .logo-1 {
    left: 757px;
    top: 60px;
    width: 383px;
    height: 485px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
  }
}

/* LinkedIn Styles */
#covers.social.stage-java-annotated .template-linkedin {
  .logo-1 {
    left: 720px;
    top: 69px;
    width: 383px;
    height: 485px;
  }

  .dateInput {
    position: absolute;
    bottom: -2px;
  }

  .generalInput {
    padding-bottom: 83px;
  }

  .dateInput {
    bottom: -18px;
    padding-right: 10px;
  }
}

/* YouTube Community Styles */
#covers.social.stage-java-annotated .template-youtubeCommunity {
  .exportable-toggle {
    margin-left: -45px;

    :nth-child(1) {
      display: none !important;
    }
  }

  .logo-1 {
    left: 633px;
    top: 494px;
    width: 386px;
    height: 488px;
  }

  .generalInput {
    font-size: 120px;
    line-height: 120px;
    position: static;
    margin-bottom: 35px;
    padding-bottom: 0;
  }

  .dateInput {
    width: max-content;
    line-height: 44px;
    margin-top: 0;
    padding: 0 15px;
  }
}

/*#covers.social.stage-java-annotated.isProductInText .template-youtubeCommunity .logo-1 {*/
/*  top: 506px;*/
/*}*/

/* Instagram Styles */
#covers.social.stage-java-annotated .template-instagram {
  .logo-1 {
    top: 810px;
    left: 426px;
    width: 560px;
    height: 710px;
  }

  .generalInput {
    font-size: 120px;
    line-height: 120px;
    padding-top: 117px;
    position: static;
    margin-bottom: 50px;
    padding-bottom: 0;
  }

  .dateInput {
    display: block;
    position: static;
    width: max-content;
    margin-top: -19px;
  }

  .logo {
    display: none;
  }
}

/* Blog Styles */
#covers.social.stage-java-annotated .template-blog {
  .logo-1 {
    left: 1592px;
    top: 230px;
    width: 769px;
    height: 974px;
  }

  .generalInput {
    font-size: 160px !important;
    line-height: 192px;
    position: static;
    margin-top: 0;
    height: auto;
  }

  .dateInput {
    font-size: 64px;
    line-height: 60px;
    font-weight: normal;
    padding: 15px 30px;
    width: max-content;
    border-radius: 18px;
    border: solid 2px #fff;
    white-space: nowrap;
    margin-top: 45px;
    margin-left: 15px;
  }
}

/*#covers.social.stage-java-annotated.isProductInText .template-blog .logo-1 {*/
/*  left: 1592px;*/
/*  top: 230px;*/
/*  width: 764px;*/
/*  height: 966px;*/
/*}*/

/* Share Styles */
#covers.social.stage-java-annotated .template-share {
  background-size: contain;

  .logo-1 {
    left: 1591px;
    top: 204px;
    width: 745px;
    height: 942px;
  }

  .generalInput {
    font-size: 192px;
    line-height: 192px;
    position: static;
    margin-top: 256px;
  }

  .dateInput {
    font-size: 64px;
    line-height: 60px;
    font-weight: normal;
    padding: 15px 30px;
    width: max-content;
    border-radius: 18px;
    border: solid 2px #fff;
    white-space: nowrap;
    margin-top: 74px;
    margin-left: 0;
  }
}

/* Toolbar Styles */
#covers.social.stage-java-annotated .toolbar .template,
#covers.social.stage-java-annotated .toolbar .language
{
  display: none;
}

/*Section Styles*/
#covers.social.stage-java-annotated .section-youtubeCommunity .exportable-toggle {
  span {
    display: none;
  }
  label {
    margin-left: 53px;
  }
  label span {
    display: block;
  }
}
