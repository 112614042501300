/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 100;*/
/*  src: url("JetBrainsSans-Thin.woff2") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 200;*/
/*  src: url("JetBrainsSans-Light.woff2") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  src: url("JetBrainsSans-Book.woff2") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: url("JetBrainsSans-Regular.woff2") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  src: url("JetBrainsSans-Medium.woff2") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  src: url("JetBrainsSans-SemiBold.woff2") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  src: url("JetBrainsSans-Bold.woff2") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 800;*/
/*  src: url("JetBrainsSans-ExtraBold.woff2") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "JetBrains Sans";*/
/*  font-style: normal;*/
/*  font-weight: 900;*/
/*  src: url("JetBrainsSans-Ultra.woff2") format("woff");*/
/*}*/
@font-face {
  font-family: "JetBrains Sans New";
  font-style: normal;
  font-weight: 100 900;
  src: url("JetBrainsSans[wght].woff2") format("woff2");
}
