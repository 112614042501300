.toolbar h5 {
    color: grey;
    margin-bottom: 10px;
  }


#covers .toolbar .control button {
  padding: 1px 5px !important;
  margin: 1px 2px !important;
}

.control.language {
  padding-left: 5px;
}


.control.language button {
  display: block;
  font-size: 0.8em;
  margin: 0;
  padding: 0 !important;
}

@keyframes softBlink {
  0% {
    background-color: #3d3d3d;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #3d3d3d;
  }
}


.isTranslated .control.language button {
  animation: softBlink 2s 5;

}

.isTranslated .control.language button[data-test-state="active"] {
  background-color: transparent;
  animation: none;
}

.toolbar div span.control {
  display: flex;
  flex-wrap: wrap;
}

#covers .toolbar .control button[data-test-state=active] {
  border: 2px solid #64a4f8 !important;
}

#covers .toolbar .control button {
  margin: 1px 2px !important;
  padding: 1px 5px !important;
}

.control span {
 display: none;
}

#covers.livestream .toolbar .localization {
  display: none;
}

#covers.livestream .toolbar .tags {
  display: none;
}
#covers.livestream .toolbar .slack_bot_rumpled_jackets {
    margin-bottom: 20px;
  }


#covers .info span {
  color: grey !important;
}

#covers .info {
  position: absolute;
  bottom: 30px;
}
#covers .info li {
  margin-bottom: 0;
  padding-bottom: 0;
}
#covers .info svg {
  margin-right: 10px;
}

#covers .info span:hover
{
  color: white !important;
}

#covers .info svg {
  color: grey !important;
}
