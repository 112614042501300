@import "./javaannotated.css";
@import "./phpannotated.css";
@import "./dotnetannotated.css";
@import "./jbnewsletter.css";
@import "./tcnewsletter.css";
@import "./mpnewsletter.css";
@import "../layouts.css";

@font-face {
  font-family: "sourcehan jp";
  src: url("../../fonts/SourceHanSansJP-VF.ttf.woff2") format("woff2");
}
@font-face {
  font-family: "sourcehan cn";
  src: url("../../fonts/SourceHanSansCN-VF.ttf.woff2") format("woff2");
}

@font-face {
  font-family: "sourcehan kr";
  src: url("../../fonts/SourceHanSansKR-VF.ttf.woff2") format("woff2");
}





.social.language-jp .generalInput,
.social.language-jp .dateInput,
.social.language-jp .firstLine
{
  font-family: "sourcehan jp", sans-serif !important;

}

.social.language-ch .generalInput,
.social.language-ch .dateInput,
.social.language-ch .firstLine {
  font-family: "sourcehan cn", sans-serif !important;
}


.social.language-kr .generalInput,
.social.language-kr .dateInput,
.social.language-kr .firstLine
{
 font-family: "sourcehan kr", sans-serif !important;
}


.social .cover-wrapper-template-twitter,
.social .cover-wrapper-template-facebook,
.social .cover-wrapper-template-linkedin {
  --cover-zoom-level: 2;
}


.social .logo-1,
.social .logo-2,
.social .logo-3 {
  display: none;
}

.social .dateInput {
  display: none;
}



/*@keyframes prompt {*/
/*  0% { opacity: 1; }*/
/*  50% { opacity: 0; }*/
/*  100% { opacity: 1; }*/
/*}*/

/*#covers.social .template-twitter .generalInput::after {*/
/*  content: "_";*/
/*  animation: prompt 1s infinite;*/

/*}*/


#covers.social .template-twitter .generalInput:focus::after {
  animation: none;
  display: none;
}
/*main styles*/

#covers.social .logo {
  /*top:3px;*/
  left: -3px;
  bottom: unset;
}

#covers.social .mainContent {
  height: 100%;
  max-height: unset;
  position: relative;
  padding: 0;
}
#covers.social .logo--image
{
  width: 335px;
  height: 72px;
  margin: 0 11px 0 0;
  background-size: 335px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");
}
#covers.social.whitelogo .logo--image {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_white_logo_white.svg");
}

#covers.social.blacklogo .logo--image {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_black.svg");
}

#covers.social.blacklogo .logo--product-name {
  color: black;
}

#covers.social.EDU .logo--image {
  width: 556px;
  height: 75px;
  padding-left: 10px;
  background-size: 570px 75px;
  margin-top: -3px;
  margin-left: 3px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS Academy/JetBrains_Academy_logo_white.svg");

}

#covers.social.blacklogo.EDU .logo--image {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS Academy/JetBrains_Academy_logo_black.svg");

}

#covers.social.EDU .logo--product-name {
  display: none;
}
#covers.social.IDE .logo--image {
  width: 454px;
  height: 72px;
  background-size: 454px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDEs/JETBRAINS_IDEs_logo_white.svg");
}
#covers.social.whitelogo.IDE .logo--image {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/logos/JETBRAINS_IDEs_white_logo_white.svg");
}

.social .logo--product-name::before {
  content: "|";
  position: relative;
  margin: 0 14px 0 0;
}



.social.isProductInText .logo--product-name,
.social.isProductInText .logo--product-name::before  {
  display: none;
}

#covers.social.EDU.isProductInText .product-edu .logo--product-name,
#covers.social.EDU.isProductInText .product-edu .logo--product-name::before  {
  display: none;
}

#covers.social.EDU.isProductInText .product-edu   .logo--image{
  width: 335px;
  height: 72px;
  margin: 0 11px 0 0;
  background-size: 335px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");

}


#covers.social .generalInput {
  display: inline;
  font-size: 120px;
  line-height: 120px;
  position: absolute;
  bottom: 0;
  overflow: visible;
  left: 0;
  max-height: 100%;
}


#covers.social.normal .generalInput{
  font-size: 96px;
  line-height: 96px;
}

#covers.social.small .generalInput{
  font-size: 80px;
  line-height: 80px;
}

.social .logo--product-name {
  padding: 10px 0 0 10px;
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
  white-space: nowrap;
}



.social .drop-avatar,
.social .mask {
  display: none;
}


.social .logo {
  position: absolute;
  bottom: 70px;
}

.social .cover-wrapper-template-twitter .template-twitter,
.social .cover-wrapper-template-facebook .template-facebook,
.social .cover-wrapper-template-facebook .template-linkedin
{
  padding: 60px 72px;
}

/*Template-0*/

.social .firstLine {
  display: none;
}

.social .cover-wrapper-template-twitter .mainContent,
.social .cover-wrapper-template-facebook .mainContent,
.social .cover-wrapper-template-linkedin .mainContent {
  width: 100%;
}

.social .cover-wrapper-template-twitter .mainContent {
  max-height: 400px;
}

.social .cover-wrapper-template-facebook .mainContent {
  max-height: 370px;
}

/*Linkedin*/

#covers.social .template-linkedin .logo {
  top:-3px;
}
.social .cover-wrapper-template-linkedin .generalInput {
  margin-bottom: -3px;
}



/*Twitter*/

.social.template-0 .cover-wrapper-template-twitter .logo--image {
  width: 96px;
  height: 96px;
  background-size: 96px;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
}

/*YouTube*/

#covers.social.template-0 .template-youtubeCommunity {
  padding: 96px;
}

#covers.social.template-0 .template-youtubeCommunity .generalInput {
  bottom: unset;
  margin-top: -5px;
  overflow: visible;
  max-height: 100%;
}

#covers.social .template-youtubeCommunity .generalInput {
  bottom: unset;
  margin-top: -5px;
  overflow: visible;
  max-height: 100%;
}

#covers.social .template-youtubeCommunity .logo {
  left: -3px;
  bottom: 0;
}

#covers.social.template-0  .template-youtubeCommunity .logo {
  left: -3px;
  bottom: -6px;
}


.social .cover-wrapper-template-youtubeCommunity .dateInput,
.social .cover-wrapper-template-youtubeCommunity .positionInput {
  display: none;
}




/*Instagram Post*/

.social .cover-wrapper-template-instagram {
  --cover-zoom-level: 3;
}

#covers.social .cover-wrapper-template-instagram .story-2 {
  padding: 96px 96px 96px 96px;
}
#covers.social.template-0 .cover-wrapper-template-instagram .generalInput {
  font-size: 120px;
  line-height: 120px;
  bottom: 484px;
}


.social .cover-wrapper-template-instagram .story-2 .dateInput,
.social .cover-wrapper-template-instagram .story-2 .positionInput {
  display: none;
}

#covers.social .cover-wrapper-template-instagram .generalInput {
  bottom: 391px;
  overflow: visible;
  max-height: 100%;
  font-size: 96px;
  line-height: 96px;
}

#covers.social .cover-wrapper-template-instagram  .logo {
  bottom: 0;
}


.social.template-0 .cover-wrapper-template-instagram .logo-1 {
  display: none;
}



.social .template-instagram.story-2 .logo {
  display: flex;
  width: max-content;
}


/*Social*/

.social .cover-wrapper-template-share .template-share {
  padding: 160px 192px 280px;
}

#covers.social .cover-wrapper-template-share .generalInput {
  font-size: 192px;
  line-height: 192px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  bottom: 0;
}

.social.template-0 .cover-wrapper-template-share .logo-1 {
  display: none;
}

#covers.social .cover-wrapper-template-share .logo--image {
  width: 683px;
  height: 144px;
  margin: 0;
  background-size: 683px 144px;
}

#covers.social.IDE .cover-wrapper-template-share .logo--image {
  width: 910px;
  height: 144px;
  margin: 0;
  background-size: 910px 144px;
}

#covers.social.EDU .cover-wrapper-template-share  .product-edu .logo--image {
  width: 910px;
  height: 144px;
  margin: 0;
  background-size: 910px 144px;
}

#covers.social.EDU.isProductInText .cover-wrapper-template-share  .product-edu .logo--image {
  width: 910px;
  height: 144px;
  margin-left: -100px;
  background-size: 910px 144px;
}

.social .template-share .logo .logo--product-name {
  font-size: 96px;
  line-height: 96px;
  padding-left: 10px;
  margin-top: 5px;
}

.social .template-share .logo .logo--product-name::before {
  padding-left: 25px;
  padding-right: 15px;
}

/*.social .template-share .logo .logo--product-name::after {*/
/*  content: ', JetBrains IDE';*/
/*  font-size: 96px;*/
/*}*/

.social .cover-wrapper-template-share .mainContent {
  max-height: 800px;
  width: 100%;
}

/*Featured*/
#covers.social .template-blog {
  padding: 192px 144px 160px;
}
#covers.social .cover-wrapper-template-blog .mainContent {
  width: 100%;
  padding: 0;
}

#covers.social .cover-wrapper-template-blog .generalInput {
  font-size: 160px;
  line-height: 192px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  bottom: unset;
  height: 100%;
}

/*Qodana*/
#covers.social.QD .story-2 .logo-2 {
  display: none;
}


/*Template-1*/

#covers.social.template-1 .template-twitter .generalInput,
#covers.social.template-1 .template-facebook .generalInput,
#covers.social.template-1 .template-linkedin .generalInput {
  bottom: 10px;
}
#covers.social .template-twitter .generalInput,
#covers.social .template-facebook .generalInput,
#covers.social .template-linkedin .generalInput
{
  width: 100%;
}

#covers.social.template-1.huge .generalInput
{
  font-size: 96px;
  line-height: 96px;

}

/*Youtube community*/


.social .cover-wrapper-template-youtubeCommunity  {
  --cover-zoom-level: 3;
}


#covers.social.template-0  .template-youtubeCommunity  .generalInput
{
  font-size: 120px;
  line-height: 120px;
}

#covers.social.template-1 .template-youtubeCommunity {
  padding: 72px;
}

#covers.social.template-1 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0;
  font-size: 96px;

}

/*Instagram post*/

/*Social*/

#covers.social.template-1  .template-share  .generalInput
{
  font-size: 144px;
  line-height: 160px;
}

/*Featured*/

#covers.social.template-1.huge  .template-blog  .generalInput,
#covers.social.template-1.normal .template-blog  .generalInput,
#covers.social.template-1.small .template-blog .generalInput
{
  font-size: 160px;
  line-height: 192px;
}



/*template-2*/

#covers.social.template-2 .mainContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
}

#covers.social.template-2 .logo {
  top: 0;
}

#covers.social.template-2 .mainContent .generalInput
{
  order: 1;
  bottom: unset;
  font-size: 96px;
  line-height: 96px;
  padding-bottom: 24px;
  margin-bottom: 0;
  position: static;
}

#covers.social.template-2 .mainContent  .editable.firstLine {
  order: 2;
  display: block;
  width: 100%;
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  position: static;
}



/*Linkedin*/

#covers.social.template-2 .template-linkedin .logo {
  top:-3px;
}
.social.template-2 .cover-wrapper-template-linkedin .firstLine {
  margin-bottom: -3px;
}

/*Youtube community*/

#covers.social.template-2 .template-youtubeCommunity .mainContent {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  height: 100%;
}

/*#covers.social.YT.template-2 .template-youtubeCommunity .mainContent {*/
/*justify-content: flex-end;*/
/*}*/

#covers.social.template-2 .template-youtubeCommunity .mainContent  .editable.firstLine {
  order: 2;
  font-size: 60px;
  line-height: 72px;
  margin-left: 25px;
}

#covers.social.YT.template-2 .template-youtubeCommunity .logo--image {
  margin-left: 25px;
  margin-top: -120px;
}


#covers.social.template-2 .cover-wrapper-template-instagram  .logo {
  bottom: 150px;
}


/*#covers.social.YT.template-2 .template-youtubeCommunity .mainContent  .editable.firstLine {*/
/*  order: 2;*/
/*  font-size: 60px;*/
/*  line-height: 72px;*/
/*  justify-items: flex-end;*/
/*  position: static;*/

/*}*/


#covers.social.template-2 .template-youtubeCommunity .mainContent .editable.generalInput
{
  position: static;
  font-size: 120px;
  line-height: 120px;
  order: 1;
  padding-bottom: 0;
  margin-bottom: 32px;
  margin-top: 20px;
  margin-left: 25px;
}
/*#covers.social.YT.template-2 .template-youtubeCommunity .mainContent .editable.generalInput*/
/*{*/
/*  position: static;*/
/*}*/

#covers.social.template-2  .template-youtubeCommunity .logo {
  top: unset;
}
/*#covers.social.template-2 .template-youtubeCommunity .logo--image {*/
/*  height: 142px;*/
/*  margin-left: 23px;*/
/*}*/

#covers.social.template-2.huge  .template-youtubeCommunity  .generalInput,
#covers.social.template-2.normal .template-youtubeCommunity  .generalInput,
#covers.social.template-2.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

#covers.social.template-2 .template-youtubeCommunity {
  padding: 72px;
}
#covers.social.template-2 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}

/*Instagram post*/

#covers.social.template-2 .template-instagram.story-2 .mainContent {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  height: 100%;
}
#covers.social.template-2 .template-instagram.story-2 .mainContent  .editable.firstLine {
  order: 2;
  font-size: 60px;
  line-height: 96px;
  padding-bottom: 540px;
}


#covers.social.template-2 .template-instagram.story-2 .mainContent .editable.generalInput
{
  position: static;
  font-size: 120px;
  line-height: 120px;
  order: 1;
  padding-bottom: 0;
  margin-bottom: 20px;

}

#covers.social.template-2  .template-instagram.story-2 .logo {
  top: unset;
}

#covers.social.template-2.huge  .template-instagram.story-2  .generalInput,
#covers.social.template-2.normal .template-instagram.story-2  .generalInput,
#covers.social.template-2.small .template-instagram.story-2  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

#covers.social.template-2 .template-instagram.story-2 .generalInput {
  bottom: unset;
  left:0
}

/*Social*/

#covers.social.template-2.huge  .template-share  .generalInput,
#covers.social.template-2.normal .template-share  .generalInput,
#covers.social.template-2.small .template-share  .generalInput
{
  font-size: 192px;
  line-height: 192px;
  padding-bottom: 0;
}


#covers.social.template-2.huge  .template-share  .firstLine,
#covers.social.template-2.normal .template-share  .firstLine,
#covers.social.template-2.small .template-share  .firstLine
{
  font-size: 80px;
  line-height: 92px;
  padding-top: 50px;
}

/*Featured*/

#covers.social.template-2.huge  .template-blog  .generalInput,
#covers.social.template-2.normal .template-blog  .generalInput,
#covers.social.template-2.small .template-blog .generalInput
{
  font-size: 160px;
  line-height: 192px;
}


#covers.social.template-2  .template-blog  .editable.firstLine {
  display: none;
}

/*Template-3*/

#covers.social.template-3 .mainContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
}

#covers.social.template-3 .logo {
  top: 0;
}

#covers.social.template-3 .mainContent .generalInput
{
  order: 1;
  bottom: unset;
  font-size: 72px;
  line-height: 80px;
  padding-bottom: 24px;
  margin-bottom: 0;
  position: static;
}

#covers.social.template-3 .mainContent  .editable.firstLine {
  order: 2;
  display: block;
  width: 100%;
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  position: static;
}



/*Linkedin*/

#covers.social.template-3 .template-linkedin .logo {
  top:-3px;
}
.social.template-3 .cover-wrapper-template-linkedin .firstLine {
  margin-bottom: -3px;
}

/*Youtube community*/

#covers.social.template-3 .template-youtubeCommunity .mainContent {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  height: 100%;
}
#covers.social.template-3 .template-youtubeCommunity .mainContent  .editable.firstLine {
  order: 2;
  font-size: 60px;
  line-height: 72px;
}


#covers.social.template-3 .template-youtubeCommunity .mainContent .editable.generalInput
{
  position: static;
  font-size: 96px;
  line-height: 96px;
  order: 1;
  margin-bottom: 8px;
  margin-top: 0;
}

#covers.social.template-3  .template-youtubeCommunity .logo {
  top: unset;
}

#covers.social.template-3.huge  .template-youtubeCommunity  .generalInput,
#covers.social.template-3.normal .template-youtubeCommunity  .generalInput,
#covers.social.template-3.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

#covers.social.template-3 .template-youtubeCommunity {
  padding: 72px;
}
#covers.social.template-3 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}

/*Instagram post*/

#covers.social.template-3 .template-instagram.story-2 .mainContent {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  height: 100%;
}
#covers.social.template-3 .template-instagram.story-2 .mainContent  .editable.firstLine {
  order: 2;
  font-size: 72px;
  line-height: 72px;
  padding-bottom: 411px;
}


#covers.social.template-3 .template-instagram.story-2 .mainContent .editable.generalInput
{
  position: static;
  font-size: 96px;
  line-height: 96px;
  order: 1;
  padding-bottom: 0;
  margin-bottom: 45px;

}

#covers.social.template-3  .template-instagram.story-2 .logo {
  top: unset;
}

#covers.social.template-3.huge  .template-instagram.story-2  .generalInput,
#covers.social.template-3.normal .template-instagram.story-2  .generalInput,
#covers.social.template-3.small .template-instagram.story-2  .generalInput
{
  font-size: 120px;
  line-height: 120px;
}

#covers.social.template-3 .template-instagram.story-2 .generalInput {
  bottom: unset;
  left:0
}

/*Social*/

#covers.social.template-3.huge  .template-share  .generalInput,
#covers.social.template-3.normal .template-share  .generalInput,
#covers.social.template-3.small .template-share  .generalInput
{
  font-size: 144px;
  line-height: 160px;
  padding-bottom: 0;
}


#covers.social.template-3.huge  .template-share  .firstLine,
#covers.social.template-3.normal .template-share  .firstLine,
#covers.social.template-3.small .template-share  .firstLine
{
  font-size: 80px;
  line-height: 92px;
  padding-top: 50px;
}

/*Featured*/

#covers.social.template-3.huge  .template-blog  .generalInput,
#covers.social.template-3.normal .template-blog  .generalInput,
#covers.social.template-3.small .template-blog .generalInput
{
  font-size: 160px;
  line-height: 192px;
}


#covers.social.template-3  .template-blog  .editable.firstLine {
  display: none;
}



/*template-4*/

/*#covers.social.EDU.template-4 .logo--image {*/
/*  width: 340px;*/
/*  background-position: left top;*/
/*}*/


/*#covers.social.template-4 .section-youtubeCommunity,*/
/*#covers.social.template-4 .section-instagram*/
/*{*/
/*  display: none;*/
/*}*/

#covers.social.EDU.template-4  .cover-wrapper-template-share .logo--product-name {
  display: none;
}


#covers.social.template-4 .cover-webinar  {
  padding-right: 0;

}


#covers.social.template-4 .template-twitter .generalInput,
#covers.social.template-4 .template-facebook .generalInput,
#covers.social.template-4 .template-linkedin .generalInput {
  bottom: 0;
}

#covers.social.template-4 .generalInput
{
  font-size: 72px;
  line-height: 80px;

}

/*Youtube community*/

#covers.social.template-4.huge  .template-youtubeCommunity  .generalInput,
#covers.social.template-4.normal .template-youtubeCommunity  .generalInput,
#covers.social.template-4.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

#covers.social.template-4 .template-youtubeCommunity {
  padding: 72px;
  background-color: black;
}
#covers.social.template-4 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}


/*Social*/

#covers.social.template-4  .template-share  .generalInput
{
  font-size: 144px;
  line-height: 160px;
}

/*Featured*/

#covers.social.template-4  .template-blog  .generalInput
{
  font-size: 160px;
  line-height: 192px;
  margin-top: -60px;
}


#covers.social.template-4  .logo--product-name {
display: block;
  position: absolute;
  top: 72px;
  left: 80px;
  font-size: 48px;
}

#covers.social.EDU.template-4 .logo--product-name {
  display: none;
}

#covers.social.JB.template-4  .logo--product-name {
  display: none;
}

#covers.social.template-4  .logo--product-name::before {
display: none;
}


#covers.social.template-4 .mainContent {
  width: 500px;
}




#covers.social.template-4 .template-share .logo-1 {
  display: none;
}

#covers.social.template-4 .template-blog .logo {
  display: none !important;
}




#covers.social.template-4 .drop-avatar
{
  background-image: none;
  display: block;
  height: 710px;
  width: 600px;
  position: absolute;
  padding-left: 0 ;
  border-radius: 20px 0 0;
  top: auto;
  bottom: 0;
  /*align-self: flex-end;*/
}

#covers.social .drop-avatar .dropArea {
  width: 180px;
  height: 180px;
  border-radius: 90px;
  margin-right: 0;
  top: unset;
}
#covers.social.template-4 .template-twitter .drop-avatar .dropArea
{
  margin-bottom: 0px;
}


#covers.social.template-4 .template-facebook .drop-avatar .dropArea
{
 margin-bottom: 0;
}

#covers.social.template-4 .template-youtubeCommunity .drop-avatar .dropArea {
  margin-bottom: 10px;
  margin-left: 50px;
}

#covers.social.template-4 .template-youtubeCommunity .logo {
  bottom: 72px;

}

#covers.social.IDE.template-4 .template-youtubeCommunity .logo {
  bottom: 72px;
  transform: scale(0.9);
  transform-origin: top left;
}

#covers.social.template-4 .template-youtubeCommunity .generalInput {
  font-size: 72px;
  line-height: 72px;
  position: static;
  margin: 0;
}

#covers.social.template-4 .template-youtubeCommunity .mainContent {
  width: 880px;
}



/*#covers.social.template-4 .template-youtubeCommunity .drop-avatar {*/
/*  background-image: none;*/
/*  display: block;*/
/*  height: 200px;*/
/*  width: 250px;*/
/*}*/

#covers.social .template-youtubeCommunity .drop-avatar .dropArea {
  width: 120px;
  height: 120px;
  border-radius: 90px;
  margin-right: 0px;
  right: 40px;
  left: unset;
  top: unset;
  bottom: 30px;
}

#covers.social.template-4 .template-instagram .drop-avatar .dropArea {
  width: 120px;
  height: 120px;
  border-radius: 90px;
  margin-right: 0px;
  right: 40px;
  left: unset;
  top: unset;
  bottom: 30px;
}

#covers.social.template-4 .template-linkedin .drop-avatar .dropArea {
  margin-bottom: 0;
}

.social.template-4 .cover-webinar {
  display: flex;
  justify-content: space-between;
}

.social.template-4 .react-transform-wrapper {
  left:0;
}

#covers.social.template-4 .cover-wrapper-template-facebook .drop-avatar {
  height: 570px;
}

#covers.social.template-4 .cover-wrapper-template-linkedin .drop-avatar {
  height: 570px;
}

#covers.social.template-4 .cover-wrapper-template-share .drop-avatar,
#covers.social.template-4 .cover-wrapper-template-blog .drop-avatar
{
  height: 1340px;
  width: 1280px;
  border-radius: 40px 0 0;
}

#covers.social.template-4 .cover-wrapper-template-share  .logo--product-name {
  display: block;
  position: absolute;
  top: 144px;
  left: 160px;
  font-size: 92px;
}


#covers.social.template-4 .cover-wrapper-template-share .mainContent,
#covers.social.template-4 .cover-wrapper-template-blog .mainContent
{
  width: 1100px;
}


.social.template-4 .cover-wrapper-template-share .template-share,
.social.template-4 .cover-wrapper-template-share .template-blog
{
  padding: 120px 0 120px 144px;
}

#covers.social.template-4 .template-instagram.story-2 {
  background-color: black;
}

#covers.social.template-4 .template-instagram.story-2 .mainContent {
  width: 100%;
}

#covers.social.template-4 .template-instagram.story-2 .generalInput {
  position: static;
  display: block;
  font-size: 120px;
  line-height: 120px;
  margin-top: 250px
}
#covers.social.template-4 .template-instagram.story-2 .logo {
  position: static;
  transform: scale(1.5);
  transform-origin: top left;
}

#covers.social.template-4 .template-instagram.story-2 .drop-avatar {
  width: 830px;
  height: 1056px;
}
#covers.social.template-4 .cover-wrapper-template-share .generalInput {
  font-size: 72px;
  line-height: 72px;

}

  /*template 5*/

#covers.social.template-5 .cover-wrapper-template-share .generalInput {
  font-size: 72px;
  line-height: 72px;

}
#covers.social.template-5 .template-blog  .generalInput
{
  font-size: 160px;
  line-height: 192px;
  margin-top: -60px;
}
/*#covers.social.EDU.template-5 .logo--image {*/
/*  width: 340px;*/
/*  background-position: left top;*/
/*}*/

/*#covers.social.template-5 .section-youtubeCommunity,*/
/*#covers.social.template-5 .section-instagram*/
/*{*/
/*  display: none;*/
/*}*/

#covers.social.template-5 .drop-avatar .dropArea {
  width: 150px;
  height: 150px;
  bottom: 0;
}

#covers.social.EDU.template-5 .logo--product-name {
  display: none;
}

#covers.social.JB.template-5 .logo--product-name {
  display: none;
}

#covers.social.template-5 .cover-webinar  {
  padding-right: 0;

}


#covers.social.template-5 .template-twitter .generalInput,
#covers.social.template-5 .template-facebook .generalInput,
#covers.social.template-5 .template-linkedin .generalInput {
  bottom: 0;
}

#covers.social.template-5 .generalInput
{
  font-size: 72px;
  line-height: 80px;

}

/*Youtube community*/

#covers.social.template-5.huge  .template-youtubeCommunity  .generalInput,
#covers.social.template-5.normal .template-youtubeCommunity  .generalInput,
#covers.social.template-5.small .template-youtubeCommunity  .generalInput
{
  font-size: 96px;
  line-height: 96px;
}

#covers.social.template-5 .template-youtubeCommunity {
  padding: 72px;
}
#covers.social.template-5 .template-youtubeCommunity .generalInput {
  bottom: unset;
  left:0
}


/*Social*/

#covers.social.template-5.huge  .template-share  .generalInput,
#covers.social.template-5.normal .template-share  .generalInput,
#covers.social.template-5.small .template-share  .generalInput
{
  font-size: 144px;
  line-height: 160px;
}

/*Featured*/

#covers.social.template-5.huge  .template-blog  .generalInput,
#covers.social.template-5.normal .template-blog  .generalInput,
#covers.social.template-5.small .template-blog .generalInput
{
  font-size: 160px;
  line-height: 192px;
}


#covers.social.template-5  .logo--product-name {
  display: block;
  position: absolute;
  top: 72px;
  left: 80px;
  font-size: 48px;
}


#covers.social.EDU.template-5 .product-edu .logo--product-name {
  display: none;
}

#covers.social.template-5  .logo--product-name::before {
  display: none;
}


#covers.social.template-5 .mainContent {
  width: 750px;
}




#covers.social.template-5 .template-share .logo-1 {
  display: none;
}


#covers.social.template-5 .drop-avatar
{
  background-image: none;
  display: block;
  height: 610px;
  width: 336px;
  position: absolute;
  padding-left: 0 ;
  border-radius: 20px 0 0;
  top: auto;
  bottom: 0;
  /*align-self: flex-end;*/
}
#covers.social.template-5 .template-twitter .drop-avatar .dropArea
{
  margin-bottom: 20px;
}


#covers.social.template-5 .template-facebook .drop-avatar .dropArea
{
  margin-bottom: 20px;
}

#covers.social.template-5 .template-youtubeCommunity .drop-avatar .dropArea {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  margin-right: -10px;
}

#covers.social.template-5 .template-instagram .drop-avatar .dropArea {
  width: 120px;
  height: 120px;
  margin: 0;
  margin-bottom: 120px;
}

#covers.social.template-5 .template-linkedin .drop-avatar .dropArea {
  margin-bottom: 20px;
}

.social.template-5 .cover-webinar {
  display: flex;
  justify-content: space-between;
  background-color: red;
}

.social.template-5 .react-transform-wrapper {
  left:0;
}

#covers.social.template-5 .cover-wrapper-template-facebook .drop-avatar {
  height: 570px;
}

#covers.social.template-5 .cover-wrapper-template-linkedin .drop-avatar {
  height: 570px;
}
#covers.social.template-5 .cover-wrapper-template-share .drop-avatar,
#covers.social.template-5 .cover-wrapper-template-blog .drop-avatar
{
  height: 1306px;
  width: 772px;
  border-radius: 40px 0 0;

}

#covers.social.template-5 .template-youtubeCommunity .logo {
  bottom: 50px;
}

#covers.social.template-5 .template-instagram {
  background-color: black;
}

#covers.social.template-5 .template-instagram.story-2 .generalInput {
  position: static;
  display: block;
  font-size: 120px;
  line-height: 120px;
  margin-top: 250px
}
#covers.social.template-5 .template-instagram.story-2 .logo {
  position: static;
  transform: scale(1);
  transform-origin: top left;
}

#covers.social.template-5 .cover-wrapper-template-share .drop-avatar .dropArea {
  margin-bottom: 40px;
  margin-right: 20px;
}

#covers.social.template-5 .cover-wrapper-template-blog .drop-avatar .dropArea {
  margin-bottom: 40px;
  margin-right: 20px;
}

#covers.social.template-5 .cover-wrapper-template-share  .logo--product-name {
  display: block;
  position: absolute;
  top: 144px;
  left: 160px;
  font-size: 92px;
}


#covers.social.template-5 .cover-wrapper-template-share .mainContent,
#covers.social.template-5 .cover-wrapper-template-blog .mainContent
{
  width: 1500px;
}


.social.template-5 .cover-wrapper-template-share .template-share {
  padding: 120px 0 120px 144px;

}


/*Template-6*/


/*Twitter*/

.social .cover-wrapper-template-twitter .set-custom .logo--image {
  width: 96px;
  height: 96px;
  background-size: 96px;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
}

/*YouTube*/

/*#covers.social .template-youtubeCommunity.set-custom  .set-custom {*/
/*  padding: 90px 96px;*/
/*}*/

/*#covers.social .template-youtubeCommunity.set-custom  .generalInput {*/
/*  bottom: unset;*/
/*  margin-top: 0;*/
/*  overflow: visible;*/
/*  max-height: 100%;*/
/*}*/

/*#covers.social .template-youtubeCommunity.set-custom  .logo {*/
/*  left: -3px;*/
/*  bottom: 0;*/
/*}*/


/*.social .cover-wrapper-template-youtubeCommunity .set-custom  .dateInput,*/
/*.social .cover-wrapper-template-youtubeCommunity .set-custom  .positionInput {*/
/*  display: none;*/
/*}*/

/*#covers.social .cover-wrapper-template-youtubeCommunity .set-custom   .drop-avatar .dropArea*/
/*{*/

/*  right: 30px;*/
/*  top: auto;*/
/*  bottom: 100px;*/
/*  left: auto;*/
/*  height: 100px;*/
/*  width: 100px;*/
/*}*/


/*Instagram Post*/

.social .cover-wrapper-template-instagram .set-custom  {
  --cover-zoom-level: 2;
}

#covers.social .cover-wrapper-template-instagram .set-custom  .story-2 {
  padding: 96px 96px 248px 96px;
}



.social .cover-wrapper-template-instagram .story-2.set-custom  .dateInput,
.social .cover-wrapper-template-instagram .story-2.set-custom  .positionInput {
  display: none;
}

#covers.social .cover-wrapper-template-instagram.set-custom  .generalInput {
  bottom: 390px;
  overflow: visible;
  max-height: 100%;
  font-size: 96px;
  line-height: 96px;
}

#covers.social .cover-wrapper-template-instagram.set-custom .logo {
  bottom: 0;
}


.social .cover-wrapper-template-instagram .set-custom  .logo-1 {
  display: none;
}



.social .template-instagram.story-2.set-custom  .logo {
  display: flex;
  width: 100%;
}


/*Social*/

.social .cover-wrapper-template-share .template-share.set-custom  {
  padding: 160px 192px 280px;
}

#covers.social .cover-wrapper-template-share .set-custom  .generalInput {
  font-size: 192px;
  line-height: 192px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  bottom: 0;
}

.social .cover-wrapper-template-share .set-custom  .logo-1 {
  display: none;
}

#covers.social .cover-wrapper-template-share .set-custom  .logo--image {
  width: 683px;
  height: 144px;
  margin: 0;
  background-size: 683px 144px;
}

#covers.social.IDE .cover-wrapper-template-share .set-custom  .logo--image {
  width: 910px;
  height: 144px;
  margin: 0;
  background-size: 910px 144px;
}

#covers.social.EDU .cover-wrapper-template-share  .product-edu.set-custom  .logo--image {
  width: 910px;
  height: 144px;
  margin: 0;
  background-size: 910px 144px;
}

#covers.social.EDU.isProductInText .cover-wrapper-template-share  .product-edu.set-custom  .logo--image {
  width: 910px;
  height: 144px;
  margin-left: -100px;
  background-size: 910px 144px;
}

.social .template-share.set-custom  .logo .logo--product-name {
  font-size: 96px;
  line-height: 96px;
  padding-left: 10px;
  margin-top: 5px;
}

.social .template-share.set-custom .logo .logo--product-name::before {
  padding-left: 25px;
  padding-right: 15px;
}


.social .cover-wrapper-template-share .set-custom  .mainContent {
  max-height: 800px;
  width: 100%;
}

#covers.social .template-share {
  background-size: cover;
}

/*Featured*/
#covers.social .template-blog.set-custom  {
  padding: 192px 144px 160px;
}
#covers.social .cover-wrapper-template-blog .set-custom  .mainContent {
  width: 100%;
  padding: 0;
}

#covers.social .cover-wrapper-template-blog .set-custom  .generalInput {
  font-size: 160px;
  line-height: 192px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  bottom: unset;
  height: 100%;
}


#covers.social .set-custom  .drop-avatar
{
  background-image: none;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  padding-left: 0 ;
  top: auto;
  bottom: 0;
}
#covers.social .set-custom  .mask {
  display: block;
  height: 100%;
  width: 100%;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  position: absolute;
  bottom: 0;
}
#covers.social .set-custom  .drop-avatar .dropArea
{
  position: absolute;
  right: 30px;
  top: 30px;
  bottom: auto;
  left: auto;
  height: 100px;
  width: 100px;
}



/*Annotated*/

/*#covers.social.ANT .logo,*/
/*#covers.social.ANT .generalInput*/
/*{*/
/*  display: none;*/
/*}*/

#covers.social.ANT .generalInput {
  font-size: 96px;
  line-height: 96px;
  padding-bottom: 96px;
}

#covers.social.ANT .translate_button {
  display: none;
}

#covers.social.ANT .dateInput {
  width: auto;
  display: inline-block;
  font-size: 32px;
  color: #fff;
  padding: 6px 15px;
  margin-bottom: 14px;
  border-radius: 9px;
  border: solid 1px #fff;
  z-index: 100;
}


#covers.social.ANT .dateInput {
  display: none;
}

#covers.social.ANT.isProductInText .dateInput {
  display: block;
}

#covers.social.ANT .template-twitter .dateInput,
#covers.social.ANT .template-facebook .dateInput,
#covers.social.ANT .template-linkedin .dateInput,
#covers.social.ANT .template-youtubeCommunity .dateInput
{
  position: absolute;
  bottom: 0;
  /*left: 0px;*/
}


#covers.social.ANT .logo-1 {
  display: block;
  bottom: unset;
  background-size: cover;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/ANT/icons/java-annotated/0.svg");
}

#covers.social.ANT .template-twitter .logo-1,
#covers.social.ANT .template-facebook .logo-1,
#covers.social.ANT .template-linkedin .logo-1
{
  left: 780px;
  top: 145px;
  width: 360px;
  height: 455px;
}

#covers.social.ANT .template-facebook .logo-1 {
  width: 320px;
  height: 405px;
  left: 800px;
}

#covers.social.ANT .template-linkedin .logo-1 {
  width: 320px;
  height: 405px;
  left: 800px;
  top: 143px;
}

#covers.social.ANT.isProductInText .template-twitter .logo-1,
#covers.social.ANT.isProductInText .template-facebook .logo-1,
#covers.social.ANT.isProductInText .template-linkedin .logo-1
{
  left: 723px;
  top: 106px;
  width: 382px;
  height: 483px;
}

#covers.social.ANT.isProductInText .template-facebook .logo-1 {
  top: 68px;
  left: 760px;
}

#covers.social.ANT.isProductInText .template-linkedin .logo-1 {
  top: 70px;
  left: 720px;
}

#covers.social.ANT .template-youtubeCommunity .logo-1,
#covers.social.ANT .template-instagram .logo-1
{
  left: 653px;
  top: 406px;
  width: 382px;
  height: 483px;
}

#covers.social.ANT.isProductInText .template-youtubeCommunity .logo-1 {
  top: 506px;
}

#covers.social.ANT .template-instagram .logo-1 {
  top: 810px;
  left: 426px;
  width: 560px;
  height: 710px;
}

#covers.social.ANT .template-share .logo-1,
#covers.social.ANT .template-blog .logo-1
{
  left: 1692px;
  top: 350px;
  width: 764px;
  height: 966px;
}

#covers.social.ANT.isProductInText .template-blog .logo-1,
#covers.social.ANT.isProductInText .template-share .logo-1
{
  left: 1592px;
  top: 230px;
  width: 764px;
  height: 966px;
}

#covers.social.ANT .template-share {
  background-size: contain;
}

#covers.social.ANT .template-youtubeCommunity .generalInput
{
  font-size: 120px;
  line-height: 120px;
}

#covers.social.ANT .template-instagram .generalInput {
  font-size: 120px;
  line-height: 120px;
  padding-top: 117px;
}

#covers.social.ANT .template-share .generalInput,
#covers.social.ANT .template-blog .generalInput
{
  font-size: 160px;
  line-height: 160px;
  position: static;
  margin-top: 220px;
}

#covers.social.ANT .template-blog .generalInput
{
  font-size: 196px !important;
  line-height: 196px;
  position: static;
  margin-top: 96px;
  height: auto;
}



#covers.social.ANT .template-youtubeCommunity  .logo--product-name,
#covers.social.ANT .template-instagram  .logo--product-name
{
  position: absolute;
  left: 80px;
  bottom: -50px;
}


#covers.social.WRS .set-9 .generalInput,
#covers.social.WRS .set-9 .logo,
#covers.social.WRS .set-10 .generalInput,
#covers.social.WRS .set-10 .logo
{
  color: black;
}

#covers.social.WRS .set-9 .logo--image,
#covers.social.WRS .set-10 .logo--image
{
 background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDEs/JETBRAINS_IDEs_logo_black.svg");
}

#covers.social.MP .product-mp .logo--product-name  {
  display: block;
}



/*Tags*/

.translate-toggle {
  display: none;
}
#covers.social .translate-toggle {
  display: block;
}

#covers.social .translate-toggle {
  color: grey;
  font-size: 16px;
  margin-bottom: 15px;
}


#covers.social.ANT .translate-toggle {
  display: none;
}

#covers.social.IH .translate-toggle {
  display: none;
}


/*#covers.social.IJ.template-6 .logo--product-name {*/
/*  display: none;*/
/*}*/

#covers.social.IJ.template-6 .logo-2 {
  display: block;
  width: 100px;
  height: 125px;
  position: absolute;
  right:71px;
  bottom: 58px;
  background-size: 100px 125px;
  background-repeat: no-repeat;
}


#covers.social.IJ.template-6 .cover-wrapper-template-twitter .mainContent,
#covers.social.IJ.template-6 .cover-wrapper-template-facebook .mainContent,
#covers.social.IJ.template-6 .cover-wrapper-template-linkedin .mainContent
{
  width: 850px;
}

#covers.social.IJ.template-6 .cover-wrapper-template-twitter .generalInput,
#covers.social.IJ.template-6 .cover-wrapper-template-facebook .generalInput,
#covers.social.IJ.template-6 .cover-wrapper-template-linkedin .generalInput
{
  font-size: 96px;
  line-height: 96px;
  margin-bottom: 12px;
}

#covers.social.IJ.template-6 .cover-wrapper-template-linkedin .generalInput
{
  font-size: 96px;
  line-height: 96px;
  margin-bottom: -5px;
}

#covers.social.IJ.template-6 .cover-wrapper-template-linkedin  .logo-2 {
  bottom: 48px;
}

#covers.social.IJ.template-6 .cover-wrapper-template-youtubeCommunity .logo-2 {
  bottom: unset;
  top: 100px;
  right: 98px;
}
#covers.social.IJ.template-6 .cover-wrapper-template-youtubeCommunity .mainContent {
  width: 760px;
}

#covers.social.IJ.template-6 .cover-wrapper-template-instagram .generalInput {
  bottom: unset;
  position: static;
  /*top: 272px;*/
  margin-top: 272px;
  font-size: 120px;
  line-height: 120px;
}

#covers.social.IJ.template-6 .cover-wrapper-template-instagram .logo {
  top: 161px;
  bottom: unset;
}
#covers.social.IJ.template-6  .cover-wrapper-template-instagram .logo-2 {
display: none;
}

#covers.social.IJ.template-6 .cover-wrapper-template-instagram .generalInput:after {
  content: " ";
  display: block;
  margin-top: 50px;
  width: 200px;
  height: 250px;
  background-size: 200px 250px;
  background-position: left top;
  background-repeat: no-repeat;
 }



#covers.social.IJ.template-6 .cover-wrapper-template-share .logo-2 {
  position: static;
  width:220px;
  height: 250px;
  background-size: 200px 250px;
  margin-top: 800px;
}

#covers.social.IJ.template-6  .cover-wrapper-template-blog .logo-2 {
  position: static;
  width:220px;
  height: 250px;
  background-size: 200px 250px;
}


#covers.social.IJ.template-6 .tag-5 .logo-2,
#covers.social.IJ.template-6 .cover-wrapper-template-instagram .tag-5 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/lamp.svg");
}

#covers.social.IJ.template-6 .tag-4 .logo-2,
#covers.social.IJ.template-6 .cover-wrapper-template-instagram .tag-4 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/track.svg");
}


#covers.social.IJ.template-6 .tag-3 .logo-2,
#covers.social.IJ.template-6 .cover-wrapper-template-instagram .tag-3 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/java.svg");
}

#covers.social.IJ.template-6 .tag-2 .logo-2,
#covers.social.IJ.template-6 .cover-wrapper-template-instagram .tag-2 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/diamond.svg");
}

#covers.social.IJ.template-6 .tag-1 .logo-2,
#covers.social.IJ.template-6 .cover-wrapper-template-instagram .tag-1 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/book.svg");
}

#covers.social.IJ.template-6 .tag-0 .logo-2,
#covers.social.IJ.template-6 .cover-wrapper-template-instagram .tag-0 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/key.svg");
}

#covers.social.IJ.template-6  .translate-toggle
{
  display: none;
}

#covers.social .tags {
  display: none;
}

#covers.social .template button[value="6"] {
  display: none;
}

#covers.social.IJ .template button[value="6"] {
  display: block;
}

#covers.social.IJ.template-6 .tags {
 display: block;
  margin-bottom: 25px;
}

#covers.social .tag_row {
  box-shadow: none
}

#covers.social .tags button {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 16px;
}

#covers.social .tags button[value="0"] {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/key.svg");
}

#covers.social .tags button[value="1"] {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/book.svg");
}

#covers.social .tags button[value="2"] {
  background-position: center 6px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/diamond.svg");
}
#covers.social .tags button[value="3"] {
  background-position: center 4px;
  background-size: 22px 18px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/java.svg");
}
#covers.social .tags button[value="4"] {
  background-position: center 6px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/track.svg");
}
#covers.social .tags button[value="5"] {
  background-position: center center;
  background-size: 22px 18px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/lamp.svg");
}


/*JBAI*/

#covers.social.JBAI .logo--product-name {
  font-size: 0;
  line-height: 0;
}
#covers.social.JBAI  .logo--product-name::before {
  position: relative;
  top: 8.2px;
  left:-8px;
  font-size: 48px;
  content: "AI";
}

#covers.social.JBAI .cover-wrapper-template-share .logo--product-name::before {
  position: relative;
  top: 18px;
  left: -16px;
  font-size: 94px;
  content: "AI";
}
#covers.social.JBAI.template-4 .logo--product-name,
#covers.social.JBAI.template-5 .logo--product-name
{
  position: static;
  margin-top: -17px;
  line-height: 0;
  font-size: 0;
}
#covers.social.JBAI.template-4 .logo--product-name::before,
#covers.social.JBAI.template-5 .logo--product-name::before
{
 display: block;
}


#covers.social.JBAI.template-4 .cover-wrapper-template-share .logo--product-name::before,
#covers.social.JBAI.template-5 .cover-wrapper-template-share .logo--product-name::before
{

  top: 12px;
}

/*Layouts*/


/*Template-1*/


/*Instagram post*/


#covers.social.template-1.huge .template-share .generalInput {
  font-size: 144px;
  line-height: 160px;
}

#covers.social.template-2 .template-share .generalInput {
  position: static;
}

#covers.social.template-3 .mainContent .generalInput {
  order: 1;
  bottom: auto;
  bottom: initial;
  font-size: 72px;
  line-height: 80px;
  padding-bottom: 24px;
  margin-bottom: 0;
  position: static;
}


#covers.social.template-3 .template-share .generalInput {
  position: static;
  font-size: 144px;
  line-height: 160px;
}


#covers.social.template-5 .generalInput {
  font-size: 72px;
  line-height: 80px;
}

#covers.social.template-5 .drop-avatar {
  background-image: none;
  display: block;
  height: 610px;
  width: 336px;
  position: absolute;
  padding-left: 0;
  border-radius: 20px 0 0;
  top: auto;
  bottom: 0;
  /* align-self: flex-end; */
}

#covers.social.template-5 .cover-wrapper-template-instagram .generalInput {
  bottom: 890px;
}
#covers.social.template-5 .cover-wrapper-template-instagram  .drop-avatar {
  background-image: none;
  display: block;
  height: 1100px;
  width: 500px;
}
#covers.social.template-5 .cover-wrapper-template-share .drop-avatar {
  border-radius: 40px 0 0;
  height: 1306px;
  width: 772px;
}

#covers.social.template-5 .template-share .generalInput{
  font-size: 144px;
  line-height: 160px;
}

#covers.social.template-5 .cover-wrapper-template-share .mainContent {
  width: 1500px;
}

/*#covers.social.template-5 .cover-wrapper-template-share .template-share {*/
/*  padding: 160px 192px 136px;*/
/*}*/
#covers.social.template-5 .template-youtubeCommunity .drop-avatar {
  background-image: none;
  display: block;
  height: 810px;
  width: 500px;
  position: absolute;
  padding-left: 0;
  border-radius: 20px 0 0;
  top: auto;
  bottom: 0;
  /* align-self: flex-end; */
}

#covers.social.template-4  .drop-avatar {
  background-image: none;
  display: block;
  height: 610px;
  width: 600px;
  position: absolute;
  padding-left: 0;
  border-radius: 20px 0 0;
  top: auto;
  bottom: 0;
  /* align-self: flex-end; */
}
#covers.social.template-4 .generalInput {
  font-size: 72px;
  line-height: 80px;
}

#covers.social.template-4 .template-share .generalInput {
  font-size: 144px;
  line-height: 160px;
  height: 100%;
  align-content: end;
}
#covers.social.template-4 .cover-wrapper-template-share .logo--product-name {
  display: block;
  font-size: 92px;
  left: 160px;
  position: absolute;
  top: 144px;
}
#covers.social.template-5 .template-share .generalInput {
  font-size: 144px;
  line-height: 160px;
}
#covers.social.template-5 .cover-wrapper-template-share .logo--product-name {
  display: block;
  font-size: 92px;
  left: 160px;
  position: absolute;
  top: 144px;
}

#covers.social.template-5 .template-share .generalInput {
  font-size: 144px;
  line-height: 160px;
  height: 100%;
  align-content: end;
}



/*Section Styles*/
#covers.social .section-youtubeCommunity .exportable-toggle {
  span {
    display: none;
  }
  label {
    margin-left: 53px;
  }
  label span {
    display: block;
  }
}

/*DN*/
#covers.social.DN .logo--product-name {
  padding-left: 0;
}

#covers.social.DN .template-share .logo--product-name {
  padding-left: 20px;
}
#covers.social.DN .logo--product-name::before {
  display: none;
}

/*IDE Services*/
