


 .cover-wrapper-template-twitter,
 .cover-wrapper-template-facebook,
 .cover-wrapper-template-linkedin {
  --cover-zoom-level: 2;
}


 .logo-1,
 .logo-2,
 .logo-3 {
  display: none;
}

 .dateInput {
  display: none;
}



/*@keyframes prompt {*/
/*  0% { opacity: 1; }*/
/*  50% { opacity: 0; }*/
/*  100% { opacity: 1; }*/
/*}*/

/*#covers .template-twitter .generalInput::after {*/
/*  content: "_";*/
/*  animation: prompt 1s infinite;*/

/*}*/

#covers .template-twitter .generalInput:focus::after {
  animation: none;
  display: none;
}
/*main styles*/

#covers .logo {
  /*top:3px;*/
  left: -3px;
  bottom: unset;
}

#covers .mainContent {
  height: 100%;
  max-height: unset;
  position: relative;
  padding: 0;
}
#covers .logo--image
{
  width: 335px;
  height: 72px;
  margin: 0 11px 0 0;
  background-size: 335px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");
}
#covers.whitelogo .logo--image {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_white_logo_white.svg");
}

#covers.blacklogo .logo--image {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_black.svg");
}

 #covers.EDU.blacklogo .logo--image {
   background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS Academy/JetBrains_Academy_logo_black.svg");
 }


#covers.blacklogo .logo--product-name {
  color: black;
}

 #covers.blacktext  .mainContent .editable {
   color: black;
   border-color: black;
 }


 #covers.EDU .logo--image {
  width: 556px;
  height: 75px;
  padding-left: 10px;
  background-size: 570px 75px;
  margin-top: -3px;
  margin-left: 3px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS Academy/JetBrains_Academy_logo_white.svg");

}

#covers.EDU .logo--product-name {
  display: none;
}
#covers.IDE .logo--image {
  width: 454px;
  height: 72px;
  background-size: 454px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDEs/JETBRAINS_IDEs_logo_white.svg");
}
#covers.whitelogo.IDE .logo--image {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/logos/JETBRAINS_IDEs_white_logo_white.svg");
}

 #covers.blacklogo.IDE .logo--image {
   /*background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/logos/JETBRAINS_IDEs_black_logo_white.svg");*/
   background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDEs/JETBRAINS_IDEs_logo_black.svg");
 }
 #covers.black_logo_white.IDE .logo--image,
 #covers.blacklogowhite.IDE .logo--image {
   background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/logos/JETBRAINS_IDEs_black_logo_white.svg");
 }


 #covers.blacklogowhite.IDE .logo--product-name,
 #covers.black_logo_white.IDE .logo--product-name {
   color: black;
 }

 .logo--product-name::before {
  content: "|";
  position: relative;
  margin: 0 14px 0 0;
}



.isProductInText .logo--product-name,
.isProductInText .logo--product-name::before  {
  display: none !important;
}

#covers.EDU.isProductInText .product-edu .logo--product-name,
#covers.EDU.isProductInText .product-edu .logo--product-name::before  {
  display: none;
}

#covers.EDU.isProductInText .product-edu   .logo--image{
  width: 335px;
  height: 72px;
  margin: 0 11px 0 0;
  background-size: 335px 72px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");

}

 #covers.YT .template button[value="4"],
 #covers.YT .template button[value="5"]
 {
   display: none;
 }

 #covers.social.YT .logo--image {
   width: 330px;
   height: 80px;
   margin: 0 11px 0 0;
   background-size: 325px 84px;
   background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Team_tools/YouTrack/YouTrack_Black.svg");
 }


 #covers.social.YT .template-share .logo--image {
   width: calc(2 * 330px);
   height: calc(2 * 80px);
   margin: 0 11px 0 0;
   background-size: calc(2 * 325px) calc(2 * 84px);
 }

 #covers.social.YT .logo--product-name {
  display: none;
 }

 /*#covers.social.YT .logo {*/
 /*  bottom: unset !important;*/
 /*}*/

 /*#covers.social.YT .template-youtubeCommunity .mainContent {*/
 /*  justify-content: unset;*/
 /*}*/

 #covers .generalInput {
  display: inline;
  font-size: 120px;
  line-height: 120px;
  position: absolute;
  bottom: 0;
  overflow: visible;
  left: 0;
  max-height: 100%;
  /*white-space: pre-wrap;*/
}


#covers.normal .generalInput{
  font-size: 96px;
  line-height: 96px;
}

#covers.small .generalInput{
  font-size: 80px;
  line-height: 80px;
}

 .logo--product-name {
  padding: 10px 0 0 10px;
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
  white-space: nowrap;
}



 .drop-avatar,
 .mask {
  display: none;
}


 .logo {
  position: absolute;
  bottom: 70px;
}

 .cover-wrapper-template-twitter .template-twitter,
 .cover-wrapper-template-facebook .template-facebook,
 .cover-wrapper-template-facebook .template-linkedin
{
  padding: 60px 72px;
}

/*Template-0*/

 .firstLine {
  display: none;
}

 .cover-wrapper-template-twitter .mainContent,
 .cover-wrapper-template-facebook .mainContent,
 .cover-wrapper-template-linkedin .mainContent {
  width: 100%;
}

 .cover-wrapper-template-twitter .mainContent {
  max-height: 400px;
}

 .cover-wrapper-template-facebook .mainContent {
  max-height: 370px;
}

/*Linkedin*/

#covers .template-linkedin .logo {
  top:-3px;
}
 .cover-wrapper-template-linkedin .generalInput {
  margin-bottom: -3px;
}



/*Twitter*/

.template-0 .cover-wrapper-template-twitter .logo--image {
  width: 96px;
  height: 96px;
  background-size: 96px;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
}

/*YouTube*/

#covers .template-youtubeCommunity {
  padding: 90px 96px;
}

#covers .template-youtubeCommunity .generalInput {
  bottom: unset;
  margin-top: 0;
  overflow: visible;
  max-height: 100%;
}

#covers .template-youtubeCommunity .logo {
  left: -3px;
  bottom: 0;
}


 .cover-wrapper-template-youtubeCommunity .dateInput,
 .cover-wrapper-template-youtubeCommunity .positionInput {
  display: none;
}




/*Instagram Post*/

 .cover-wrapper-template-instagram {
  --cover-zoom-level: 3;
}

#covers .cover-wrapper-template-instagram .story-2 {
  padding: 96px 96px 248px 96px;
}



 .cover-wrapper-template-instagram .story-2 .dateInput,
 .cover-wrapper-template-instagram .story-2 .positionInput {
  display: none;
}

#covers .cover-wrapper-template-instagram .generalInput {
  bottom: 390px;
  overflow: visible;
  max-height: 100%;
  font-size: 96px;
  line-height: 96px;
  font-weight: 600;
}

#covers .cover-wrapper-template-instagram  .logo {
  bottom: 0;
}


.template-0 .cover-wrapper-template-instagram .logo-1 {
  display: none;
}



 .template-instagram.story-2 .logo {
  display: flex;
  width: 100%;
}


/*Social*/

 .cover-wrapper-template-share .template-share {
  padding: 160px 192px 280px;
}

#covers .cover-wrapper-template-share .generalInput {
  font-size: 192px;
  line-height: 192px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  bottom: 0;
}

.template-0 .cover-wrapper-template-share .logo-1 {
  display: none;
}

#covers .cover-wrapper-template-share .logo--image {
  width: 683px;
  height: 144px;
  margin: 0;
  background-size: 683px 144px;
}

#covers.IDE .cover-wrapper-template-share .logo--image {
  width: 910px;
  height: 144px;
  margin: 0;
  background-size: 910px 144px;
}

#covers.EDU .cover-wrapper-template-share  .product-edu .logo--image {
  width: 910px;
  height: 144px;
  margin: 0;
  background-size: 910px 144px;
}

#covers.EDU.isProductInText .cover-wrapper-template-share  .product-edu .logo--image {
  width: 910px;
  height: 144px;
  margin-left: -100px;
  background-size: 910px 144px;
}

 .template-share .logo .logo--product-name {
  font-size: 96px;
  line-height: 96px;
  padding-left: 10px;
  margin-top: 5px;
}

 .template-share .logo .logo--product-name::before {
  padding-left: 25px;
  padding-right: 15px;
}

/* .template-share .logo .logo--product-name::after {*/
/*  content: ', JetBrains IDE';*/
/*  font-size: 96px;*/
/*}*/

 .cover-wrapper-template-share .mainContent {
  max-height: 800px;
  width: 100%;
}

/*Featured*/
#covers .template-blog {
  padding: 192px 144px 160px;
}
#covers .cover-wrapper-template-blog .mainContent {
  width: 100%;
  padding: 0;
}

#covers .cover-wrapper-template-blog .generalInput {
  font-size: 160px;
  line-height: 192px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  bottom: unset;
  height: 100%;
  font-weight: 600;
}

/*Qodana*/
#covers.QD .story-2 .logo-2 {
  display: none;
}

/*Template-6*/


/*Twitter*/

 .cover-wrapper-template-twitter .set-custom .logo--image {
  width: 96px;
  height: 96px;
  background-size: 96px;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
}

/*YouTube*/

#covers .template-youtubeCommunity.set-custom  .set-custom {
  padding: 90px 96px;
}

#covers .template-youtubeCommunity.set-custom  .generalInput {
  bottom: unset;
  margin-top: 0;
  overflow: visible;
  max-height: 100%;
}

#covers .template-youtubeCommunity.set-custom  .logo {
  left: -3px;
  bottom: 0;
}


 .cover-wrapper-template-youtubeCommunity .set-custom  .dateInput,
 .cover-wrapper-template-youtubeCommunity .set-custom  .positionInput {
  display: none;
}

#covers .cover-wrapper-template-youtubeCommunity .set-custom   .drop-avatar .dropArea
{

  right: 30px;
  top: auto;
  bottom: 100px;
  left: auto;
  height: 100px;
  width: 100px;
}


/*Instagram Post*/

 .cover-wrapper-template-instagram .set-custom  {
  --cover-zoom-level: 2;
}

#covers .cover-wrapper-template-instagram .set-custom  .story-2 {
  padding: 96px 96px 248px 96px;
}



 .cover-wrapper-template-instagram .story-2.set-custom  .dateInput,
 .cover-wrapper-template-instagram .story-2.set-custom  .positionInput {
  display: none;
}

#covers .cover-wrapper-template-instagram.set-custom  .generalInput {
  bottom: 390px;
  overflow: visible;
  max-height: 100%;
  font-size: 96px;
  line-height: 96px;
}

#covers .cover-wrapper-template-instagram.set-custom .logo {
  bottom: 0;
}


 .cover-wrapper-template-instagram .set-custom  .logo-1 {
  display: none;
}



 .template-instagram.story-2.set-custom  .logo {
  display: flex;
  width: 100%;
}


/*Social*/

 .cover-wrapper-template-share .template-share.set-custom  {
  padding: 160px 192px 280px;
}

#covers .cover-wrapper-template-share .set-custom  .generalInput {
  font-size: 192px;
  line-height: 192px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  bottom: 0;
}

 .cover-wrapper-template-share .set-custom  .logo-1 {
  display: none;
}

#covers .cover-wrapper-template-share .set-custom  .logo--image {
  width: 683px;
  height: 144px;
  margin: 0;
  background-size: 683px 144px;
}

#covers.IDE .cover-wrapper-template-share .set-custom  .logo--image {
  width: 910px;
  height: 144px;
  margin: 0;
  background-size: 910px 144px;
}

#covers.EDU .cover-wrapper-template-share  .product-edu.set-custom  .logo--image {
  width: 910px;
  height: 144px;
  margin: 0;
  background-size: 910px 144px;
}

#covers.EDU.isProductInText .cover-wrapper-template-share  .product-edu.set-custom  .logo--image {
  width: 910px;
  height: 144px;
  margin-left: -100px;
  background-size: 910px 144px;
}

 .template-share.set-custom  .logo .logo--product-name {
  font-size: 96px;
  line-height: 96px;
  padding-left: 10px;
  margin-top: 5px;
}

 .template-share.set-custom .logo .logo--product-name::before {
  padding-left: 25px;
  padding-right: 15px;
}


 .cover-wrapper-template-share .set-custom  .mainContent {
  max-height: 800px;
  width: 100%;
}

#covers .template-share {
  background-size: cover;
}

/*Featured*/
#covers .template-blog.set-custom  {
  padding: 192px 144px 160px;
}
#covers .cover-wrapper-template-blog .set-custom  .mainContent {
  width: 100%;
  padding: 0;
}

#covers .cover-wrapper-template-blog .set-custom  .generalInput {
  font-size: 160px;
  line-height: 192px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  bottom: unset;
  height: 100%;
}


#covers .set-custom  .drop-avatar
{
  background-image: none;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  padding-left: 0 ;
  top: auto;
  bottom: 0;
}
#covers .set-custom  .mask {
  display: block;
  height: 100%;
  width: 100%;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  position: absolute;
  bottom: 0;
}
#covers .set-custom  .drop-avatar .dropArea
{
  position: absolute;
  right: 30px;
  top: 30px;
  bottom: auto;
  left: auto;
  height: 100px;
  width: 100px;
}



/*Annotated*/

/*#covers.ANT .logo,*/
/*#covers.ANT .generalInput*/
/*{*/
/*  display: none;*/
/*}*/

#covers.ANT .generalInput {
  font-size: 96px;
  line-height: 96px;
  padding-bottom: 96px;
}

#covers.ANT .translate_button {
  display: none;
}

#covers.ANT .dateInput {
  width: auto;
  display: inline-block;
  font-size: 32px;
  color: #fff;
  padding: 6px 15px;
  margin-bottom: 14px;
  border-radius: 9px;
  border: solid 1px #fff;
  z-index: 100;
}


#covers.ANT .dateInput {
  display: none;
}

#covers.ANT.isProductInText .dateInput {
  display: block;
}

#covers.ANT .template-twitter .dateInput,
#covers.ANT .template-facebook .dateInput,
#covers.ANT .template-linkedin .dateInput,
#covers.ANT .template-youtubeCommunity .dateInput
{
  position: absolute;
  bottom: 0;
  /*left: 0px;*/
}


#covers.ANT .logo-1 {
  display: block;
  bottom: unset;
  background-size: cover;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/ANT/icons/java-annotated/0.svg");
}

#covers.ANT .template-twitter .logo-1,
#covers.ANT .template-facebook .logo-1,
#covers.ANT .template-linkedin .logo-1
{
  left: 780px;
  top: 145px;
  width: 360px;
  height: 455px;
}

#covers.ANT .template-facebook .logo-1 {
  width: 320px;
  height: 405px;
  left: 800px;
}

#covers.ANT .template-linkedin .logo-1 {
  width: 320px;
  height: 405px;
  left: 800px;
  top: 143px;
}

#covers.ANT.isProductInText .template-twitter .logo-1,
#covers.ANT.isProductInText .template-facebook .logo-1,
#covers.ANT.isProductInText .template-linkedin .logo-1
{
  left: 723px;
  top: 106px;
  width: 382px;
  height: 483px;
}

#covers.ANT.isProductInText .template-facebook .logo-1 {
  top: 68px;
  left: 760px;
}

#covers.ANT.isProductInText .template-linkedin .logo-1 {
  top: 70px;
  left: 720px;
}

#covers.ANT .template-youtubeCommunity .logo-1,
#covers.ANT .template-instagram .logo-1
{
  left: 653px;
  top: 406px;
  width: 382px;
  height: 483px;
}

#covers.ANT.isProductInText .template-youtubeCommunity .logo-1 {
  top: 506px;
}

#covers.ANT .template-instagram .logo-1 {
  top: 810px;
  left: 426px;
  width: 560px;
  height: 710px;
}

#covers.ANT .template-share .logo-1,
#covers.ANT .template-blog .logo-1
{
  left: 1692px;
  top: 350px;
  width: 764px;
  height: 966px;
}

#covers.ANT.isProductInText .template-blog .logo-1,
#covers.ANT.isProductInText .template-share .logo-1
{
  left: 1592px;
  top: 230px;
  width: 764px;
  height: 966px;
}

#covers.ANT .template-share {
  background-size: contain;
}

#covers.ANT .template-youtubeCommunity .generalInput
{
  font-size: 120px;
  line-height: 120px;
}

#covers.ANT .template-instagram .generalInput {
  font-size: 120px;
  line-height: 120px;
  padding-top: 117px;
}

#covers.ANT .template-share .generalInput,
#covers.ANT .template-blog .generalInput
{
  font-size: 160px;
  line-height: 160px;
  position: static;
  margin-top: 220px;
}

#covers.ANT .template-blog .generalInput
{
  font-size: 196px !important;
  line-height: 196px;
  position: static;
  margin-top: 96px;
  height: auto;
}



#covers.ANT .template-youtubeCommunity  .logo--product-name,
#covers.ANT .template-instagram  .logo--product-name
{
  position: absolute;
  left: 80px;
  bottom: -50px;
}


#covers.WRS .set-9 .generalInput,
#covers.WRS .set-9 .logo,
#covers.WRS .set-10 .generalInput,
#covers.WRS .set-10 .logo
{
  color: black;
}

#covers.WRS .set-9 .logo--image,
#covers.WRS .set-10 .logo--image
{
 background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDEs/JETBRAINS_IDEs_logo_black.svg");
}

#covers.MP .product-mp .logo--product-name  {
  display: block;
}



/*Tags*/

.translate-toggle {
  display: none;
}
#covers .translate-toggle {
  display: block;
}

#covers .translate-toggle {
  color: grey;
  font-size: 16px;
  margin-bottom: 15px;
}


#covers.ANT .translate-toggle {
  display: none;
}

#covers.IH .translate-toggle {
  display: none;
}


/*#covers.IJ.template-6 .logo--product-name {*/
/*  display: none;*/
/*}*/

#covers.IJ.template-6 .logo-2 {
  display: block;
  width: 100px;
  height: 125px;
  position: absolute;
  right:71px;
  bottom: 58px;
  background-size: 100px 125px;
  background-repeat: no-repeat;
}


#covers.IJ.template-6 .cover-wrapper-template-twitter .mainContent,
#covers.IJ.template-6 .cover-wrapper-template-facebook .mainContent,
#covers.IJ.template-6 .cover-wrapper-template-linkedin .mainContent
{
  width: 850px;
}

#covers.IJ.template-6 .cover-wrapper-template-twitter .generalInput,
#covers.IJ.template-6 .cover-wrapper-template-facebook .generalInput,
#covers.IJ.template-6 .cover-wrapper-template-linkedin .generalInput
{
  font-size: 96px;
  line-height: 96px;
  margin-bottom: 12px;
}

#covers.IJ.template-6 .cover-wrapper-template-linkedin .generalInput
{
  font-size: 96px;
  line-height: 96px;
  margin-bottom: -5px;
}

#covers.IJ.template-6 .cover-wrapper-template-linkedin  .logo-2 {
  bottom: 48px;
}

#covers.IJ.template-6 .cover-wrapper-template-youtubeCommunity .logo-2 {
  bottom: unset;
  top: 100px;
  right: 98px;
}
#covers.IJ.template-6 .cover-wrapper-template-youtubeCommunity .mainContent {
  width: 760px;
}

#covers.IJ.template-6 .cover-wrapper-template-instagram .generalInput {
  bottom: unset;
  position: static;
  /*top: 272px;*/
  margin-top: 272px;
  font-size: 120px;
  line-height: 120px;
}

#covers.IJ.template-6 .cover-wrapper-template-instagram .logo {
  top: 161px;
  bottom: unset;
}
#covers.IJ.template-6  .cover-wrapper-template-instagram .logo-2 {
display: none;
}

#covers.IJ.template-6 .cover-wrapper-template-instagram .generalInput:after {
  content: " ";
  display: block;
  margin-top: 50px;
  width: 200px;
  height: 250px;
  background-size: 200px 250px;
  background-position: left top;
  background-repeat: no-repeat;
 }



#covers.IJ.template-6 .cover-wrapper-template-share .logo-2 {
  position: static;
  width:220px;
  height: 250px;
  background-size: 200px 250px;
  margin-top: 800px;
}

#covers.IJ.template-6  .cover-wrapper-template-blog .logo-2 {
  position: static;
  width:220px;
  height: 250px;
  background-size: 200px 250px;
}


#covers.IJ.template-6 .tag-5 .logo-2,
#covers.IJ.template-6 .cover-wrapper-template-instagram .tag-5 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/lamp.svg");
}

#covers.IJ.template-6 .tag-4 .logo-2,
#covers.IJ.template-6 .cover-wrapper-template-instagram .tag-4 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/track.svg");
}


#covers.IJ.template-6 .tag-3 .logo-2,
#covers.IJ.template-6 .cover-wrapper-template-instagram .tag-3 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/java.svg");
}

#covers.IJ.template-6 .tag-2 .logo-2,
#covers.IJ.template-6 .cover-wrapper-template-instagram .tag-2 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/diamond.svg");
}

#covers.IJ.template-6 .tag-1 .logo-2,
#covers.IJ.template-6 .cover-wrapper-template-instagram .tag-1 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/book.svg");
}

#covers.IJ.template-6 .tag-0 .logo-2,
#covers.IJ.template-6 .cover-wrapper-template-instagram .tag-0 .generalInput:after {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/key.svg");
}

#covers.IJ.template-6  .translate-toggle
{
  display: none;
}

#covers .tags {
  display: none;
}

#covers .template button[value="6"] {
  display: none;
}

#covers.IJ .template button[value="6"] {
  display: block;
}

#covers.IJ.template-6 .tags {
 display: block;
  margin-bottom: 25px;
}

#covers .tag_row {
  box-shadow: none
}

#covers .tags button {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 16px;
}

#covers .tags button[value="0"] {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/key.svg");
}

#covers .tags button[value="1"] {
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/book.svg");
}

#covers .tags button[value="2"] {
  background-position: center 6px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/diamond.svg");
}
#covers .tags button[value="3"] {
  background-position: center 4px;
  background-size: 22px 18px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/java.svg");
}
#covers .tags button[value="4"] {
  background-position: center 6px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/track.svg");
}
#covers .tags button[value="5"] {
  background-position: center center;
  background-size: 22px 18px;
  background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/icons/blog-categories/lamp.svg");
}


/*JBAI*/

#covers.JBAI .logo--product-name {
  font-size: 0;
  line-height: 0;
}
#covers.JBAI  .logo--product-name::before {
  position: relative;
  top: 8.2px;
  left:-8px;
  font-size: 48px;
  content: "AI";
}

#covers.JBAI .cover-wrapper-template-share .logo--product-name::before {
  position: relative;
  top: 18px;
  left: -16px;
  font-size: 94px;
  content: "AI";
}
#covers.JBAI.template-4 .logo--product-name,
#covers.JBAI.template-5 .logo--product-name
{
  position: static;
  margin-top: -17px;
  line-height: 0;
  font-size: 0;
}
#covers.JBAI.template-4 .logo--product-name::before,
#covers.JBAI.template-5 .logo--product-name::before
{
 display: block;
}


#covers.JBAI.template-4 .cover-wrapper-template-share .logo--product-name::before,
#covers.JBAI.template-5 .cover-wrapper-template-share .logo--product-name::before
{

  top: 12px;
}
/*Section Styles*/
#covers .section-youtubeCommunity .exportable-toggle {
  span {
    display: none;
  }
  label {
    margin-left: 53px;
  }
  label span {
    display: block;
  }
}

/*IDE Services*/
 #covers.IS .logo {
   left: -13px;
 }

 #covers.IS .logo--image
 {
   width: 650px;
   background-size: 650px 72px;
   background-position: top left;
   background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDE Services/JETBRAINS_IDE_Services_logo_white.svg");
 }
 #covers.IS .logo--product-name {
   display: none;
 }

 #covers.IS .template-share .logo--image {
   width: calc(2 * 650px);
   background-size: calc(2 * 650px) calc(2 * 72px);
 }

 /*JB IDEs*/


 #covers.JBIDEs .logo--image
 {
   width: 450px;
   background-size: 450px 72px;
   background-position: top left;
   background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/Initiatives/JETBRAINS IDEs/JETBRAINS_IDEs_logo_white.svg");
 }
 #covers.JBIDEs .logo--product-name {
   display: none;
 }

 #covers.JBIDEs .template-share .logo--image {
   width: calc(2 * 450px);
   background-size: calc(2 * 450px) calc(2 * 72px);
 }

 /*Startup programs*/

 #covers.ST .logo--product-name {
   display: none;
 }

 /*MP*/
 #covers.MP .logo--product-name {
   padding-left: 0;
 }

 #covers.MP .template-share .logo--product-name {
   padding-left: 20px;
 }

 #covers.MP .logo--product-name::before {
  display: none;
 }

 /*Statuses*/


 #covers #container::after {
   display: block;
   content: "Latest";
   padding: 5px 5px;
   color: darkgray;
   border-radius: 4px;
   position: absolute;
   top: 20px;
   right: 270px;
   z-index: 20;
   font-size: 13px;
   background-color: darkgreen;
 }

 #covers.empty #container::after {
   content: "Coming Soon";
   background-color: darkred;
 }

 #covers.outdated #container::after {
   content: "Outdated";
   background-color: orangered;
   color: white;
 }

 #covers.empty .cover-wrapper>div {
   background-image: none;
   background-color: black;
 }


 @keyframes flash {
   0%   { opacity: 1; }
   50%  { opacity: 0.6; }
   100% { opacity: 1; }
 }

 #covers.empty #container::after {
   animation: flash 3s linear infinite;
 }

 /*NON-product*/

 .nonproduct .logo--product-name {
   display: none !important;
 }

 /*Standalone*/

 .standalone .logo--product-name::before {
   display: none !important;
 }

 /*Benchmark*/

 #covers.benchmark .capture-image:before {
   content: ' ';
   display: block;
   position: absolute;
   background-color: black;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   opacity: 0.8;
 }
