/* JetBrains Newsletter Styles */

/* General Styles */
#covers.social.TC.stage-newsletter .drop-avatar {
  display: none !important;
}

#covers.social.TC.stage-newsletter .mask {
  display: none !important;
}

#covers.social.TC.stage-newsletter {
  .generalInput {
    font-size: 96px;
    line-height: 96px;
    padding-bottom: 83px;

  }

  .dateInput {
    display: block;
    font-size: 40px;
    color: #fff;
    padding: 8px 20px;
    font-weight: 400;
    border-radius: 9px;
    border: solid 1px #fff;
    z-index: 100;
  }

  &.isProductInText .dateInput {
    display: block;
  }

  .translate_button,
  .language {
    display: none;
  }

  .logo-1 {
    display: block;
    bottom: unset;
    background-size: cover;
    background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/TC/icons/newsletter/0.svg");
  }

  .logo--product-name {
    display: none;
  }
}

/* Template Specific Styles */
#covers.social.TC.stage-newsletter {
  /* LinkedIn Template */
  .template-linkedin {
    .generalInput {
      padding-bottom: 83px;
    }

    .dateInput {
      margin-bottom: -3px;
      position: absolute;
      bottom: 0;
    }

    .logo-1 {
      width: 400px;
      height: 241px;
      background-size: 393px 234px;
      left: 742px;
      top: 272px;
    }
  }

  /* Instagram Template */
  .template-instagram {
    .logo {
      display: none;
    }

    .generalInput {
      font-size: 120px;
      line-height: 120px;
      position: static;
      margin-top: 323px;
      margin-bottom: 50px;
      padding-bottom: 0;
    }

    .dateInput {
      display: block;
      position: static;
      width: max-content;
      margin-top: -9px;
      white-space: nowrap;
    }

    .logo-1 {
      width: 600px;
      height: 680px;
      background-size: 595px 555px;
      left: 429px;
      top: 907px;
    }
  }

  /* YouTube Community Template */

  .template-youtubeCommunity {
    .generalInput {
      font-size: 120px;
      line-height: 120px;
      position: static;
      margin-bottom: 35px;
      padding-bottom: 0;
    }

    .dateInput {
      display: block;
      position: static;
      width: max-content;
      padding: 0 15px;
      white-space: nowrap;
    }

    /*.logo {*/
    /*  display: none;*/
    /*}*/

    .logo-1 {
      width: 409px;
      height: 489px;
      background-size: 389px 489px;
      left: 607px;
      top: 563px;
    }

  }

  /* Twitter Template */
  .template-twitter {
    .dateInput {
      position: absolute;
      bottom: 0;
    }

    .logo-1 {
      width: 400px;
      height: 241px;
      background-size: 393px 234px;
      left: 737px;
      top: 283px;
    }
  }

  /* Facebook Template */
  .template-facebook {
    .dateInput {
      position: absolute;
      bottom: 0;
    }

    .logo-1 {
      width: 400px;
      height: 241px;
      background-size: 393px 234px;
      left: 742px;
      top: 272px;
    }
  }

  /* Share and Blog Templates */
  .template-blog {
    .logo-1 {
      width: calc(2* 409px);
      height: calc(2* 489px);
      background-size: calc(2* 389px) calc(2* 593px);
      left: calc(2* 819px);
      top: calc(2* 172px);
    }

    .generalInput {
      font-size: 160px;
      line-height: 192px;
      position: static;
      margin-top: 0;
      height: unset;
    }

    .dateInput {
      font-size: 80px;
      line-height: 80px;
      font-weight: normal;
      padding: 16px 40px;
      width: max-content;
      border-radius: 18px;
      border: solid 2px #fff;
      white-space: nowrap;
      margin-top: -38px;
      margin-left: 0px;
    }
  }

  .template-share {
    .logo-1 {
      width: calc(2* 409px);
      height: calc(2* 489px);
      background-size: calc(2* 389px) calc(2* 593px);
      left: calc(2* 794px);
      top: calc(2* 122px);
    }

    .generalInput {
      font-size: 192px;
      line-height: 192px;
      position: static;
      margin-top: 237px;
      height: unset;
    }

    .dateInput {
      font-size: 80px;
      line-height: 80px;
      font-weight: normal;
      padding: 16px 40px;
      width: max-content;
      border-radius: 18px;
      border: solid 2px #fff;
      white-space: nowrap;
      margin-top: -7px;
      margin-left: 0px;
    }
  }
}



/* IDE Specific Styles */
#covers.social.IDE.stage-newsletter {
  .logo--image {
    width: 403px;
    height: 403px;
    margin: 0 11px 0 0;
    background-size: 403px 403px;
    background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");
  }

  .template-share .logo--image {
    width: 670px;
    height: 144px;
    background-size: 910px 144px;
  }
}

/* Hide Template and Localization in Toolbar */
#covers.social.stage-newsletter .toolbar {
  .template,
  .localization {
    display: none;
  }
}


/*Section Styles*/
#covers.social.stage-newsletter .section-youtubeCommunity .exportable-toggle {
  span {
    display: none;
  }
  label {
    margin-left: 53px;
  }
  label span {
    display: block;
  }
}
