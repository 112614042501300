.section {
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
}

.title  {
  color: gray;
  font-size: 18px;
  margin-bottom: 8px;
  width: 100%;
}

/*Exportable control*/

:global .exportable-toggle {
  margin-right: 15px;
  label span {
    /*transform: scale(0.9);*/
    background-color: black !important;
    /*background-color: darkslategray !important;*/
    /*background-color: gray !important;*/
  }
  span::after {
    /*background-color: #222;*/
    background-color: #333;
  }
  :nth-child(1)  {
    color: gray !important;
  }
}


:global .exportable-toggle {
  margin-top: -30px;
  margin-left: -95px;
}


:global .no-exportable .cover-wrapper {
  filter: grayscale(100%);
  opacity: 0.1;
  transition: filter 0.3s ease, opacity 0.3s ease;
}

:global .no-exportable .exportable-toggle {
  opacity: 1.0;

  :nth-child(1) {
    color: #444 !important;
  }

  /*span::after {*/
  /*  background-color: #444 !important;*/
  /*}*/
}

:global .no-exportable .exportable-toggle label span {
  background-color: black;
}




:global .no-exportable h2 {
  color: #444;
}
