
/* JetBrains Newsletter Styles */

/* General Styles */
#covers.social.MP.stage-newsletter .drop-avatar {
  display: none !important;
}

#covers.social.MP.stage-newsletter .mask {
  display: none !important;
}

#covers.social.MP.stage-newsletter .product-mp  {
  .generalInput {
    font-size: 96px;
    line-height: 96px;
    padding-bottom: 71px;

  }

  .dateInput {
    display: block;
    font-size: 40px;
    color: #fff;
    /*padding: 8px 20px;*/
    font-weight: 400;
    /*border-radius: 9px;*/
    /*border: solid 1px #fff;*/
    z-index: 100;
  }

  &.isProductInText .dateInput {
    display: block;
  }
  .logo--product-name {
    display: none;
  }

  .translate_button,
  .language {
    display: none;
  }

  .logo-1 {
    display: block;
    bottom: unset;
    background-size: cover;
    background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Coverbaker/Social/MP/icons/newsletter/0.svg");
  }

  .logo--product-name {
    display: none;
  }
}

/* Template Specific Styles */
#covers.social.MP.stage-newsletter {
  .template-linkedin {
    .generalInput {
      padding-bottom: 70px;
    }

    .dateInput {
      margin-bottom: -3px;
      position: absolute;
      bottom: 0;
    }

    .logo-1 {
      width: 380px;
      height: 411px;
      background-size: 367px;
      left: 776px;
      top: 175px;
    }
  }

  /* Instagram Template */
  .template-instagram {
    .logo {
      display: none;
    }

    .generalInput {
      font-size: 120px;
      line-height: 120px;
      position: static;
      margin-top: 300px;
      margin-bottom: 50px;
      padding-bottom: 0;
    }

    .dateInput {
      display: block;
      position: static;
      width: max-content;
      /*margin-top: -9px;*/
      font-size: 60px;
      line-height: 72px;
    }

    .logo-1 {
      width: 600px;
      height: 680px;
      background-size: 585px;
      left: 471px;
      top: 861px;
    }
  }

  /* YouTube Community Template */

  .template-youtubeCommunity {
    .generalInput {
      font-size: 120px;
      line-height: 120px;
      position: static;
      margin-bottom: 35px;
      padding-bottom: 0;
    }

    .dateInput {
      display: block;
      position: static;
      width: max-content;
      padding: 0;
      font-size: 60px;
      line-height: 72px;
    }

    /*.logo {*/
    /*  display: none;*/
    /*}*/

    .logo-1 {
      width: 409px;
      height: 489px;
      background-size: 389px 489px;
      left: 629px;
      top: 563px;
    }

  }

  /* Twitter Template */
  .template-twitter {
    .dateInput {
      position: absolute;
      bottom: 0;
    }

    .logo-1 {
      width: 380px;
      height: 411px;
      background-size: 367px;
      left: 772px;
      top: 196px;
    }
  }

  /* Facebook Template */
  .template-facebook {
    .dateInput {
      position: absolute;
      bottom: 0;
    }

    .logo-1 {
      width: 380px;
      height: 411px;
      background-size: 367px;
      left: 776px;
      top: 186px;
    }
  }

  /* Share and Blog Templates */
  .template-blog {
    .logo-1 {
      width: calc(2* 409px);
      height: calc(2* 450px);
      background-size: calc(2* 369px);
      left: calc(2* 851px);
      top: calc(2* 264px);
    }

    .generalInput {
      font-size: 160px;
      line-height: 192px;
      position: static;
      margin-top: 0;
      height: unset;
    }

    .dateInput {
      font-size: 80px;
      line-height: 80px;
      font-weight: normal;
      width: max-content;
      white-space: nowrap;
      margin-top: -18px;
      margin-left: 0px;
    }
  }

  .template-share {
    .logo-1 {
      width: calc(2* 409px);
      height: calc(2* 500px);
      background-size: calc(2* 369px);
      left: calc(2* 823px);
      top: calc(2* 214px);
    }

    .generalInput {
      font-size: 192px;
      line-height: 192px;
      position: static;
      margin-top: 267px;
      height: unset;
    }

    .dateInput {
      font-size: 80px;
      line-height: 80px;
      font-weight: normal;
      /*padding: 16px 40px;*/
      width: max-content;
      padding: 0;
      /*border-radius: 18px;*/
      /*border: solid 2px #fff;*/
      white-space: nowrap;
      /*margin-top: -77px;*/
      margin-left: 0px;
    }
  }
}



/* IDE Specific Styles */
#covers.social.IDE.stage-newsletter {
  .logo--image {
    width: 403px;
    height: 403px;
    margin: 0 11px 0 0;
    background-size: 403px 403px;
    background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos/JETBRAINS/JETBRAINS_logo_white.svg");
  }

  .template-share .logo--image {
    width: 670px;
    height: 144px;
    background-size: 910px 144px;
  }
}

/* Hide Template and Localization in Toolbar */
#covers.social.stage-newsletter .toolbar {
  .template,
  .localization {
    display: none;
  }
}


/*Section Styles*/
#covers.social.stage-newsletter .section-youtubeCommunity .exportable-toggle {
  span {
    display: none;
  }
  label {
    margin-left: 53px;
  }
  label span {
    display: block;
  }
}
