
$products: (
  "amper": "AMP",
  "annotated": "ANT",
  "aqua": "AQ",
  "clion": "CL",
  "datagrip": "DG",
  "dataspell": "DS",
  "datalore": "DL",
  "dottrace": "DT",
  "dotcover": "DC",
  "dotpeek": "DP",
  "dotmemory": "DM",
  "hub": "HB",
  "fleet": "FL",
  "goland": "GO",
  "intellij-idea": "IJ",
  "pycharm": "PC",
  "phpstorm": "PS",
  "qodana": "QD",
  "resharper": "RS",
  "resharper-cpp": "RScpp",
  "code-with-me": "CWM",
  "teamcity": "TC",
  "toolbox": "TB",
  "jetbrains": "JB",
  "jetbrains-academy": "EDU",
  "rider": "RD",
  "rubymine": "RM",
  "webstorm": "WS",
  "youtrack": "YT"
);
$internals: "twitter", "facebook", "linkedin", "youtubeCommunity", "instagram", "countdown" "share", "blog";

@each $product, $abbr in $products {
  @each $internal in $internals {
    //$iconType: if($product == "fleet" or $product == "code-with-me", "icon-full", "square");

  //  .internal #{$abbr} .cover-wrapper-template-#{$internal} .logo-1 {
  //
  //  background-image: url("https://d3r50642m20jlp.cloudfront.net/tiles/get_tilesets?prefix=Coverbaker/Internal/${product?.value}/${stage.value}/#{$product}/0.svg");
  //}
  //  .internal.template-8.#{$abbr} .cover-wrapper-template-#{$internal} .logo-1 {
  //    display: block;
  //    background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos_Icons_v2/#{$product}/Type=icon-full.svg");
  //    height: 200px;
  //    margin-bottom: 0;
  //  }

    .internal.template-11.#{$abbr} .cover-wrapper-template-#{$internal} .logo-1 {
      display: block;
      background-image: url("https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos_Icons_v2/#{$product}/Type=icon-full.svg");
      height: 200px;
      margin-bottom: 0;
    }
  }
}


$primaryColors: (
  "IJ": #0047FD,
);

@each $product, $color in $primaryColors {

#covers.internal.#{$product} .template-streamYardOverlay .generalInput {
  background: linear-gradient(to right, $color, black);
}

}
